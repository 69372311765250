<template>
  <v-container></v-container>
</template>

<script>

export default {
  name: 'Landing',
  setup(props, {root}) {

  },
}
</script>