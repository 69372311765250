<template>
  <v-container class="pa-0 grouped-job-list">
    <v-row v-if="sites.length === 0"
           class="flex-grow-1">
      <v-col align-self="center">
        NO JOBS
      </v-col>
    </v-row>

    <virtual-list style="flex: 1 1 0; overflow: auto"
                  :data-key="'id'"
                  :data-sources="sites"
                  :data-component="siteCell"></virtual-list>


  </v-container>
</template>

<script>
import {DateTime} from 'luxon';
import _ from 'lodash';
import {ref, watch, computed, onMounted} from '@vue/composition-api'
import {EVENT_SHOW_FILTER, JOB_STATUS, JOB_TYPE, SUBMISSION_RESULT} from "@/constants";
import {ACTION_TYPES} from "@/store/types";
import {JobSubmission} from "@/store/models";
import VirtualList from 'vue-virtual-scroll-list'
import SiteCell from "@/components/SiteCell";

export default {
  name: 'GroupedJobList',
  components: {VirtualList},
  props: {
    value: Array,
    jobs: Array,
    selectMode: Boolean,
    hideStatus: Boolean,
    hideFilter: Boolean,
  },
  setup(props, {root, emit}) {
    const sites = computed(() => {
      let sites = _.uniqBy(props.jobs.map(j=>j.equipment.site), 'id');
      let jobsBySite = _.groupBy(props.jobs, 'equipment.site.id');

      return _.sortBy(sites, 'name').map((s)=> {
        return {
          ...s,
          jobs: jobsBySite[s.id] || [],
        }
      });
    });
    const jobStatusCounts = computed(() => {
      return _.countBy(props.jobs, 'job_status');
    });

    const selectedJobIds = ref([]);
    const shouldShowSelectCheckbox = function (job) {
      return job.job_status === JOB_STATUS.PENDING;
    };
    const onJobSelected = function () {
      emit('input', selectedJobIds.value);
    };

    watch(() => props.value, (newValue, oldValue) => {
      selectedJobIds.value = newValue;
    }, {immediate: true});

    const isJobExpired = function (job) {
      if (job.job_status === JOB_STATUS.COMPLETED) return false;

      // Check if equipment expired
      if (job.type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION && job.equipment && job.equipment.next_load) {
        if (DateTime.fromISO(job.equipment.next_load) < DateTime.fromJSDate(new Date())) {
          return true
        }
      }

      // Check if equipment expired
      if (job.equipment && job.equipment.next_exam) {
        if (DateTime.fromISO(job.equipment.next_exam) < DateTime.fromJSDate(new Date())) {
          return true
        }
      }

      return false;
    };
    const isJobNotSubmitted = function (job) {
      return _.some(job.submissions, (s) => s.ready_to_submit)
    };
    const getJobChipColor = function (job) {
      if (isJobExpired(job)) {
        return 'red';
      }

      return {
        [JOB_STATUS.PENDING]: '#ffe660',
        [JOB_STATUS.COMPLETED]: '#91d7ff',
        [JOB_STATUS.CANCELLED]: 'red',
      }[job.job_status];
    };
    const getJobChipIcon = function (job) {
      if (isJobExpired(job)) {
        return 'fas fa-exclamation-triangle';
      }

      return {
        [JOB_STATUS.PENDING]: 'fas fa-user-clock',
        [JOB_STATUS.COMPLETED]: 'fas fa-check',
        [JOB_STATUS.CANCELLED]: 'fas fa-ban',
      }[job.job_status];
    };
    const getJobStatus = function (job) {
      return {
        [JOB_STATUS.PENDING]: root.$t('job_status.pending'),
        [JOB_STATUS.COMPLETED]: root.$t('job_status.completed'),
        [JOB_STATUS.CANCELLED]: root.$t('job_status.cancelled'),
      }[job.job_status];
    };

    const getJobExamChipColor = function (job) {
      if (job.last_exam_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: '#91d7ff',
          [SUBMISSION_RESULT.FAIL]: 'red',
          [SUBMISSION_RESULT.MISSING]: '#e5d9fe',
          [SUBMISSION_RESULT.NOT_READY]: '#e5d9fe',
          [SUBMISSION_RESULT.UNDER_REPAIR]: '#e5d9fe',
        }[job.last_exam_submission.result];
      } else {
        return '#ffe660'
      }
    }
    const getJobExamChipIcon = function (job) {
      if (job.last_exam_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: 'fas fa-thumbs-up',
          [SUBMISSION_RESULT.FAIL]: 'fas fa-ban',
          [SUBMISSION_RESULT.MISSING]: 'fas fa-question',
          [SUBMISSION_RESULT.NOT_READY]: 'fas fa-question',
          [SUBMISSION_RESULT.UNDER_REPAIR]: 'fas fa-hammer',
        }[job.last_exam_submission.result];
      } else {
        return 'fas fa-clock'
      }
    }
    const getJobExamLabel = function (job) {
      if (job.last_exam_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: root.$t('job_status.pass'),
          [SUBMISSION_RESULT.FAIL]: root.$t('job_status.fail'),
          [SUBMISSION_RESULT.MISSING]: root.$t('job_status.missing'),
          [SUBMISSION_RESULT.NOT_READY]: root.$t('job_status.not_ready'),
          [SUBMISSION_RESULT.UNDER_REPAIR]: root.$t('job_status.under_repair'),
        }[job.last_exam_submission.result];
      } else {
        // return root.$t('job_status.pending')
        return job.equipment.next_exam;
      }
    }

    const getJobLoadTestChipColor = function (job) {
      if (job.last_load_test_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: '#91d7ff',
          [SUBMISSION_RESULT.FAIL]: 'red',
          [SUBMISSION_RESULT.MISSING]: '#e5d9fe',
          [SUBMISSION_RESULT.NOT_READY]: '#e5d9fe',
          [SUBMISSION_RESULT.UNDER_REPAIR]: '#e5d9fe',
        }[job.last_load_test_submission.result];
      } else {
        return '#ffe660'
      }
    }
    const getJobLoadTestChipIcon = function (job) {
      if (job.last_load_test_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: 'fas fa-thumbs-up',
          [SUBMISSION_RESULT.FAIL]: 'fas fa-ban',
          [SUBMISSION_RESULT.MISSING]: 'fas fa-question',
          [SUBMISSION_RESULT.NOT_READY]: 'fas fa-question',
          [SUBMISSION_RESULT.UNDER_REPAIR]: 'fas fa-hammer',
        }[job.last_load_test_submission.result];
      } else {
        return 'fas fa-clock'
      }
    }
    const getJobLoadTestLabel = function (job) {
      if (job.last_load_test_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: root.$t('job_status.pass'),
          [SUBMISSION_RESULT.FAIL]: root.$t('job_status.fail'),
          [SUBMISSION_RESULT.MISSING]: root.$t('job_status.missing'),
          [SUBMISSION_RESULT.NOT_READY]: root.$t('job_status.missing'),
          [SUBMISSION_RESULT.UNDER_REPAIR]: root.$t('job_status.under_repair'),
        }[job.last_load_test_submission.result];
      } else {
        // return root.$t('job_status.pending')
        return job.equipment.next_load;
      }
    }

    const onJobClicked = function (job) {
      emit('click', job);
    };

    // Job Status
    const currentFilter = computed(() => root.$store.getters.jobFilters);
    const currentFilterCount = computed(() => {
      return _.filter(['site', 'client', 'district', 'region'], (key) => {
        return currentFilter.value[key].length > 0;
      }).length
    })
    const isJobStatusActive = function (status) {
      return !showUnsubmittedJobs.value && currentFilter.value.jobStatus.indexOf(status) !== -1;
    }
    const toggleJobStatusFilter = function (status) {
      if (showUnsubmittedJobs.value) {
        showUnsubmittedJobs.value = false;
      } else {
        let currentJobStatusFilter = currentFilter.value.jobStatus;
        let idx = currentJobStatusFilter.indexOf(status);

        if (idx !== -1) {
          currentJobStatusFilter.splice(idx, 1);
        } else {
          currentJobStatusFilter.push(status);
        }

        root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
          ...currentFilter.value,
          jobStatus: currentJobStatusFilter.sort(),
        });
      }
    };
    const showFilter = function () {
      root.$eventHub.$emit(EVENT_SHOW_FILTER);
    }

    //
    const showExpiredJobs = ref(true);
    const expiredJobs = computed(() => {
      return _.filter(props.jobs, isJobExpired);
    })

    //
    const unsubmittedJobs = computed(() => {
      return _.uniqBy(JobSubmission.query().where('ready_to_submit', true).get(), 'job_id');
    });
    const showUnsubmittedJobs = ref(false);

    onMounted(() => {
      root.$eventHub.$on('JobClicked', (job) => {
        onJobClicked(job);
      })
    })

    return {
      siteCell: SiteCell,
      JOB_TYPE,

      sites,
      jobStatusCounts,

      selectedJobIds,
      shouldShowSelectCheckbox,
      onJobSelected,

      isJobExpired,
      isJobNotSubmitted,

      getJobChipColor,
      getJobChipIcon,
      getJobStatus,

      getJobExamChipColor,
      getJobExamChipIcon,
      getJobExamLabel,

      getJobLoadTestChipColor,
      getJobLoadTestChipIcon,
      getJobLoadTestLabel,

      onJobClicked,

      currentFilter,
      currentFilterCount,
      isJobStatusActive,
      toggleJobStatusFilter,
      showFilter,

      showExpiredJobs,
      expiredJobs,

      showUnsubmittedJobs,
      unsubmittedJobs,
    }
  }
}
</script>

<style lang="less">
.grouped-job-list {
  //height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  flex: 1 1 0;

  .site-cell {
    border-bottom: solid 1px lightgray;
  }
}
</style>
