<template>
  <v-container fluid class="login">
    <v-row>
      <v-col>
        <v-img src="../assets/emsd_logo.png"
               height="10vh"
               max-height="200px"
               contain></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field :label="$t('login.username')"
                      v-model="username"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field :label="$t('login.password')"
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                      @click:append="showPassword = !showPassword"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block
               color="red"
               dark
               @click="nextStep">
          {{ $t('next') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-footer absolute
              color="transparent"
              class="login-footer">
      <v-container fluid>
        <v-row no-gutters>
          <v-col>Contact EMSD (SVSD/S/CS Team) at</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>XXXX-XXXX</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>Or</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>XXX@XXXX.com</v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script>
import {onMounted, onUnmounted, ref} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'Login',
  components: {},
  setup(props, {root}) {
    onMounted(() => {
      root.$store.dispatch(ACTION_TYPES.HIDE_APPBAR);
    });

    onUnmounted(() => {
      root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR);
    })

    const username = ref('');
    const password = ref('');
    const nextStep = async function () {
      try {
        await root.$store.dispatch(ACTION_TYPES.LOGIN, {
          username: username.value,
          password: password.value,
        });

        if (!root.$store.getters.rpe) {
          root.$router.replace({name: 'rpe'});
        } else {
          root.$router.replace({name: 'job-list'});
        }
      } catch (e) {
        console.log(e);
      }
    };

    return {
      username,
      password,
      showPassword: ref(false),
      nextStep,
    }
  }
}
</script>

<style lang="less">
.login {
  .login-footer {
    font-size: 60%;
  }
}
</style>
