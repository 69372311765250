import VuexORM, {Model} from '@vuex-orm/core'
import {v4 as uuid} from "uuid";

const database = new VuexORM.Database();

export class LalgFile extends Model {
    static entity = 'lalg_files'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            filename: this.string(),
            mime: this.string(),
            coordinates: this.string(),
            cached: this.boolean(false),

            job_id: this.uid(null),
            job_submission_id: this.uid(null),
        }
    }
}

export class Contract extends Model {
    static entity = 'contracts'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(),
            name: this.attr(),
            start: this.attr(),
            end: this.attr(),

            // schedule_plans: this.hasMany(SchedulePlan, 'contract'),
            visible_schedules: this.hasMany(SchedulePlan, 'contract'),
        }
    }
}

export class SchedulePlan extends Model {
    static entity = 'schedulePlans'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(),
            name: this.attr(),
            type: this.attr(),
            contract: this.attr(),

            contractObj: this.belongsTo(Contract, 'contract'),
            jobs: this.hasMany(Job, 'schedule_plan'),
        }
    }
}

export class Client extends Model {
    static entity = 'clients'
    static primaryKey = 'code'

    static fields() {
        return {
            code: this.attr(),
            name: this.attr(),

            sites: this.hasMany(Site, 'id'),
        }
    }
}

export class Site extends Model {
    static entity = 'sites'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            client_id: this.attr(),
            district: this.attr(),
            region: this.attr(),
            name: this.attr(),
            abbreviation: this.attr(),
            address: this.attr(),
            street: this.attr(),
            contact_name: this.attr(),
            contact_no: this.attr(),
            coordinates: this.attr(),
            memo_attn: this.attr(),

            client: this.belongsTo(Client, 'client_id'),
            contacts: this.hasMany(Contact, 'site_id')
        }
    }
}

export class Contact extends Model {
    static entity = 'contacts'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            site_id: this.attr(),
            name: this.attr(),
            post: this.attr(),
            phone: this.attr(),
            fax: this.attr(),
            address: this.attr(),
            email: this.attr(),

            site: this.belongsTo(Site, 'site_id'),
        }
    }
}

export class EquipmentType extends Model {
    static entity = 'equipment_types'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(),
            abbreviation: this.attr(),
            remarks: this.attr(),
            load_test_form: this.attr(),
            examination_form: this.attr(),
        }
    }
}

export class Equipment extends Model {
    static entity = 'equipments'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            site_id: this.uid(),
            equipment_type: this.uid(),
            equipment_reg_no: this.attr(),
            equipment_code: this.attr(),
            coordinates: this.attr(null),
            address_detail: this.attr(null),
            description: this.attr(null),

            first_service: this.attr(),
            install_date: this.attr(),

            load_test_required: this.attr(),
            load_test_interval: this.attr(),
            load_test_form: this.attr(),
            last_load: this.attr(),
            next_load: this.attr(),

            examination_required: this.attr(),
            examination_interval: this.attr(),
            examination_form: this.attr(),
            last_exam: this.attr(),
            next_exam: this.attr(),
            require_checklist: this.attr(),

            jib_length: this.attr(),
            radius: this.attr(),
            safe_working_load: this.attr(),
            test_load: this.attr(),
            maker: this.attr(),
            model: this.attr(),
            serial_no: this.attr(),
            remarks: this.attr(),
            room: this.attr(),

            site: this.belongsTo(Site, 'site_id'),
            // equipmentType: this.belongsTo(EquipmentType, 'equipment_type'),

            last_exam_submission: this.attr(),
            last_load_submission: this.attr(),
            last_exam_cert: this.attr(),
            last_load_cert: this.attr(),

            // certificates: this.hasMany(Certificate, 'equipment'),
        }
    }
}

export class Job extends Model {
    static entity = 'jobs'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            client_id: this.attr(),
            schedule_plan: this.attr(),
            equipment_id: this.uid(),
            job_type: this.attr(null),
            job_date: this.attr(null),
            job_status: this.attr(null),
            completed_at: this.attr(null),
            extended_until: this.attr(null),

            exam_submission: this.uid(),
            load_test_submission: this.uid(),
            last_exam_submission: this.belongsTo(JobSubmission, 'exam_submission'),
            last_load_test_submission: this.belongsTo(JobSubmission, 'load_test_submission'),

            submissions: this.hasMany(JobSubmission, 'job_id'),

            equipment: this.belongsTo(Equipment, 'equipment_id'),
        }
    }
}

export class JobSubmission extends Model {
    static entity = 'job_submissions'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(() => uuid()),
            job_id: this.uid(),
            type: this.attr(),
            coordinates: this.attr(),
            address_detail: this.attr(),
            result: this.attr(),
            fail_reason: this.attr(),
            rpe_name: this.attr(),
            rpe_no: this.attr(),
            rpe_qualification: this.attr(),
            rpe_discipline: this.attr(),

            // photo_ids: this.attr(),
            // photos: this.hasMany(LalgFile, 'job_submission_id'),
            photos: this.attr(),
            photoObjs: this.hasMany(LalgFile, 'job_submission_id'),
            job: this.belongsTo(Job, 'job_id'),

            checklist_result: this.attr(false),

            jib_length: this.attr(null),
            radius: this.attr(null),
            safe_working_load: this.attr(null),
            test_load: this.attr(null),
            form_3_7: this.attr(null),
            form_3_9: this.attr(null),

            ready_to_submit: this.attr(false),

            created_at: this.attr(null),
            uploaded_at: this.attr(null),
        }
    }
}

// export class Certificate extends Model {
//     static entity = 'certificates'
//     static primaryKey = 'id'
//
//     static fields() {
//         return {
//             id: this.uid(() => uuid()),
//             equipment: this.uid(),
//             signed: this.attr(),
//             type: this.attr(),
//
//             created_at: this.attr(null),
//         }
//     }
// }

export class PendingUpload extends Model {
    static entity = 'pending_upload'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(() => uuid()),

            submission_id: this.uid(),

            job_submission: this.belongsTo(JobSubmission, 'submission_id'),
        }
    }
}

database.register(LalgFile);
database.register(Contract);
database.register(SchedulePlan);
database.register(Client);
database.register(Site);
database.register(Contact);
database.register(EquipmentType);
database.register(Equipment);
database.register(Job);
database.register(JobSubmission);
// database.register(Certificate);
database.register(PendingUpload);


export default VuexORM.install(database)
