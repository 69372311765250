import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);

const messages = {
    'en': {
        login: {
            username: 'Username',
            password: 'Password',
        },
        account: {
            contractor: 'Contractor',
            contract_id: 'Contract No.',
            schedule_plan_id: 'Schedule Plan',
            rpe_name: 'Full Name on ID Card',
            rpe_no: 'RPE Registration No.',
            rpe_qualification: 'Qualification',
            rpe_discipline: 'Discipline',
            rpe_valid_from: 'Latest Validity Starting From'
        },
        equipment: {
            client_code: 'Client Code',
            client_name: 'Owner',
            equipment_reg_no: 'Reg. No.',
            equipment_code: 'Equip Code',
            description: 'Description',
            next_exam: 'Next Exam',
            next_load: 'Next Load Test',
            exam_form: 'Exam Form',
            load_test_form: 'Load Test Form',
            safe_working_load: 'Max SWL',
            test_load: 'Test Load',
            maker: 'Maker',
            model: 'Model',
            serial_no: 'Serial No.',
            install_date: 'Install Date',
            remarks: 'Remarks',
            photos: 'Photos',
            address_detail: 'Detailed Address',
        },
        site: {
            title: 'Venue',
            client: 'Owner',
            client_code: 'Client Code',
            address: 'Address',
            district: 'District',
            region: 'Region',
            contact_name: 'Contact Name',
            contact_no: 'Contact No.',
            coordinates: 'Geo-location',
        },
        contact: {
            name: 'Contact Person',
            post: 'Post',
            phone: 'Contact Number',
            email: 'Email',
            fax: 'Fax',
            address: 'Address',
        },
        site_list: {
            title: 'Venues',
        },
        job_list: {
            title: 'List of LA/LG Assigned',

            filter: 'Sort & Filter',
            clear_filter: 'Clear All Filters',
            refresh_job: 'Refresh Jobs',
            reload_job: 'Reload Jobs',
            reload_job_confirm_msg: 'Confirm Clear Cache and Reload Jobs from server? Please ensure stable internet connection',
            schedule_job: 'Schedule Jobs',
            schedule_job_confirm_msg: 'Scheduling {job_no} jobs at {date}?',
            batch_submit: 'Batch-submit Un-submitted Forms',
            batch_submit_confirm_msg: 'Batch-submit {count} Un-submitted Forms?',

            sort_by: 'Sort By',
            job_date: 'Scheduled Date',
            job_status: 'Job Status',
            site: 'Venue',
            client: 'Client',
            district: 'District',
            region: 'Region',
            job_type: 'Job Type',
            attn: 'Attn',


            offline_msg: 'Unable to get latest jobs at this time. Please check your network settings and try again.'
        },
        job_detail: {
            equipment: 'Equipment',
            venue: 'Venue',
            contact: 'Contact',
            past_exams: 'Photos',

            load_test: 'Load Test',
            exam: 'Exam',
            load_test_and_exam: 'Load Test & Exam',

            schedule_job_first: 'Please Schedule Job First',
            cannot_resubmit_msg: 'Examination results has been submitted already. If you want to examine this LA/LG again, please contact EMSD',
            resubmitted_confirm_msg: 'Examination result has been submitted already. Are you sure you want to examine this LA/LG again?',
            resume_msg: 'There is a un-submitted Form for this LA/LG. Click "MODIFY" to edit.',
        },
        job_form: {
            change_coordinates: 'View Geo-location on Map',
            delete_image_confirm_msg: 'Confirm Delete Photo?',

            jib_length: 'Length of jib (metres)',
            radius: 'Radius (metres)',
            test_load: 'Test Load (tonnes)',
            safe_working_load: 'Safe Working Load (tonnes)',
            form_3_7: 'In the case of a crane with a derricking jib or jibs the maximum radius at which the jib or jibs may be worked (in metres)',
            form_3_9: 'In the case of a crane, state whether the automatic safe load indicator is in good working order',

            result: 'Checking Result',
            result_pass: 'Pass',
            result_fail: 'Fail',
            fail_reason: 'Fail Reason',
            result_other: 'Other',
            other_reason: 'Other Reason',
            missing: 'Missing',
            under_repair: 'Under Repair',
            not_ready: 'Not Ready',

            confirm_exit_msg: 'Examination results have not been submitted yet and will be saved as draft upon exit. Are you sure you want to exit?',

            submit_now_msg: 'Submit Now or later using the "Batch-submit Un-submitted Forms" button?',
            submit_now: 'Now',
            submit_later: 'Later',

            confirm_submit_msg: 'Submit Confirm Message',
            submit_fail_msg: 'Submission was unsuccessful due to disconnection from mobile network. 1) You may now try to close this message and submit again if the mobile network becomes immediately available. 2) Alternatively by closing this message and leaving this submission page, the present finished task is now saved as one of the "Un-submitted Forms" in the List of LA/LG Assigned. Once you have a stable mobile connection, you can opt to "Batch-submit Un-submitted Forms" to process this task together with all other un-submitted ones in a batch OR access and submit the tasks individually.',
            submit_success_msg: 'The draft certificate(s) will be ready in PDF format on the Admin Portal at midnight. Please login with the account given to the Surveying Contractor to download and endorse the printed copies.',
        },
        schedule: {
            title: 'Schedule',
        },
        search: {
            title: 'Search LA/LG',
            reg_no: 'EMSD Registration No.',

            invalid_qr_code: 'Invalid QR Code',
            equipment_not_assigned: 'Invalid Registration No. or LA\\LG is not assigned to you',
        },
        qr_code_scanner: {
            title: 'Scan QR Code',
        },
        input_reg_no: {
            title: 'Input Reg. No.',
        },
        rpe_info: {
            title: 'RPE Info',
            label: 'View and Edit your RPE info',
            save: 'Save',
        },
        change_contract: {
            title: 'Contract / Schedule Plan',
            label: 'Select a Contract and Schedule Plan to view assigned LA/LG under that schedule plan',
        },
        more: {
            title: 'More',
        },

        job_status: {
            pending: 'Unfinished',
            scheduled: 'Scheduled',
            pass: 'Pass',
            fail: 'Fail',
            completed: 'Completed',
            cancelled: 'Cancelled',
            missing: 'Missing',
            not_ready: 'Not Ready',
            under_repair: 'Under Repair',
        },
        job_type: {
            load_test_and_exam: 'Load Test and Examination',
            LOAD_TEST: 'Load Test and Examination',
            EXAM: 'Examination',
        },
        district: {
            hong_kong: 'Hong Kong Island',
            kowloon: 'Kowloon',
            new_territories: 'New Territories',
            island: 'Islands',
        },
        error_messages: {
            default: 'Something Went Wrong. Please Try Again Later.',

            login_fail: 'Incorrect Username or Password',

            session_expired: 'Session Expired. Refreshing Session...',
            invalid_schedule_plan_id: 'Could not find schedule plan',
        },
        no_network: 'NO NETWORK',
        next: 'Next',
        submit: 'Submit',
        confirm: 'Confirm',
        cancel: 'Cancel',
        select_all: 'Select All',
        view: 'View',
        logout: 'Logout',
    },
    'zh': {},
};

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

export default i18n;
