<template>
  <v-container fluid class="pa-0">
    <v-slide-group show-arrows="mobile">
      <v-slide-item v-for="(src, index) in photos" :key="src">
        <v-card class="ma-2">
          <v-card-text class="pa-0">
            <v-img :src="getURL(src)"
                   contain
                   width="100px"
                   height="100px"
                   @click="showImage(index)"></v-img>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <viewer :options="viewerOptions"
            :images="photos"
            ref="photoViewer"
            @inited="onViewerInit">
      <template slot-scope="{images}">
        <img v-for="src in images"
             :key="src"
             :src="src"
             style="display: none">
        <div class="photo-viewer-delete-icon"
             v-if="viewerShown && !hideDeleteBtn"
             @click="deleteImage">
          <v-icon>fas fa-trash</v-icon>
        </div>
      </template>
    </viewer>
  </v-container>
</template>

<script>
import {ref, computed} from '@vue/composition-api';

export default {
  name: 'PhotoCarousel',
  props: {
    value: Array,
    hideDeleteBtn: Boolean,
  },
  setup(props, {root, emit}) {

    const showPlaceHolder = computed(() => {
      return !(props.value && props.value.length && props.value.length > 0);
    })
    const photos = computed(() => {
      return (props.value && props.value.length && props.value.length > 0) ? props.value.map(getURL) : [
        require('../assets/placeholder.png'),
      ];
    });
    const getURL = function (f) {
      if (f instanceof File) {
        return URL.createObjectURL(f);
      } else {
        return f;
      }
    }

    const viewer = ref(null);
    const viewerShown = ref(false);
    const showingImageIdx = ref(null);
    const onViewerInit = function (e) {
      viewer.value = e;
    };
    const viewerOptions = ref({
      inline: false,
      title: false,
      toolbar: {
        prev: {show: true, size: 'large'},
        next: {show: true, size: 'large'},
      },
      rotatable: false,
      zIndex: 205,

      show(e) {
        viewerShown.value = true;

        root.$nextTick(()=> {
          let deleteBtn = window.document.getElementsByClassName('photo-viewer-delete-icon')[0];
          if (deleteBtn) {
            deleteIconParent.value = deleteBtn.parentElement;
            window.document.body.appendChild(deleteBtn);
          }
        })
      },
      hide(e) {
        let deleteBtn = window.document.getElementsByClassName('photo-viewer-delete-icon')[0];
        if (deleteBtn) {
          deleteIconParent.value.appendChild(deleteBtn);
        }

        viewerShown.value = false;
        showingImageIdx.value = null;
      },
      viewed(e) {
        viewerShown.value = true;
        showingImageIdx.value = e.detail.index;
      },
    });

    const showImage = function (index) {
      if (!showPlaceHolder.value) {
        viewer.value.view(index);
      } else {
        emit('placeholder');
      }
    };

    const deleteIconParent = ref(null);
    const deleteImage = async function () {
      if (await root.$confirm(root.$t('job_form.delete_image_confirm_msg'))) {
        emit('delete', showingImageIdx.value);
        viewer.value.hide();
      }
    };


    return {
      photos,
      viewerOptions,
      onViewerInit,
      getURL,
      viewerShown,
      showImage,
      deleteImage,
    };
  },
}
</script>

<style lang="less">
.photo-viewer-delete-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  height: 100px;
  overflow: hidden;
  position: fixed;
  left: -50px;
  top: -50px;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
  width: 100px;
  z-index: 9999;

  .fa-trash {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: white;
    font-size: 32px;
  }
}
</style>
