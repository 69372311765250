<template>
  <v-container fluid class="select-contract">
    <v-row>
      <v-col>
        <v-img src="../assets/emsd_logo.png"
               height="10vh"
               max-height="200px"
               contain></v-img>
      </v-col>
    </v-row>
    <v-container v-if="account && contractor" fluid>
      <v-form v-model="isFormValid">
        <v-row>
          <v-col>
            <v-text-field readonly
                          :label="$t('account.contractor')"
                          :value="contractor.name">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select outlined
                      :label="$t('account.contract_id')"
                      :placeholder="$t('account.contract_id')"
                      :items="contracts"
                      item-text="id"
                      return-object
                      v-model="selectedContract"
                      :rules="requiredRules">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select outlined
                      :label="$t('account.schedule_plan_id')"
                      :placeholder="$t('account.schedule_plan_id')"
                      :items="schedulePlans"
                      item-text="name"
                      return-object
                      v-model="selectedSchedulePlan"
                      :rules="requiredRules">
            </v-select>
          </v-col>
        </v-row>
        <v-row class="select-contract-rpe-info-header text-left">
          <v-col>
            <label>RPE Info: </label>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox outlined
                        :items="rpeOptions"
                        :label="$t('account.rpe_name')"
                        :placeholder="$t('account.rpe_name')"
                        @change="onRpeNameChange"
                        v-model="rpeName"
                        :rules="requiredRules">
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field outlined
                          prefix="RP"
                          :label="$t('account.rpe_no')"
                          :placeholder="$t('account.rpe_no')"
                          v-model="rpeNo"
                          :rules="rpeNoRules">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field outlined
                          :label="$t('account.rpe_qualification')"
                          :placeholder="$t('account.rpe_qualification')"
                          v-model="rpeQualification"
                          :rules="requiredRules">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field outlined
                          :label="$t('account.rpe_discipline')"
                          :placeholder="$t('account.rpe_discipline')"
                          v-model="rpeDiscipline"
                          :rules="requiredRules">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DatePickerInput outlined
                             :label="$t('account.rpe_valid_from')"
                             v-model="rpeValidFrom"
                             :error-message="rpeValidFromErrorMsg"></DatePickerInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block
                   color="primary"
                   :disabled="!isFormValid || !!rpeValidFromErrorMsg"
                   @click="saveRpe">
              {{ $t('submit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";
import {Contract} from "@/store/models";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from 'luxon';

export default {
  name: 'SelectContract',
  components: {DatePickerInput},
  setup(props, {root}) {
    const isFormValid = ref(false);
    const account = computed(() => root.$store.getters.account);
    const contractor = computed(() => root.$store.getters.contractor);

    const contracts = computed(() => Contract.query().withAllRecursive().all());
    const selectedContract = ref(null);

    const schedulePlans = computed(() => {
      if (!selectedContract.value) {
        return [];
      }
      return selectedContract.value.visible_schedules;
    });
    const selectedSchedulePlan = ref(null);

    const rpeOptions = ref([]);
    const getRpe = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'app/rpe/',
      });

      if (response.body.success) {
        rpeOptions.value = response.body.rpes.map((r) => {
          return {
            text: r.rpe_name,
            value: {
              ...r,
              rpe_no: r.rpe_no.replaceAll('RP', ''),
            },
          }
        })
      }
    }
    const onRpeNameChange = function (e) {
      if (e) {
        rpeName.value = e.value.rpe_name;
        rpeNo.value = e.value.rpe_no;
        rpeQualification.value = e.value.rpe_qualification;
        rpeDiscipline.value = e.value.rpe_discipline;
      }
    }

    const rpeName = ref(null);
    const rpeNo = ref('');
    const rpeQualification = ref('');
    const rpeDiscipline = ref('');
    const rpeValidFrom = ref('');

    const requiredRules = ref([
      value => !!value || 'Required',
    ]);
    const rpeNoRules = ref([
      value => !!value || 'Required',
      value => value.toString().length === 7 || 'RPE Registration No. must have consist of 7 digits',
    ]);
    const rpeValidFromErrorMsg = computed(() => {
      if (DateTime.fromJSDate(new Date).plus({year: 1}) > DateTime.fromFormat(rpeValidFrom.value, 'yyyy-MM-dd')) {
        return null;
      }
      return 'Your registration must be valid within the following year'
    })
    const saveRpe = async function () {
      await root.$store.dispatch(ACTION_TYPES.SET_CURRENT_CONTRACT, {contractNo: selectedContract.value.id, schedulePlanId: selectedSchedulePlan.value.id});

      await root.$store.dispatch(ACTION_TYPES.SET_RPE, {
        name: rpeName.value,
        rpe_no: 'RP' + rpeNo.value,
        rpe_qualification: rpeQualification.value,
        rpe_discipline: rpeDiscipline.value,
        valid_from: rpeValidFrom.value,
      });
      root.$router.replace({name: 'job-list'});
    };

    onMounted(() => {
      getRpe();

      root.$store.dispatch(ACTION_TYPES.HIDE_APPBAR);
      root.$store.dispatch(ACTION_TYPES.RETRIEVE_CONTRACTS);
    });

    onUnmounted(() => {
      root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR);
    })

    return {
      isFormValid,
      account,
      contractor,

      contracts,
      selectedContract,

      schedulePlans,
      selectedSchedulePlan,

      rpeOptions,
      onRpeNameChange,

      rpeName,
      rpeNo,
      rpeQualification,
      rpeDiscipline,
      rpeValidFrom,
      requiredRules,
      rpeNoRules,
      rpeValidFromErrorMsg,
      saveRpe,
    }
  }
}
</script>

<style lang="less">
.select-contract {
  .select-contract-rpe-info-header {
    margin-left: -24px;
    margin-right: -24px;
    background-color: #e9ebf0;
    color: #707071;
  }
}
</style>
