import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Submission Unsuccessful ")]),_c(VCardText,{staticClass:"text-left"},[_c(VRow,{staticClass:"font-weight-bold"},[_c(VCol,[_vm._v("Submission was unsuccessful due to network issue")])],1),_c(VRow,[_c(VCol,[_vm._v(" This finished task has been saved as \"Un-submitted Form\". You may now close this message and try the following: ")])],1),_c(VRow,[_c(VCol,[_c('ol',[_c('li',[_vm._v("submit again once the mobile network becomes available.")]),_c('li',[_vm._v("leave this submission page, once the mobile network becomes available, click \"Batch-submit Un-submitted Forms\" on the top-right corner to submit all \"Un-submitted Forms\"")])])])],1)],1),_c(VCardActions,{staticClass:"text-right"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }