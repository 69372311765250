<template>
  <v-app>
    <AppDrawer v-model="drawerShown"></AppDrawer>
    <v-app-bar app
               color="primary"
               dark
               v-if="$store.getters.showAppBar">
      <!--      <v-app-bar-nav-icon @click.stop="drawerShown = !drawerShown"></v-app-bar-nav-icon>-->
      <v-app-bar-nav-icon @click.stop="drawerShown = !drawerShown"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $store.getters.appBarTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon
             v-if="$store.getters.showAppBarEllipsis"
             @click="moreActionDialogShown = true">
        <v-icon>fas fa-ellipsis-v</v-icon>
      </v-btn>
    </v-app-bar>

    <v-offline @detected-condition="networkConditionChanged"></v-offline>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackBarShown"
                :timeout="3000">
      {{ snackBarContent }}
    </v-snackbar>

    <v-dialog v-model="moreActionDialogShown"
              width="80%"
              persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn block
                     @click="showFilterDialog">{{ $t('job_list.filter') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block
                     @click="refreshJob">{{ $t('job_list.refresh_job') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block
                     @click="reloadJob">{{ $t('job_list.reload_job') }}
              </v-btn>
            </v-col>
          </v-row>
          <!--          <v-row>-->
          <!--            <v-col>-->
          <!--              <v-btn block-->
          <!--                     @click="showScheduleDialog">{{ $t('job_list.schedule_job') }}-->
          <!--              </v-btn>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-row>
            <v-col>
              <v-btn block
                     large
                     :disabled="unsubmittedJobs.length === 0"
                     @click="batchSubmit">
                Batch-submit <br>Un-submitted Forms
                <!--                {{ $t('job_list.batch_submit') }}-->
                {{ unsubmittedJobs.length > 0 ? `(${unsubmittedJobs.length})` : '' }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block color="primary"
                     @click="moreActionDialogShown = false">{{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <JobFilter :shown="filterDialogShown"
               @change="onJobFilterChanged"
               @cancel="filterDialogShown = false"></JobFilter>

    <!--    <JobScheduler :shown="scheduleDialogShown"-->
    <!--                  @finish="onJobScheduleFinish"-->
    <!--                  @cancel="scheduleDialogShown = false"></JobScheduler>-->

    <PdfViewer :shown="pdfViewerShown"
               :src="pdfSrc"
               @close="pdfViewerShown = false"></PdfViewer>

    <v-overlay :value="isLoading"
               z-index="999">
      {{ loadingMessage }}
    </v-overlay>
  </v-app>
</template>

<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";
import AppDrawer from "@/components/AppDrawer";
import VOffline from 'v-offline';
import JobFilter from "@/components/JobFilter";
import {EVENT_SHOW_FILTER, EVENT_SHOW_MORE, EVENT_SHOW_PDF, EVENT_SHOW_SCHEDULER, JOB_STATUS} from "@/constants";
import PdfViewer from "@/components/PdfViewer";
import {JobSubmission} from "@/store/models";
import ChecklistModal from "@/components/ChecklistModal";

export default {
  name: 'App',
  components: {PdfViewer, JobFilter, AppDrawer, VOffline},
  setup(props, {root}) {
    // Side Menu
    const drawerShown = ref(false);

    // App Bar Ellipsis
    const onAppBarEllipsisClicked = function () {
      root.$eventHub.$emit('onAppBarEllipsisClicked');
    };

    // Snack Bar
    const snackBarShown = ref(false);
    const snackBarContent = computed(() => root.$store.getters.snackBarContent);

    watch([snackBarContent], ([content]) => {
      if (content) {
        snackBarShown.value = true;
      }
    }, {immediate: true});

    watch(() => snackBarShown.value, (shown, oldShown) => {
      if (oldShown && !shown) {
        root.$store.dispatch(ACTION_TYPES.HIDE_SNACKBAR);
      }
    }, {immediate: true});

    // More
    const moreActionDialogShown = ref(false);

    // Filter
    const filterDialogShown = ref(false);
    const showFilterDialog = function () {
      moreActionDialogShown.value = false;
      root.$eventHub.$emit(EVENT_SHOW_FILTER);
    };
    const onJobFilterChanged = function (filters) {
      root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, filters);
      filterDialogShown.value = false;
    };

    //
    const refreshJob = async function () {
      moreActionDialogShown.value = false;
      await root.$store.dispatch(ACTION_TYPES.RETRIEVE_JOBS, {forceReload: false})
    }
    const reloadJob = async function () {
      if (await root.$confirm(root.$t('job_list.reload_job_confirm_msg'))) {
        moreActionDialogShown.value = false;
        await root.$store.dispatch(ACTION_TYPES.RETRIEVE_JOBS, {forceReload: true})
      }
    }

    // Scheduler
    const scheduleDialogShown = ref(false);
    const showScheduleDialog = function () {
      moreActionDialogShown.value = false;
      root.$eventHub.$emit(EVENT_SHOW_SCHEDULER);
    };
    const onJobScheduleFinish = async function () {
      scheduleDialogShown.value = false;
      await root.$store.dispatch(ACTION_TYPES.RETRIEVE_JOBS, {forceReload: false});
    };

    //
    const unsubmittedJobs = computed(() => {
      return JobSubmission.query().where('ready_to_submit', true).get();
    });
    const batchSubmit = async function () {
      if (await root.$confirm(root.$t('job_list.batch_submit_confirm_msg', {
        count: unsubmittedJobs.value.length,
      }))) {
        moreActionDialogShown.value = false;

        let submissionIds = unsubmittedJobs.value.map((s) => s.id);

        await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, 'Submitting...')

        const rpe = root.$store.getters.rpe;
        try {
          for (const [idx, submissionId] of submissionIds.entries()) {
            await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, `Submitting (${idx + 1}/${submissionIds.length}) ...`);
            await root.$store.dispatch(ACTION_TYPES.SUBMIT_FORM, {jobSubmissionId: submissionId, rpe});
          }

          await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null)
          await root.$dialog.error({text: root.$t('job_form.submit_success_msg')});
        } catch (e) {
          console.log(e);
          await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null)
        }
      }

      // await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, `Submitting Jobs (2/13) ...`);
    };

    // Loading Spinner
    const isLoading = computed(() => {
      return root.$store.getters.requestCount !== 0 || loadingMessage.value;
    });
    const loadingMessage = computed(() => root.$store.getters.loadingMessage);

    // V-Offline
    const networkConditionChanged = async function (e) {
      console.log(`NETWORK CONDITION CHANGED: ${e}`);
      await root.$store.dispatch(ACTION_TYPES.SET_ONLINE_STATUS, e);
    };

    // PDF Viewer
    const pdfViewerShown = ref(false);
    const pdfSrc = ref(null);

    const isLoggedIn = computed(() => root.$store.getters.isLoggedIn);
    watch(() => isLoggedIn.value, (newValue, oldValue) => {
      if (!newValue && oldValue) {
        console.log("LOGGED OUT");
        root.$router.replace({name: 'login'});
      }
    })

    // Landing
    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);

      // Check if id_token is present
      const urlParams = new URLSearchParams(window.location.search);
      const id_token = urlParams.get('id_token');

      root.$store.dispatch(ACTION_TYPES.SET_ONLINE_STATUS, navigator.onLine);

      if (id_token) {
        await root.$store.dispatch(ACTION_TYPES.AUTHENTICATE_TEAMNOTE_JWT, id_token);
      }

      try {
        await root.$store.dispatch(ACTION_TYPES.GET_ME);

        if (!root.$store.getters.rpe) {
          root.$router.replace({name: 'rpe'});
        } else {
          root.$store.dispatch(ACTION_TYPES.RETRIEVE_CONTRACTS);
          root.$router.replace({name: 'job-list'});
        }

      } catch (response) {
        root.$router.replace({name: 'login'});
      }

      // More
      root.$eventHub.$on(EVENT_SHOW_MORE, () => {
        showFilterDialog();
      })

      // Filter
      root.$eventHub.$on(EVENT_SHOW_FILTER, () => {
        filterDialogShown.value = true;
      })

      // Scheduler
      root.$eventHub.$on(EVENT_SHOW_SCHEDULER, () => {
        scheduleDialogShown.value = true;
      })

      // PDF
      root.$eventHub.$on(EVENT_SHOW_PDF, (src) => {
        pdfSrc.value = src;
        pdfViewerShown.value = true;
      });

      const currentFilter = root.$store.getters.jobFilters;
      root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
        ...currentFilter,
        jobStatus: [
          JOB_STATUS.PENDING,
        ],
        showExpired: true,
      });
    });

    return {
      drawerShown,
      onAppBarEllipsisClicked,

      snackBarShown,
      snackBarContent,

      // More
      moreActionDialogShown,

      // Filter
      filterDialogShown,
      showFilterDialog,
      onJobFilterChanged,

      //
      refreshJob,
      reloadJob,

      // Schedule Job
      scheduleDialogShown,
      showScheduleDialog,
      onJobScheduleFinish,

      //
      unsubmittedJobs,
      batchSubmit,

      // PDF
      pdfSrc,
      pdfViewerShown,

      isLoading,
      loadingMessage,

      networkConditionChanged,
    }
  },
}
</script>

<style lang="less">
body {
  overscroll-behavior: contain;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .no-network-banner {
    color: white;
  }
}
</style>
