<template>
  <v-container class="job-form px-0 fill-height" fluid v-if="job">
    <v-app-bar color="primary"
               dark
               flat
               absolute>
      <v-btn icon
             @click="back">
        <v-icon>fas fa-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ job.equipment.equipment_reg_no }}</v-toolbar-title>
    </v-app-bar>
    <v-row no-gutters>
      <v-col>
        <OnlineStatusBanner></OnlineStatusBanner>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-banner sticky
                  color="#eff0f8"
                  :value="true"
                  class="text-left">
          <v-row no-gutters>
            <v-col><label><b>Task Type: </b>{{ $t(`job_type.${jobSubmission.type}`) }}</label></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col><label><b>Required Form: </b>{{ requiredForm }}</label></v-col>
          </v-row>
        </v-banner>
      </v-col>
    </v-row>

    <v-container class="text-left job-form-content-container" :style="jobFormContainerStyle" fluid>
      <v-form ref="form">
        <FormGroup :title="$t('site.coordinates')">
          <v-btn color="primary"
                 @click="coordinatePickerShown = true">
            <v-icon class="mr-4">fas fa-map-marker-alt</v-icon>
            {{ $t('job_form.change_coordinates') }}
          </v-btn>
          <CoordinatePicker :value="jobSubmission.coordinates"
                            :shown="coordinatePickerShown"
                            @cancel="onCoordinatePickerCancel"
                            @confirm="onCoordinatePickerConfirm"
                            v-if="job"></CoordinatePicker>
        </FormGroup>
        <FormGroup :title="$t('equipment.address_detail')">
          <v-textarea outlined
                      auto-grow
                      no-resize
                      rows="1"
                      :placeholder="$t('equipment.address_detail')"
                      v-model="addressDetail"></v-textarea>
        </FormGroup>
        <v-input v-model="jobPhotos"
                 :rules="[value=> value.length > 0 || 'Please at least upload one photo']"
                 hide-details="auto">
          <FormGroup :title="$t('equipment.photos')"
                     no-padding>
            <template #title-append>
              <v-btn small @click="selectPhotoSource"
                     :disabled="jobPhotos.length >= jobPhotoLimit">
                <v-icon>fas fa-camera</v-icon>
              </v-btn>
              <CameraCapture :shown="cameraCaptureShown"
                             @cancel="cameraCaptureShown = false"
                             @capture="onCameraCapture"></CameraCapture>
            </template>

            <v-input v-model="jobPhotos">
              <PhotoCarousel :value="jobPhotos"
                             @placeholder="selectPhotoSource"
                             @delete="onPhotoDelete"></PhotoCarousel>
            </v-input>
          </FormGroup>

          <input hidden type="file" ref="finput" accept="image/jpeg" @input="onFileSelected($event)" multiple>
        </v-input>
        <div v-if="isForm3">
          <FormGroup :title="$t('job_form.jib_length')">
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-model="jibLength"></v-textarea>
          </FormGroup>
          <FormGroup :title="$t('job_form.radius')">
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-model="radius"></v-textarea>
          </FormGroup>
          <FormGroup :title="$t('job_form.test_load')">
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-model="testLoad"></v-textarea>
          </FormGroup>
          <FormGroup :title="$t('job_form.safe_working_load')">
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-model="safeWorkingLoad"></v-textarea>
          </FormGroup>
          <FormGroup :title="$t('job_form.form_3_7')">
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-model="form_3_7"></v-textarea>
          </FormGroup>
          <FormGroup :title="$t('job_form.form_3_9')">
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-model="form_3_9"></v-textarea>
          </FormGroup>
        </div>


        <FormGroup :title="$t('job_form.result')">
          <v-radio-group v-model="jobResult" dense class="pt-0 mt-0">
            <v-radio :label="$t('job_form.result_pass')"
                     :value="SUBMISSION_RESULT.PASS"></v-radio>
            <v-radio :label="$t('job_form.result_fail')"
                     :value="SUBMISSION_RESULT.FAIL"></v-radio>
            <v-textarea outlined
                        auto-grow
                        no-resize
                        rows="1"
                        v-if="jobResult === SUBMISSION_RESULT.FAIL"
                        :label="$t('job_form.fail_reason')"
                        v-model="failReason"
                        :rules="[value => !!value || 'Required']"></v-textarea>
            <v-radio :label="$t('job_form.result_other')"
                     :value="SUBMISSION_RESULT.OTHER"></v-radio>
            <v-select outlined
                      v-if="jobResult === SUBMISSION_RESULT.OTHER"
                      :label="$t('job_form.other_reason')"
                      :items="otherOptions"
                      v-model="otherResult"
                      :rules="[value => !!value || 'Required']"></v-select>
          </v-radio-group>
        </FormGroup>
      </v-form>
    </v-container>

    <v-footer absolute>
      <v-btn block
             color="primary"
             @click="$refs.form.validate() && submitForm()">
        {{ $t('submit') }}
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script>
import _ from 'lodash';
import {DateTime} from 'luxon';
import {computed, onMounted, onUnmounted, ref, watch} from "@vue/composition-api";
import {JobSubmission} from "@/store/models";
import {ACTION_TYPES} from "@/store/types";
import CoordinatePicker from "@/components/CoordinatePicker";
import OnlineStatusBanner from "@/components/OnlineStatusBanner";
import PhotoCarousel from "@/components/PhotoCarousel";
import CameraCapture from "@/components/CameraCapture";
import FormGroup from "@/components/FormGroup";
import {JOB_STATUS, SUBMISSION_RESULT, SUBMISSION_TYPE} from "@/constants";
import SubmissionFailDialog from "@/components/SubmissionFailDialog";
import SelectPhotoSource from "@/components/SelectPhotoSource";
import FormSubmitDialog from "@/components/FormSubmitDialog";
import ChecklistModal from "@/components/ChecklistModal";

export default {
  name: 'JobForm',
  props: {
    jobSubmissionId: String,
  },
  components: {FormGroup, CameraCapture, PhotoCarousel, OnlineStatusBanner, CoordinatePicker},
  setup(props, {root, emit}) {
    onMounted(async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          currentGeoLocation.value = pos.coords;
        });

        geoLocationWatchId.value = navigator.geolocation.watchPosition(onGeoLocationUpdate);
      }
    });
    onUnmounted(async () => {
      if (geoLocationWatchId.value) {
        navigator.geolocation.clearWatch(geoLocationWatchId.value);
      }
    });

    const geoLocationWatchId = ref(null);
    const currentGeoLocation = ref(null);
    const onGeoLocationUpdate = function (position) {
      currentGeoLocation.value = position.coords;
    };

    const isOnline = computed(() => {
      return root.$store.getters.isOnline;
    });
    const jobFormContainerStyle = computed(() => {
      return {
        height: `calc(100vh - ${isOnline.value ? 177 : 236}px)`
      }
    })

    const back = async function () {
      if (jobSubmission.value.created_at || jobSubmission.value.ready_to_submit) {
        emit('submit');
      } else {
        // Delete this submission if there is already uploaded submission
        if (_.some(job.value.submissions, (s) => s.type === jobSubmission.value.type && s.created_at)) {
          await JobSubmission.delete(jobSubmissionId.value);
        } else if (!await root.$confirm(root.$t('job_form.confirm_exit_msg'))) {
          return;
        }
        emit('submit');
      }
    };

    const jobSubmissionId = ref(props.jobSubmissionId);
    const jobSubmission = computed(() => JobSubmission.query().withAllRecursive().where('id', jobSubmissionId.value).first());
    const job = computed(() => jobSubmission.value ? jobSubmission.value.job : null);
    const jobSubmissionPhotos = computed(() => jobSubmission.value ? jobSubmission.value.photoObjs : []);
    const requiredForm = computed(() => {
      if (jobSubmission.type === SUBMISSION_TYPE.EXAM) {
        return job.value.equipment['examination_form'];
      } else {
        return `${job.value.equipment['load_test_form']} & ${job.value.equipment['examination_form']}`
      }
    })

    const coordinatePickerShown = ref(false);
    const onCoordinatePickerCancel = function () {
      coordinatePickerShown.value = false;
    };
    const onCoordinatePickerConfirm = async function (coordinateObject) {
      coordinatePickerShown.value = false;

      await JobSubmission.update({
        where: jobSubmissionId.value,
        data: {
          coordinates: JSON.stringify(coordinateObject),
        }
      })
    };

    const jobPhotoLimit = computed(() => root.$store.getters.photoLimit);
    const jobPhotos = ref([]);
    const getJobPhotos = async function () {
      let result = [];
      for (const p of jobSubmission.value.photoObjs) {
        result.push(await root.$store.dispatch(ACTION_TYPES.GET_FILE, {
          file_id: p.id,
          job_submission_id: jobSubmissionId.value,
        }))
      }

      jobPhotos.value = result.map((i) => {
        return root.$convertBase64toFile(i.base64, i.filename);
      });
    }
    watch(() => jobSubmissionPhotos.value, async (newVal, oldVal) => {
      if (!oldVal && newVal.length > 0) {
        await getJobPhotos();
      }
    }, {immediate: true})
    const addPhoto = async function (imgBase64) {
      let filename = `${job.value.equipment.equipment_reg_no}_${DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-ddTHHmmss')}_${jobSubmissionId.value}.jpg`
      await root.$store.dispatch(ACTION_TYPES.SAVE_FILE, {
        base64: imgBase64,
        filename: filename,
        coordinates: JSON.stringify({
          accuracy: currentGeoLocation.value ? currentGeoLocation.value.accuracy : null,
          latitude: currentGeoLocation.value ? currentGeoLocation.value.latitude : null,
          longitude: currentGeoLocation.value ? currentGeoLocation.value.longitude : null,
        }),
        job_submission_id: jobSubmissionId.value,
      });
    };
    const onPhotoDelete = async function (index) {
      let photo = jobSubmission.value.photoObjs[index];

      await root.$store.dispatch(ACTION_TYPES.DELETE_FILE, photo.id);
      await getJobPhotos();
    };

    const finput = ref();
    const onFileSelected = async function (evt) {
      // console.log(evt.target.files[0]);
      if ((jobPhotos.value.length + evt.target.files.length) > jobPhotoLimit.value) {
        await root.$dialog.error({text: `You can only submit a total of ${jobPhotoLimit.value} photos per job`});
        return;
      }

      await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, 'Processing Photos...');

      for (const f of evt.target.files) {
        let imgBase64 = await root.$convertFileToBase64(f);
        let img = new Image();
        imgBase64 = await new Promise((resolve, reject) => {
          img.onload = function () {
            let canvas = document.createElement('canvas');

            let ratio = Math.min(640 / img.height, 640 / img.width, 1);
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;

            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL());
          };
          img.src = imgBase64;
        });
        await addPhoto(imgBase64);
      }

      finput.value.value = '';
      await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null);
      await getJobPhotos();
    }

    const cameraCaptureShown = ref(false);
    const onCameraCapture = async function (imgBase64) {
      await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, 'Processing Photos...');
      await addPhoto(imgBase64);
      cameraCaptureShown.value = false;
      await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null);
      await getJobPhotos();
    };

    const selectPhotoSource = async function () {
      const mode = await root.$dialog.showAndWait(SelectPhotoSource, {showClose: false});

      if (mode === 'camera') {
        cameraCaptureShown.value = true;
      } else if (mode === 'file') {
        finput.value.click();
      }
    }

    const addressDetail = computed({
      get() {
        return jobSubmission.value.address_detail;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {address_detail: val}
        })
      }, 1000),
    });

    const isForm3 = computed(() => {
      return jobSubmission.value.type === SUBMISSION_TYPE.LOAD_TEST && job.value.equipment['load_test_form'] === 'FORM_3';
    })
    const jibLength = computed({
      get() {
        return jobSubmission.value.jib_length;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {jib_length: val}
        })
      }, 1000),
    });
    const radius = computed({
      get() {
        return jobSubmission.value.radius;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {radius: val}
        })
      }, 1000),
    });
    const safeWorkingLoad = computed({
      get() {
        return jobSubmission.value.safe_working_load;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {safe_working_load: val}
        })
      }, 1000),
    });
    const testLoad = computed({
      get() {
        return jobSubmission.value.test_load;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {test_load: val}
        })
      }, 1000),
    });
    const form_3_7 = computed({
      get() {
        return jobSubmission.value.form_3_7;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {form_3_7: val}
        })
      }, 1000),
    });
    const form_3_9 = computed({
      get() {
        return jobSubmission.value.form_3_9;
      },
      set: _.debounce(async function (val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {form_3_9: val}
        })
      }, 1000),
    });

    const jobResult = computed({
      get() {
        return (jobSubmission.value.result === SUBMISSION_RESULT.PASS || jobSubmission.value.result === SUBMISSION_RESULT.FAIL) ? jobSubmission.value.result : SUBMISSION_RESULT.OTHER;
      },
      async set(val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {result: val === SUBMISSION_RESULT.OTHER ? otherResult.value : val}
        })
      },
    });
    const otherResult = computed({
      get() {
        return (jobSubmission.value.result === SUBMISSION_RESULT.PASS || jobSubmission.value.result === SUBMISSION_RESULT.FAIL) ? null : jobSubmission.value.result;
      },
      async set(val) {
        await JobSubmission.update({
          where: jobSubmissionId.value,
          data: {result: (jobSubmission.value.result === SUBMISSION_RESULT.PASS || jobSubmission.value.result === SUBMISSION_RESULT.FAIL) ? jobSubmission.value.result : val}
        })
      },
    });
    const setFailReason = _.debounce(async (val) => {
      await JobSubmission.update({
        where: jobSubmissionId.value,
        data: {fail_reason: val}
      })
    }, 1000)
    const failReason = computed({
      get() {
        return jobSubmission.value.fail_reason;
      },
      set: setFailReason,
    });

    const otherOptions = ref([
      {text: root.$t('job_form.missing'), value: 'MISSING'},
      {text: root.$t('job_form.not_ready'), value: 'NOT_READY'},
      {text: root.$t('job_form.under_repair'), value: 'UNDER_REPAIR'},
    ]);

    const canSubmitForm = computed(() => {
      return jobPhotos.value.length !== 0;
    })

    const submitForm = async function () {
      let checklistResult = null;
      if (job.value.equipment.require_checklist && (jobResult.value === SUBMISSION_RESULT.PASS || jobResult.value === SUBMISSION_RESULT.FAIL)) {
        checklistResult = await root.$dialog.showAndWait(ChecklistModal, {
          required: job.value.equipment.require_checklist,
          fullscreen: true,
          persistent: true,
          showClose: false,
        });
      }

      const submitNow = await root.$dialog.showAndWait(FormSubmitDialog, {showClose: false});

      if (submitNow === undefined) return

      await JobSubmission.update({
        where: jobSubmissionId.value,
        data: {ready_to_submit: true, checklist_result: checklistResult}
      })

      if (submitNow) {
        if (!root.$store.getters.isOnline) {
          console.log('OFFLINE, CANNOT SUBMIT');
          await root.$dialog.showAndWait(SubmissionFailDialog);
          return;
        }

        await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, 'Submitting...')
        try {
          const rpe = root.$store.getters.rpe;
          const result = await root.$store.dispatch(ACTION_TYPES.SUBMIT_FORM, {jobSubmissionId: jobSubmissionId.value, rpe});

          if (result) {
            await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null)
            await root.$dialog.error({text: root.$t('job_form.submit_success_msg')});

            emit('submit');
          }
        } catch (e) {
          console.log(e);
          await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null)
        }
      } else {
        emit('submit');
      }
    };

    return {
      back,

      jobSubmission,
      requiredForm,

      job,

      jobFormContainerStyle,

      coordinatePickerShown,
      onCoordinatePickerCancel,
      onCoordinatePickerConfirm,

      jobPhotoLimit,
      jobPhotos,

      finput,
      onFileSelected,

      cameraCaptureShown,
      onCameraCapture,
      onPhotoDelete,

      selectPhotoSource,

      addressDetail,

      isForm3,
      jibLength,
      radius,
      safeWorkingLoad,
      testLoad,
      form_3_7,
      form_3_9,

      jobResult,
      failReason,
      otherResult,
      otherOptions,
      canSubmitForm,
      submitForm,

      SUBMISSION_RESULT,
    }
  },
}
</script>

<style lang="less">
.job-form {
  background: white;
  padding-top: 56px !important;
  padding-bottom: 48px !important;

  .form-title {
    font-weight: bold;
    font-size: 110%;
  }

  .job-form-content-container {
    overflow: scroll;
  }
}

.viewer-open {
  padding-right: 0 !important;
}
</style>
