import {ACTION_TYPES} from "@/store/types";
import {v4 as uuid} from "uuid";
import i18n from '@/plugins/i18n';

export const apiModule = {
    state: {
        baseUrl: '/api/',
        requests: {},
        loadingMessage: null,
    },
    mutations: {
        startRequest(state, {uid, record}) {
            state.requests = {
                ...state.requests,
                [uid]: record,
            };
        },
        endRequest(state, uid) {
            let d = state.requests;
            delete d[uid];
            state.requests = {...d};
        },
        clearRequest(state) {
            state.requests = {};
            state.loadingMessage = null;
        },
        setLoadingMessage(state, msg) {
            state.loadingMessage = msg;
        },
    },
    actions: {
        [ACTION_TYPES.START_LOADING]: {
            root: true,
            handler({commit}, {uid, record, timeout}) {
                commit('startRequest', {uid, record});

                if (timeout !== -1) {
                    setTimeout(() => {
                        commit('endRequest', uid);
                    }, timeout || 30000);
                }
            }
        },
        [ACTION_TYPES.STOP_LOADING]: {
            root: true,
            handler({commit}, uid) {
                commit('endRequest', uid);
            }
        },
        [ACTION_TYPES.CLEAR_LOADING]: {
            root: true,
            handler({commit}) {
                commit('clearRequest');
            }
        },
        [ACTION_TYPES.SET_LOADING_MSG]: {
            root: true,
            handler({commit}, msg) {
                commit('setLoadingMessage', msg);
            }
        },
        [ACTION_TYPES.CALL_API]: {
            root: true,
            async handler({state, commit, dispatch}, {url, params, opt}) {
                const {not_blocking, throw_error, disable_error_msg, extra} = {
                    not_blocking: false,
                    throw_error: false,
                    disable_error_msg: false,
                    extra: null,
                    ...opt,
                };
                const uid = uuid();

                if (!not_blocking) {
                    dispatch(ACTION_TYPES.START_LOADING, {
                        uid, record: {url, params},
                    });
                }

                try {
                    const response = await this._vm.$http.post(state.baseUrl + url, params, {timeout: 600000, ...extra});
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }
                    return response;
                } catch (response) {
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }

                    if (!disable_error_msg) {
                        if (typeof response.body === 'object' && 'msg' in response.body) {
                            let msg = response.body.msg;
                            if (i18n.te('error_messages.' + msg)) {
                                dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t(`error_messages.${msg}`));
                            } else {
                                dispatch(ACTION_TYPES.SHOW_SNACKBAR, msg);
                            }
                        } else if (response.status === 401 || response.status === 302) {
                            dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t('error_messages.session_expired'));
                        } else {
                            dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t('error_messages.default'));
                        }
                    }

                    if (throw_error) {
                        throw response;
                    } else if (response.status === 401 || response.status === 302) {
                        commit('logout');
                    }
                }
            },
        },
        [ACTION_TYPES.UPLOAD_FILE]: {
            root: true,
            async handler({dispatch, commit}, {files, file_ids, coordinates}) {
                let formData = new FormData();
                let needUpload = false;
                files.forEach(function (f) {
                    if (f instanceof File || f instanceof Blob) {
                        needUpload = true;
                        formData.append('file', f, f.name);
                    }
                });
                if (file_ids) {
                    formData.append('file_ids', file_ids);
                }
                if (coordinates) {
                    formData.append('coordinates', coordinates);
                }
                if (needUpload) {
                    // eslint-disable-next-line no-useless-catch
                    try {
                        const response = await dispatch(
                            ACTION_TYPES.CALL_API,
                            {url: 'app/upload/', params: formData},
                            {throw_error: true},
                        );

                        return files.map((f) => {
                            if (f instanceof File || f instanceof Blob) {
                                return response.body.file_ids[f.name]
                            } else {
                                return f;
                            }
                        });
                    } catch (e) {
                        throw e;
                    }
                } else {
                    return files;
                }
            },
        },
    },
    getters: {
        requestCount(state) {
            return Object.keys(state.requests).length;
        },
        loadingMessage(state) {
            return state.loadingMessage;
        },
    },
}
