import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import {ACTION_TYPES} from "@/store/types";

// VueResource
import VueResource from 'vue-resource';
// Composition API
import VueCompositionAPI from '@vue/composition-api'
// VCalendar
import VCalendar from 'v-calendar';
// Vuetify Confirm
import VuetifyConfirm from 'vuetify-confirm';
// Vuetify Dialog
import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';

//V-Viewer
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import './registerServiceWorker'

//
import {DateTime} from "luxon";
import {EVENT_SHOW_PDF} from "@/constants";

Vue.use(VueResource);
Vue.use(VueCompositionAPI);
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
Vue.use(VuetifyConfirm, {vuetify})
Vue.use(VuetifyDialog, {
    context: {
        router,
        store,
        i18n,
        vuetify,
    },
    confirm: {showClose: false},
    error: {icon: false, showClose: false},
})
Vue.use(Viewer)

Vue.config.productionTip = false;

export const HOST = '/api/';

Vue.use({
    install() {
        //Event Hub
        Vue.prototype.$eventHub = new Vue();

        Vue.prototype.$showAlertMessage = function (message) {
            this.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, message);
        };

        Vue.prototype.$convertBase64toFile = function (base64, filename) {
            var arr = base64.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type: mime});
        };
        Vue.prototype.$convertFileToBase64 = async function (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        Vue.prototype.$parseDate = function (d, fmt = 'yyyy-MM-dd HH:mm') {
            return DateTime.fromISO(d).toFormat(fmt);
        };

        Vue.prototype.$showPdf = async function (file) {
            this.$eventHub.$emit(EVENT_SHOW_PDF, URL.createObjectURL(file));
        };

        Vue.prototype.$showPdfByUrl = async function (url) {
            this.$eventHub.$emit(EVENT_SHOW_PDF, url);
        };

        Vue.prototype.$isTeamNote = function () {
            return Boolean(window.tnConnector);
        };
    }
})

setTimeout(async () => {
    if (window.tnConnector) {
        console.log('init');
        window.tnConnector.init({
            featureId: 'emsd_lalg',
        }, () => {
            window.tnConnector.ui.setSideMenuEnabled({
                isEnabled: false,
            })

            window.tnConnector.ui.setSystemBackButtonEnabled(
                {isEnabled: 0},
                () => {
                    console.log('setSystemBackButtonEnabled success')
                },
                (errorMsg) => {
                    console.log('setSystemBackButtonEnabled failed: ' + errorMsg)
                });

            initVue();
        }, (error) => {
            console.log(error);
        });
    } else {
        initVue();
    }
}, 1000);

function initVue() {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount('#app');
}


