<template>
  <v-navigation-drawer disable-route-watcher
                       color="#F4F5F7"
                       v-model="drawerShown"
                       app
                       width="320px"
                       id="appDrawer">
    <v-row class="pr-4">
      <v-col class="text-right">
        <v-icon @click="drawerShown = false"
                color="primary">
          fas fa-times
        </v-icon>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-for="item in items"
                   :key="item.title"
                   link
                   @click="onMenuItemClick(item)">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'AppDrawer',
  props: {
    value: Boolean,
  },
  setup(props, {emit, root}) {
    // Drawer Open State
    const drawerShown = ref(Boolean(props.value));
    watch(drawerShown, (newValue, oldValue) => {
      if (newValue != null && newValue !== oldValue) {
        emit('input', newValue);
      }
    });

    // Props
    watch(() => props.value, (newValue, oldValue) => {
      if (newValue != null && newValue !== oldValue) {
        drawerShown.value = newValue;
      }
    });

    // Menu Items
    const items = ref([
      {title: root.$t('job_list.title'), icon: 'fas fa-hard-hat', route: {name: 'job-list'}},
      // {title: root.$t('site_list.title'), icon: 'fas fa-hard-hat', route: {name: 'site-list'}},
      // {title: root.$t('schedule.title'), icon: 'fas fa-calendar-alt', route: {name: 'schedule'}},
      {title: root.$t('search.title'), icon: 'fas fa-search', route: {name: 'search'}},
      // {title: root.$t('qr_code_scanner.title'), icon: 'fas fa-qrcode', route: {name: 'qr-code-scanner'}},
      // {title: root.$t('input_reg_no.title'), icon: 'fas fa-search', route: {name: 'input-reg-no'}},
      {title: root.$t('change_contract.title'), icon: 'fas fa-exchange-alt', route: {name: 'change-contract'}},
      {title: root.$t('rpe_info.title'), icon: 'fas fa-user', route: {name: 'rpe-info'}},
      {title: root.$t('logout'), icon: 'fas fa-sign-out-alt', route: {name: 'login'}},
    ]);

    // On Menu Click
    const onMenuItemClick = async (item) => {
      drawerShown.value = false;

      if (item.route.name === 'login') {
        await root.$store.dispatch(ACTION_TYPES.LOGOUT);
      }

      root.$router.push(item.route);
    };

    return {
      drawerShown,
      items,
      onMenuItemClick,
    }
  },
  watch: {
    value(newValue) {
      this.drawerShown = newValue;
    },
    drawerShown(newValue) {
      this.$emit('input', newValue);
    },
  },
}
</script>

<style lang="less">
#appDrawer {
  .v-list-item--active {
    border-left: solid 4px #ed1f29;
    color: #ed1f29;
  }
}
</style>
