import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VSlideGroup,{attrs:{"show-arrows":"mobile"}},_vm._l((_vm.photos),function(src,index){return _c(VSlideItem,{key:src},[_c(VCard,{staticClass:"ma-2"},[_c(VCardText,{staticClass:"pa-0"},[_c(VImg,{attrs:{"src":_vm.getURL(src),"contain":"","width":"100px","height":"100px"},on:{"click":function($event){return _vm.showImage(index)}}})],1)],1)],1)}),1),_c('viewer',{ref:"photoViewer",attrs:{"options":_vm.viewerOptions,"images":_vm.photos},on:{"inited":_vm.onViewerInit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var images = ref.images;
return [_vm._l((images),function(src){return _c('img',{key:src,staticStyle:{"display":"none"},attrs:{"src":src}})}),(_vm.viewerShown && !_vm.hideDeleteBtn)?_c('div',{staticClass:"photo-viewer-delete-icon",on:{"click":_vm.deleteImage}},[_c(VIcon,[_vm._v("fas fa-trash")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }