<template>
  <v-dialog v-model="dialogShown"
            fullscreen
            persistent
            transition="slide-x-reverse-transition">
    <v-container fluid class="filter-dialog-container">
      <v-app-bar color="primary"
                 dark
                 absolute>
        <v-btn icon
               @click="cancel">
          <v-icon>fas fa-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('job_list.filter') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon
               @click="applyFilter">
          <v-icon>fas fa-check</v-icon>
        </v-btn>
      </v-app-bar>
      <!--      <v-row>-->
      <!--        <v-col>-->
      <!--          <v-select outlined-->
      <!--                    hide-details-->
      <!--                    :label="$t('job_list.sort_by')"-->
      <!--                    :items="sortByOptions"-->
      <!--                    v-model="sortBy"></v-select>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <v-row>
        <v-col>
          <v-btn block
                 color="primary"
                 @click="clear">
            {{ $t('job_list.clear_filter')}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ModalSelector outlined
                         multiple
                         chips
                         hide-details
                         :label="$t('job_list.job_status')"
                         :items="jobStatusOptions"
                         v-model="jobStatusFilter"></ModalSelector>

        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ModalSelector outlined
                         multiple
                         chips
                         hide-details
                         :label="$t('job_list.client')"
                         :items="clientOptions"
                         v-model="clientFilter"></ModalSelector>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ModalSelector outlined
                         multiple
                         chips
                         hide-details
                         :label="$t('job_list.attn')"
                         :items="attnOptions"
                         v-model="attnFilter"></ModalSelector>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ModalSelector outlined
                         multiple
                         chips
                         hide-details
                         :label="$t('job_list.region')"
                         :items="regionOptions"
                         v-model="regionFilter"></ModalSelector>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ModalSelector outlined
                         multiple
                         chips
                         hide-details
                         :label="$t('job_list.district')"
                         :items="districtOptions"
                         v-model="districtFilter"></ModalSelector>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ModalSelector outlined
                         multiple
                         chips
                         hide-details
                         :label="$t('job_list.site')"
                         :items="siteOptions"
                         v-model="siteFilter"></ModalSelector>
        </v-col>
      </v-row>
      <!--      <v-row>-->
      <!--        <v-col class="text-left pb-0">-->
      <!--          <h4>{{ $t('job_list.job_type') }}</h4>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <v-row>-->
      <!--        <v-col class="pt-0">-->
      <!--          <v-checkbox multiple-->
      <!--                      v-model="jobTypeFilter"-->
      <!--                      :label="$t('job_type.load_test_and_exam')"-->
      <!--                      :value="JOB_TYPE.LOAD_TEST_AND_EXAMINATION">-->
      <!--          </v-checkbox>-->
      <!--          <v-checkbox multiple-->
      <!--                      v-model="jobTypeFilter"-->
      <!--                      :label="$t('job_type.EXAM')"-->
      <!--                      :value="JOB_TYPE.EXAMINATION">-->
      <!--          </v-checkbox>-->
      <!--        </v-col>-->
      <!--      </v-row>-->

    </v-container>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import {Client, Site} from "@/store/models";
import {JOB_TYPE} from "@/constants";
import _ from 'lodash';
import ModalSelector from "@/components/ModalSelector";

export default {
  name: 'JobFilter',
  components: {ModalSelector},
  props: {
    shown: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);
    watch(() => props.shown, (newValue, oldValue) => {
      dialogShown.value = newValue;

      if (newValue) {
        let currentFilter = root.$store.getters.jobFilters;

        sortBy.value = currentFilter.sortBy;
        jobStatusFilter.value = currentFilter.jobStatus;
        clientFilter.value = currentFilter.client;
        attnFilter.value = currentFilter.attn;
        regionFilter.value = currentFilter.region;
        districtFilter.value = currentFilter.district;
        siteFilter.value = currentFilter.site;
        jobTypeFilter.value = currentFilter.jobType;
      }
    });

    const cancel = function () {
      emit('cancel');
    };
    const clear = function () {
      emit('change', {
        sortBy: sortBy.value,
        jobStatus: jobStatusFilter.value,
        client: [],
        attn: [],
        region: [],
        district: [],
        site: [],
        jobType: jobTypeFilter.value,
      });
    }
    const applyFilter = function () {
      emit('change', {
        sortBy: sortBy.value,
        jobStatus: jobStatusFilter.value,
        client: clientFilter.value,
        attn: attnFilter.value,
        region: regionFilter.value,
        district: districtFilter.value,
        site: siteFilter.value,
        jobType: jobTypeFilter.value,
      });
    };

    const sortByOptions = ref([
      {text: `${root.$t('equipment.equipment_reg_no')} (Ascending)`, value: 'equipment.equipment_reg_no'},
      {text: `${root.$t('equipment.equipment_reg_no')} (Descending)`, value: '-equipment.equipment_reg_no'},
      {text: `${root.$t('job_list.client')} (Ascending)`, value: 'equipment.site.client.name'},
      {text: `${root.$t('job_list.client')} (Descending)`, value: '-equipment.site.client.name'},
      {text: `${root.$t('job_list.site')} (Ascending)`, value: 'equipment.site.name'},
      {text: `${root.$t('job_list.site')} (Descending)`, value: '-equipment.site.name'},
      {text: `${root.$t('job_list.district')} (Ascending)`, value: 'equipment.site.district'},
      {text: `${root.$t('job_list.district')} (Descending)`, value: '-equipment.site.district'},
      {text: `${root.$t('job_list.region')} (Ascending)`, value: 'equipment.site.region'},
      {text: `${root.$t('job_list.region')} (Descending)`, value: '-equipment.site.region'},
      // {text: `${root.$t('equipment.next_exam')} (Ascending)`, value: 'equipment.next_exam'},
      // {text: `${root.$t('equipment.next_exam')} (Descending)`, value: '-equipment.next_exam'},
      // {text: `${root.$t('equipment.next_load')} (Ascending)`, value: 'equipment.next_load'},
      // {text: `${root.$t('equipment.next_load')} (Descending)`, value: '-equipment.next_load'},
      // {text: `${root.$t('job_list.job_date')} (Ascending)`, value: 'job_date'},
      // {text: `${root.$t('job_list.job_date')} (Descending)`, value: '-job_date'},
    ]);
    const sortBy = ref(null);

    const jobStatusOptions = ref([
      {text: root.$t('job_status.pending'), value: 0},
      // {text: root.$t('job_status.scheduled'), value: 1},
      {text: root.$t('job_status.completed'), value: 2},
      {text: root.$t('job_status.cancelled'), value: -1},
    ]);
    const jobStatusFilter = ref([]);

    const clientOptions = computed(() => {
      let clients = Client.query().orderBy('name').all();

      return clients.map((c) => {
        return {text: c.name, value: c.code}
      })
    });
    const clientFilter = ref([]);

    const attnOptions = computed(() => {
      let sites = Site.query().orderBy('memo_attn').all();

      if (clientFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(clientFilter.value, s.client_id) !== -1)
      }

      const attns = _.uniqBy(sites, 'memo_attn').map((s) => s.memo_attn);

      attnFilter.value = _.intersection(attnFilter.value, attns);

      return attns.map((a) => {
        return {text: a, value: a};
      })
    })
    const attnFilter = ref([]);

    const regionOptions = computed(() => {
      let sites = Site.query().orderBy('district').all();

      if (clientFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(clientFilter.value, s.client_id) !== -1)
      }

      if (attnFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(attnFilter.value, s.memo_attn) !== -1)
      }

      const regions = _.uniqBy(sites, 'region').map((s) => s.region);

      regionFilter.value = _.intersection(regionFilter.value, regions);

      return regions.map((r) => {
        return {
          'HK': {text: root.$t('district.hong_kong'), value: 'HK'},
          'KLN': {text: root.$t('district.kowloon'), value: 'KLN'},
          'NT': {text: root.$t('district.new_territories'), value: 'NT'},
          'ISL': {text: root.$t('district.island'), value: 'ISL'},
        }[r];
      })
    })
    const regionFilter = ref([]);

    const districtOptions = computed(() => {
      let sites = Site.query().orderBy('district').all();

      if (clientFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(clientFilter.value, s.client_id) !== -1)
      }

      if (attnFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(attnFilter.value, s.memo_attn) !== -1)
      }

      if (regionFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(regionFilter.value, s.region) !== -1)
      }

      const districts = _.uniqBy(sites, 'district').map((s) => s.district);

      districtFilter.value = _.intersection(districtFilter.value, districts);

      return districts.map((d) => {
        return {text: d, value: d};
      })
    });
    const districtFilter = ref([]);

    const siteOptions = computed(() => {
      let sites = Site.query().orderBy('name').all();

      if (attnFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(attnFilter.value, s.memo_attn) !== -1)
      }

      if (regionFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(regionFilter.value, s.region) !== -1)
      }

      if (districtFilter.value.length > 0) {
        sites = _.filter(sites, (s) => _.indexOf(districtFilter.value, s.district) !== -1)
      }

      siteFilter.value = _.intersection(siteFilter.value, sites.map((s) => s.id));

      return sites.map((s) => {
        return {text: s.name, value: s.id}
      })
    });
    const siteFilter = ref([]);

    const jobTypeFilter = ref([0, 1]);

    return {
      dialogShown,

      cancel,
      clear,
      applyFilter,

      sortByOptions,
      sortBy,

      jobStatusOptions,
      jobStatusFilter,

      clientOptions,
      clientFilter,

      attnOptions,
      attnFilter,

      regionOptions,
      regionFilter,

      districtOptions,
      districtFilter,

      siteOptions,
      siteFilter,

      jobTypeFilter,
      JOB_TYPE,
    };
  },
}
</script>
