import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"search-view pa-0 fill-height",attrs:{"fluid":""}},[_c(VSnackbar,{attrs:{"color":"rgba(0, 0, 0, 0.5)","timeout":"-1","value":true,"absolute":"","top":"","content-class":"text-center"}},[_vm._v(" Scan QR code on Certificate or enter Registration No. ")]),_c(VContainer,{staticClass:"pa-0"},[_c(VRow,{attrs:{"align-self":"center"}},[_c(VCol,{staticClass:"pa-0",attrs:{"align-self":"center"}},[(!_vm.htmlCameraShown)?_c(VBtn,{staticStyle:{"height":"100% !important"},attrs:{"block":"","text":"","dark":"","x-large":"","ripple":false},on:{"click":_vm.startCodeReader}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c(VIcon,{attrs:{"size":"60px"}},[_vm._v("fas fa-camera")])],1)],1),_c(VRow,[_c(VCol,[_vm._v(" Tap to Scan QR Code ")])],1)],1)],1):_vm._e(),(_vm.htmlCameraShown)?_c('qrcode-stream',{attrs:{"camera":_vm.camera},on:{"decode":_vm.onQrcodeDecode}}):_vm._e()],1)],1)],1),_c(VFooter,{staticClass:"pr-6 py-3",attrs:{"app":"","inset":""}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"outlined":"","prepend-inner-icon":"fas fa-search","label":_vm.$t('search.reg_no'),"hide-details":""},model:{value:(_vm.regNo),callback:function ($$v) {_vm.regNo=$$v},expression:"regNo"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary","disabled":!_vm.regNo},on:{"click":_vm.searchEquipment}},[_vm._v(_vm._s(_vm.$t('search.title'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }