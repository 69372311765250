import _ from 'lodash';
import {ACTION_TYPES} from "@/store/types";
import {Client, Contact, Contract, Equipment, EquipmentType, Job, JobSubmission, LalgFile, Site} from "@/store/models";
import {v4 as uuid} from "uuid";
import {JOB_STATUS, JOB_TYPE, SUBMISSION_TYPE} from "@/constants";
import i18n from "@/plugins/i18n";
import localforage from "localforage";
import {DateTime} from "luxon";

export const lalgModule = {
    state: {
        isOnline: false,
        lastUpdateTimestamp: null,

        showAppBar: true,
        appBarTitle: 'EMSD LALG',
        showAppBarEllipsis: false,

        currentContractNo: null,
        currentSchedulePlanId: null,

        jobLoaded: false,
        jobFilters: {
            sortBy: 'equipment.equipment_reg_no',
            jobStatus: [JOB_STATUS.PENDING],
            client: [],
            attn: [],
            region: [],
            district: [],
            site: [],
            jobType: [JOB_TYPE.LOAD_TEST_AND_EXAMINATION, JOB_TYPE.EXAMINATION],
            showExpired: true,
        },
        filteredJobs: [],

        scheduledJobDates: [],
    },
    mutations: {
        setOnlineStatus(state, isOnline) {
            state.isOnline = isOnline;
        },

        setLastUpdateTimestamp(state, timestamp) {
            state.lastUpdateTimestamp = timestamp;
        },
        clearLastUpdateTimestamp(state) {
            state.lastUpdateTimestamp = null;
        },

        showAppBar(state) {
            state.showAppBar = true;
        },
        hideAppBar(state) {
            state.showAppBar = false;
        },
        setAppBarTitle(state, title) {
            state.appBarTitle = title;
        },

        showAppBarEllipsis(state) {
            state.showAppBarEllipsis = true;
        },
        hideAppBarEllipsis(state) {
            state.showAppBarEllipsis = false;
        },

        setCurrentContractNo(state, contract_no) {
            state.currentContractNo = contract_no;
        },
        setCurrentSchedulePlanId(state, schedule_plan_id) {
            state.currentSchedulePlanId = schedule_plan_id;
        },
        setScheduledJobDates(state, job_dates) {
            state.scheduledJobDates = job_dates;
        },
        setJobLoaded(state, loaded) {
            state.jobLoaded = loaded;
        },
        setJobFilters(state, filters) {
            state.jobFilters = filters;
        },
        filterJobs(state) {
            let filters = state.jobFilters;
            let filteredJobs = Job.query().withAllRecursive().get();
            if (filters.client.length > 0) {
                filteredJobs = _.filter(filteredJobs, (job) => {
                    return filters.client.indexOf(job.equipment.site.client.code) !== -1;
                });
            }

            if (filters.site.length > 0) {
                filteredJobs = _.filter(filteredJobs, (job) => {
                    return filters.site.indexOf(job.equipment.site.id) !== -1
                });
            }

            if (filters.attn.length > 0) {
                filteredJobs = _.filter(filteredJobs, (job) => {
                    return filters.attn.indexOf(job.equipment.site.memo_attn) !== -1
                });
            }

            if (filters.district.length > 0) {
                filteredJobs = _.filter(filteredJobs, (job) => {
                    return filters.district.indexOf(job.equipment.site.district) !== -1;
                });
            }

            if (filters.region.length > 0) {
                filteredJobs = _.filter(filteredJobs, (job) => {
                    return filters.region.indexOf(job.equipment.site.region) !== -1;
                });
            }

            if (filters.jobType.length > 0) {
                filteredJobs = _.filter(filteredJobs, (job) => {
                    return filters.jobType.indexOf(job.job_type) !== -1;
                });
            }

            let expired = _.remove(filteredJobs, (j) => {
                if (j.job_status === JOB_STATUS.COMPLETED) {
                    return false
                }

                // Check if equipment expired
                if (j.type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION && j.equipment && j.equipment.next_load) {
                    if (DateTime.fromISO(j.equipment.next_load) < DateTime.fromJSDate(new Date())) {
                        return true
                    }
                }

                // Check if equipment expired
                if (j.equipment && j.equipment.next_exam) {
                    if (DateTime.fromISO(j.equipment.next_exam) < DateTime.fromJSDate(new Date())) {
                        return true
                    }
                }

                return false;
            });
            expired = _.sortBy(expired, ['equipment.site.name', 'equipment.equipment_reg_no'])
            filteredJobs = _.sortBy(filteredJobs, ['equipment.site.name', 'equipment.equipment_reg_no'])
            state.filteredJobs = _.concat(expired, filteredJobs);
        },
    },
    actions: {
        [ACTION_TYPES.SET_ONLINE_STATUS]: {
            root: true,
            async handler({dispatch, commit}, isOnline) {
                commit('setOnlineStatus', true);
            },
        },

        [ACTION_TYPES.SHOW_APPBAR]: {
            root: true,
            async handler({commit}) {
                commit("showAppBar");
            },
        },
        [ACTION_TYPES.HIDE_APPBAR]: {
            root: true,
            async handler({commit}) {
                commit("hideAppBar")
            },
        },
        [ACTION_TYPES.SET_APPBAR_TITLE]: {
            root: true,
            async handler({commit}, title) {
                commit("setAppBarTitle", title);
            },
        },
        [ACTION_TYPES.SHOW_APPBAR_ELLIPSIS]: {
            root: true,
            async handler({commit}) {
                commit("showAppBarEllipsis");
            },
        },
        [ACTION_TYPES.HIDE_APPBAR_ELLIPSIS]: {
            root: true,
            async handler({commit}) {
                commit("hideAppBarEllipsis");
            },
        },

        // File
        [ACTION_TYPES.SAVE_FILE]: {
            root: true,
            async handler({dispatch, commit}, {base64, filename, coordinates, job_id, job_submission_id}) {
                let file_id = uuid();

                if (window.tnConnector) {
                    await new Promise((resolve, reject) => {
                        window.tnConnector.app.setStorage({
                            key: file_id,
                            value: base64,
                        }, () => {
                            console.log('resolve');
                            resolve();
                        }, (error) => {
                            console.log(error);
                            reject(error);
                        });
                    })
                } else {
                    await localforage.setItem(file_id, base64);
                    // // Save to localStorage
                    // window.localStorage.setItem(file_id, base64);
                }

                // Get MIME
                let arr = base64.split(','),
                    mime = arr[0].match(/:(.*?);/)[1];

                // Create ORM record
                await LalgFile.insert({
                    data: {
                        id: file_id,
                        filename,
                        mime,
                        coordinates,
                        job_id,
                        job_submission_id,
                        cached: true,
                    }
                });
                // let file = result.lalg_files[0];

                // // Mark file as cached
                // console.time('LalgFile.update');
                // await LalgFile.update({
                //     where: file.id,
                //     data: {cached: true},
                // });
                // console.timeEnd('LalgFile.update');

                // // Hydrate related Records
                // console.time('Job.hydrate');
                // if (job_id) {
                //     Job.hydrate({id: job_id});
                // }
                // console.timeEnd('Job.hydrate');
                // console.time('JobSubmission.hydrate');
                // if (job_submission_id) {
                //     JobSubmission.hydrate({id: job_submission_id});
                // }
                // console.timeEnd('JobSubmission.hydrate');

                return base64;
            },
        },
        [ACTION_TYPES.GET_FILE]: {
            root: true,
            async handler({dispatch, commit}, {file_id, job_id, job_submission_id, justChecking = false}) {
                console.log(`GET FILE: ${file_id}`);
                // Check ORM record
                let file = LalgFile.find(file_id);

                if (file) {
                    if (justChecking && file.cached) {
                        return;
                    }

                    // Check if file is cached
                    let cache
                    if (window.tnConnector) {
                        cache = await new Promise((resolve, reject) => {
                            window.tnConnector.app.getStorage({
                                key: file_id,
                            }, ({value}) => {
                                resolve(value);
                            }, (error) => {
                                console.log(error);
                                reject(error);
                            });
                        })
                    } else {
                        // cache = window.localStorage.getItem(file.id);
                        cache = await localforage.getItem(file_id);
                    }

                    if (cache) {
                        await LalgFile.update({
                            where: file.id,
                            data: {
                                cached: true,
                            }
                        });

                        return {base64: cache, filename: file.filename};
                    }
                }

                // Download File if not cached
                const response = await dispatch(ACTION_TYPES.CALL_API, {url: `app/file/${file_id}`, opt: {not_blocking: true, extra: {responseType: 'blob'}}});

                let filename = response.headers.get('content-filename'),
                    mime = response.headers.get('content-type'),
                    coordinates = response.headers.get('x-coordinates');

                // Update ORM record
                await LalgFile.insertOrUpdate({
                    data: {
                        id: file_id,
                        filename,
                        mime,
                        coordinates,
                        cached: true,
                        job_id,
                        job_submission_id,
                    }
                });

                let f = new File([await response.blob()], response.headers.get('content-filename'));
                let base64 = await this._vm.$convertFileToBase64(f);

                if (window.tnConnector) {
                    await new Promise((resolve, reject) => {
                        window.tnConnector.app.setStorage({
                            key: file_id,
                            value: base64,
                        }, () => {
                            resolve();
                        }, (error) => {
                            console.log(error);
                            reject(error);
                        });
                    })
                } else {
                    await localforage.setItem(file_id, base64);
                    // // Save to localStorage
                    // window.localStorage.setItem(file_id, base64);
                }

                return {base64, filename};
            },
        },
        [ACTION_TYPES.CLONE_FILE]: {
            root: true,
            async handler({dispatch, commit}, {file_id, filename, job_id, job_submission_id}) {
                console.log(`CLONE FILE: ${file_id}`);

                // Check if file is already cached
                const {base64} = await dispatch(ACTION_TYPES.GET_FILE, {file_id});

                let file = LalgFile.find(file_id);

                // Create and cache new file
                await LalgFile.insertOrUpdate({
                    data: {
                        id: file_id,
                        filename,
                        mime: file.mime,
                        coordinates: file.coordinates,
                        cached: true,
                        job_id,
                        job_submission_id,
                    }
                });

                if (window.tnConnector) {
                    await new Promise((resolve, reject) => {
                        window.tnConnector.app.setStorage({
                            key: file_id,
                            value: base64,
                        }, () => {
                            resolve();
                        }, (error) => {
                            console.log(error);
                            reject(error);
                        });
                    })
                } else {
                    await localforage.setItem(file_id, base64);
                }

                return {base64, filename};
            },
        },
        [ACTION_TYPES.DELETE_FILE]: {
            root: true,
            async handler({dispatch, commit}, file_id) {
                if (window.tnConnector) {
                    await new Promise((resolve, reject) => {
                        window.tnConnector.app.removeStorage({
                            key: file_id,
                        }, () => {
                            resolve();
                        }, (error) => {
                            console.log(error);
                            reject(error);
                        });
                    })
                } else {
                    await localforage.removeItem(file_id)
                    // // Save to localStorage
                    // window.localStorage.removeItem(file_id);
                }

                await LalgFile.delete(file_id);
            },
        },

        // Contracts
        [ACTION_TYPES.RETRIEVE_CONTRACTS]: {
            root: true,
            async handler({dispatch}) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {url: 'app/contracts/', opt: {not_blocking: true}});
                await Contract.deleteAll();
                await Contract.create({
                    data: response.body.contracts
                });
            },
        },
        [ACTION_TYPES.SET_CURRENT_CONTRACT]: {
            root: true,
            async handler({commit}, {contractNo, schedulePlanId}) {
                await commit('setCurrentContractNo', contractNo)
                await commit('setCurrentSchedulePlanId', schedulePlanId)
            },
        },
        // Jobs
        [ACTION_TYPES.RETRIEVE_JOBS]: {
            root: true,
            async handler({state, dispatch, commit}, {forceReload = false}) {
                if (!state.isOnline) {
                    await dispatch(ACTION_TYPES.SHOW_SNACKBAR, i18n.t('job_list.offline_msg'));
                    return
                }

                if (forceReload || !state.jobLoaded) {
                    commit('clearLastUpdateTimestamp');
                }

                try {
                    const timestamp = Math.floor(new Date().getTime() / 1000);
                    await dispatch(ACTION_TYPES.SET_LOADING_MSG, `Loading Jobs ...`);
                    const response = await dispatch(ACTION_TYPES.CALL_API, {
                        url: 'app/jobs/',
                        params: {
                            contract_id: state.currentContractNo,
                            schedule_plan_id: state.currentSchedulePlanId,
                            timestamp: state.lastUpdateTimestamp,
                        },
                        opt: {not_blocking: true, throw_error: true},
                    });

                    if (forceReload || !state.jobLoaded) {
                        await dispatch(ACTION_TYPES.RESET_JOB_FILTERS);
                        await EquipmentType.deleteAll();
                        await Equipment.deleteAll();
                        await Site.deleteAll();
                        await Client.deleteAll();
                        await Contact.deleteAll();
                        await Job.deleteAll();
                        await JobSubmission.deleteAll();
                        await LalgFile.deleteAll();

                        if (window.tnConnector) {
                            await new Promise((resolve, reject) => {
                                window.tnConnector.app.clearStorage({}, () => {
                                    resolve(true);
                                }, (error) => {
                                    console.log(error);
                                    reject(false);
                                });
                            })
                        } else {
                            await localforage.clear();
                        }
                    }

                    await Equipment.insert({
                        data: response.body.equipments
                    });
                    await Site.insert({
                        data: response.body.sites
                    });
                    await Client.insert({
                        data: response.body.clients
                    });
                    await Contact.insert({
                        data: response.body.contacts
                    });

                    let jobs = response.body.jobs;
                    await Job.insert({
                        data: jobs.map((j) => {
                            return {
                                ...j,
                                job_status: j.job_status === 3 ? 2 : j.job_status,
                            }
                        }),
                    });

                    // await Job.insert({
                    //     data: response.body.expired_jobs
                    // });

                    await JobSubmission.insert({
                        data: response.body.job_submissions
                    })

                    // Delete Submissions of cancelled / completed jobs
                    const pendingJobIds = Job.query().where('job_status', JOB_STATUS.PENDING).all().map((j) => j.id);
                    await JobSubmission.delete((submission) => {
                        return pendingJobIds.indexOf(submission.job_id) === -1 && !submission.created_at
                    });

                    commit('setLastUpdateTimestamp', timestamp);
                    commit('setJobLoaded', true);
                } catch (e) {
                    console.log(e);
                    if (e.status === 400 && e.body.msg === 'invalid_schedule_plan_id') {
                        await dispatch(ACTION_TYPES.SET_LOADING_MSG, null);
                        return true;
                    }
                } finally {
                    await dispatch(ACTION_TYPES.SET_LOADING_MSG, null);
                    // commit('filterJobs');
                }
                //
            },
        },
        [ACTION_TYPES.SCHEDULE_JOBS]: {
            root: true,
            async handler({dispatch, commit}, {job_ids, job_date}) {
                await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'app/schedule_job/',
                    params: {job_ids, job_date},
                });

                await Job.update({
                    where: (job) => job_ids.indexOf(job.id) !== -1,
                    data: {job_date: this._vm.$parseDate(job_date, 'yyyy-MM-dd'), job_status: JOB_STATUS.SCHEDULED},
                })

                // Update Schedule Job Dates
                // Get all unique dates with scheduled jobs
                let scheduledDates = _.uniqBy(Job.all(), (job) => {
                    return job.job_date;
                });
                await commit('setScheduledJobDates', _.without(scheduledDates.map((j) => j.job_date), null));
            },
        },
        [ACTION_TYPES.UPDATE_JOB_STATUS]: {
            root: true,
            async handler({dispatch, commit}, job_id) {
                const job = Job.find(job_id);

                let submittedLoadTest = JobSubmission.query()
                    .where('job_id', job_id)
                    .where('type', SUBMISSION_TYPE.LOAD_TEST)
                    .where('created_at', (value) => {
                        return Boolean(value);
                    }).first();
                let submittedExam = JobSubmission.query()
                    .where('job_id', job_id)
                    .where('type', SUBMISSION_TYPE.EXAM)
                    .where('created_at', (value) => {
                        return Boolean(value);
                    }).first();

                console.log(submittedExam, submittedLoadTest);

                if (job.job_type === JOB_TYPE.EXAMINATION) {
                    if (submittedExam) {
                        await Job.update({
                            where: job_id,
                            data: {
                                job_status: JOB_STATUS.COMPLETED,
                            }
                        })
                    }
                } else if (submittedLoadTest && submittedExam) {
                    await Job.update({
                        where: job_id,
                        data: {
                            job_status: JOB_STATUS.COMPLETED,
                        }
                    })
                }
            },
        },
        [ACTION_TYPES.SET_JOB_FILTERS]: {
            root: true,
            async handler({dispatch, commit}, filters) {
                commit('setJobFilters', filters);
                // commit('filterJobs');
            },
        },
        [ACTION_TYPES.RESET_JOB_FILTERS]: {
            root: true,
            async handler({commit}) {
                commit('setJobFilters', {
                    sortBy: 'equipment.equipment_reg_no',
                    jobStatus: [
                        JOB_STATUS.PENDING,
                    ],
                    client: [],
                    attn: [],
                    region: [],
                    district: [],
                    site: [],
                    jobType: [JOB_TYPE.LOAD_TEST_AND_EXAMINATION, JOB_TYPE.EXAMINATION],
                    showExpired: true,
                });
                // commit('filterJobs');
            },
        },

        // Forms
        [ACTION_TYPES.SUBMIT_FORM]: {
            root: true,
            async handler({dispatch, commit, state}, {jobSubmissionId, rpe}) {
                const submission = JobSubmission.query().withAllRecursive().where('id', jobSubmissionId).first();

                // console.log(submission)
                // Upload Photos
                let fIds = {};
                let coordinates = {};

                submission.photoObjs.map((p) => {
                    fIds[p.filename] = p.id;
                    coordinates[p.filename] = p.coordinates;
                });

                // Get Photo Data and convert to Base64
                let promises = submission.photoObjs.map((p) => {
                    return dispatch(ACTION_TYPES.GET_FILE, {
                        file_id: p.id,
                        job_submission_id: jobSubmissionId,
                    });
                });
                const result = await Promise.all(promises);
                let jobPhotos = result.map((i) => {
                    return this._vm.$convertBase64toFile(i.base64, i.filename);
                });

                try {
                    await dispatch(
                        ACTION_TYPES.UPLOAD_FILE,
                        {
                            files: jobPhotos,
                            file_ids: JSON.stringify(fIds),
                            coordinates: JSON.stringify(coordinates),
                        }
                    );
                } catch (e) {
                    console.log(e);
                    throw e;
                }

                const response = await dispatch(ACTION_TYPES.CALL_API, {
                    url: 'app/submit/',
                    params: {
                        submission_id: submission.id,
                        job_id: submission.job_id,
                        job_type: {
                            'LOAD_TEST': 'LOAD_TEST',
                            'EXAM': 'EXAM',
                        }[submission.type],
                        result: submission.result,
                        fail_reason: submission.fail_reason,
                        checklist_result: submission.checklist_result,
                        rpe_name: rpe.name,
                        rpe_no: rpe.rpe_no,
                        rpe_qualification: rpe.rpe_qualification,
                        rpe_discipline: rpe.rpe_discipline,
                        photos: submission.photoObjs.map((p) => p.id),

                        jib_length: submission.jib_length,
                        radius: submission.radius,
                        safe_working_load: submission.safe_working_load,
                        test_load: submission.test_load,
                        form_3_7: submission.form_3_7,
                        form_3_9: submission.form_3_9,
                    },
                });
                let submitted = response.body.submissions;

                await JobSubmission.insert({
                    data: submitted.map((s) => {
                        return {
                            ...submitted,
                            photo_ids: s.photos,
                        }
                    }),
                });

                submitted.map(async (s) => {
                    let key = {
                        'LOAD_TEST': 'load_test_submission',
                        'EXAM': 'exam_submission',
                    }[s.type];
                    await Job.update({
                        where: s.job_id,
                        data: {
                            [key]: s.id,
                            job_status: JOB_STATUS.COMPLETED,
                        }
                    })
                })

                // Delete local copy
                await JobSubmission.delete(jobSubmissionId)
                // commit('filterJobs');

                return true;
            },
        },
    },
    getters: {
        isOnline(state) {
            return state.isOnline;
        },
        showAppBar(state) {
            return state.showAppBar;
        },
        appBarTitle(state) {
            return state.appBarTitle;
        },
        showAppBarEllipsis(state) {
            return state.showAppBarEllipsis;
        },
        currentContractNo(state) {
            return state.currentContractNo;
        },
        currentSchedulePlanId(state) {
            return state.currentSchedulePlanId;
        },
        scheduledJobDates(state) {
            return state.scheduledJobDates;
        },
        jobFilters(state) {
            return state.jobFilters;
        },
        filteredJobs(state) {
            return state.filteredJobs;
        },
    },
}
