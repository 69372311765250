<template>
  <v-card>
    <v-card-title></v-card-title>
    <v-card-text class="text-center">
      {{ $t('job_form.submit_now_msg') }}
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col>
          <v-btn block
                 @click="$emit('submit', undefined)">
            {{ $t('cancel') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn block
                 color="primary"
                 @click="$emit('submit', false)">
            {{ $t('job_form.submit_later') }}
          </v-btn>
          <v-btn block
                 class="mt-4"
                 color="primary"
                 @click="$emit('submit', true)">
            {{ $t('job_form.submit_now') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'FormSubmitDialog',
}
</script>