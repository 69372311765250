import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"disable-route-watcher":"","color":"#F4F5F7","app":"","width":"320px","id":"appDrawer"},model:{value:(_vm.drawerShown),callback:function ($$v) {_vm.drawerShown=$$v},expression:"drawerShown"}},[_c(VRow,{staticClass:"pr-4"},[_c(VCol,{staticClass:"text-right"},[_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){_vm.drawerShown = false}}},[_vm._v(" fas fa-times ")])],1)],1),_c(VDivider),_c(VList,_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.onMenuItemClick(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-left"},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }