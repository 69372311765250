import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c(VCard,{staticClass:"pa-4"},[_c(VCardTitle),_c(VCardText,{staticClass:"text-center"},[_c('vc-date-picker',{attrs:{"color":"red","is-inline":"","is-expanded":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c(VCardActions,[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1),_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary","disabled":!_vm.selectedDate},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }