<template>
  <v-container class="pa-0 job-list">
    <v-row v-if="filteredJobs.length === 0"
           class="flex-grow-1">
      <v-col align-self="center">
        NO JOBS
      </v-col>
    </v-row>

    <v-virtual-scroll :items="filteredJobs"
                      :item-height="120"
                      :bench="10"
                      class="job-list-virtual-scroll"
                      v-if="filteredJobs.length !== 0">
      <template v-slot="{item: job}">
        <v-container fluid
                     :class="['job-list-cell', {'expired': isJobExpired(job)}, {'not-submitted': isJobNotSubmitted(job)}, 'pa-0']">
          <v-row :key="job.id"
                 @click="onJobClicked(job)"
                 no-gutters>
            <!--            <v-col :cols="1" align-self="center" v-if="selectMode">-->
            <!--              <div>-->
            <!--                <v-row no-gutters>-->
            <!--                  <v-col>-->
            <!--                    <v-checkbox v-if="shouldShowSelectCheckbox(job)"-->
            <!--                                v-model="selectedJobIds"-->
            <!--                                multiple-->
            <!--                                color="primary"-->
            <!--                                :value="job.id"-->
            <!--                                @click="onJobSelected"-->
            <!--                                hide-details></v-checkbox>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </div>-->
            <!--            </v-col>-->
            <v-col :cols="3" class="job-list-row-left-col" align-self="center">
              <div>
                <v-row no-gutters>
                  <v-col cols="12"><h5>{{ job.equipment.equipment_reg_no }}</h5></v-col>
                  <!--                </v-row>-->
                  <!--                <v-row no-gutters>-->
                  <!--                  <v-col><h5>{{ job.equipment.site.client.code }}</h5></v-col>-->
                  <!--                </v-row>-->
                  <!--                <v-row>-->
                  <v-col cols="12">
                    <v-chip :color="getJobChipColor(job)">
                      <v-icon>{{ getJobChipIcon(job) }}</v-icon>
                    </v-chip>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col :cols="selectMode? 8 : 9" class="text-left" align-self="center">
              <v-row no-gutters>
                <v-col>{{ job.equipment.site.name }}</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>{{ `${job.equipment.site.district}, ${job.equipment.site.region}` }}</v-col>
              </v-row>
              <v-row no-gutters
                     v-if="job.equipment.room">
                <v-col>{{ job.equipment.room }}</v-col>
              </v-row>
              <!--              <v-row>-->
              <!--                <v-col class="pb-0">-->
              <!--                  <v-chip :color="getJobExamChipColor(job)">-->
              <!--                    <v-icon class="mr-2">{{ getJobExamChipIcon(job) }}</v-icon>-->
              <!--                    Exam: {{ getJobExamLabel(job) }}-->
              <!--                  </v-chip>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
              <!--              <v-row v-if="job.job_type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION">-->
              <!--                <v-col class="pb-0">-->
              <!--                  <v-chip :color="getJobLoadTestChipColor(job)">-->
              <!--                    <v-icon class="mr-2">{{ getJobLoadTestChipIcon(job) }}</v-icon>-->
              <!--                    Load Test: {{ getJobLoadTestLabel(job) }}-->
              <!--                  </v-chip>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
            </v-col>
          </v-row>
          <!--          <v-row>-->
          <!--            <v-col class="pb-0">-->
          <!--              <v-chip small-->
          <!--                      :color="getJobExamChipColor(job)">-->
          <!--                <v-icon class="mr-2">{{ getJobExamChipIcon(job) }}</v-icon>-->
          <!--                Exam: {{ getJobExamLabel(job) }}-->
          <!--              </v-chip>-->
          <!--            </v-col>-->
          <!--            <v-col class="pb-0">-->
          <!--              <v-chip small-->
          <!--                      :color="getJobLoadTestChipColor(job)"-->
          <!--                      v-if="job.job_type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION">-->
          <!--                <v-icon class="mr-2">{{ getJobLoadTestChipIcon(job) }}</v-icon>-->
          <!--                Load Test: {{ getJobLoadTestLabel(job) }}-->
          <!--              </v-chip>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </v-container>
      </template>
    </v-virtual-scroll>

    <v-row class="flex-grow-0 job-list-filter-row"
           v-if="!hideStatus">
      <v-col>
        <v-chip color="#ffe660"
                :outlined="!isJobStatusActive(0)"
                @click="toggleJobStatusFilter(0)">
          <v-icon class="mr-2">fas fa-user-clock</v-icon>
          {{ jobStatusCounts[0] || '0' }}
        </v-chip>
      </v-col>
      <v-col>
        <v-chip color="#ffe660"
                :outlined="!showDraft"
                @click="toggleShowDraft">
          <v-icon class="mr-2">far fa-file</v-icon>
          {{ drafts.length }}
        </v-chip>
      </v-col>
      <v-col>
        <v-chip color="#91d7ff"
                :outlined="!isJobStatusActive(2)"
                @click="toggleJobStatusFilter(2)">
          <v-icon class="mr-2">fas fa-check</v-icon>
          {{ jobStatusCounts[2] || '0' }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 job-list-filter-row"
           v-if="!hideStatus">
      <v-col>
        <v-chip color="red"
                :outlined="!showExpiredJobs"
                @click="toggleShowExpiredJobs">
          <v-icon class="mr-2">fas fa-exclamation-triangle</v-icon>
          {{ expiredJobs.length }}
        </v-chip>
      </v-col>
      <v-col>
        <v-chip color="#6CDC87"
                :outlined="!showUnsubmittedJobs"
                @click="toggleShowUnsubmittedJobs">
          <v-icon class="mr-2">fas fa-history</v-icon>
          {{ unsubmittedJobs.length }}
        </v-chip>
      </v-col>

      <!--      <v-col>-->
      <!--        <v-chip :color="isJobStatusActive(-1) ? 'red' : '#666'"-->
      <!--                @click="toggleJobStatusFilter(-1)">-->
      <!--          <v-icon class="mr-2">fas fa-ban</v-icon>-->
      <!--          {{ jobStatusCounts[3] || '0' }}-->
      <!--        </v-chip>-->
      <!--      </v-col>-->
      <v-col>
        <v-chip color="primary"
                :outlined="currentFilterCount === 0"
                @click="showFilter"
                v-if="!hideFilter">
          <v-icon class="mr-2">fas fa-filter</v-icon>
          ({{ currentFilterCount }})
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {DateTime} from 'luxon';
import _ from 'lodash';
import {ref, watch, computed} from '@vue/composition-api'
import {EVENT_SHOW_FILTER, JOB_STATUS, JOB_TYPE, SUBMISSION_RESULT} from "@/constants";
import {ACTION_TYPES} from "@/store/types";
import {JobSubmission} from "@/store/models";

export default {
  name: 'JobList',
  props: {
    value: Array,
    jobs: Array,
    selectMode: Boolean,
    hideStatus: Boolean,
    hideFilter: Boolean,
  },
  setup(props, {root, emit}) {
    const jobIds = computed(() => {
      return props.jobs.map((j) => j.id);
    })
    const filteredJobs = computed(() => {
      let jobStatusFilters = currentFilter.value.jobStatus;
      let jobs = props.jobs;

      if (showExpiredJobs.value) {
        jobs = _.filter(jobs, (job) => isJobExpired(job));
      } else {
        if (showDraft.value) {
          const draftIds = drafts.value.map((s) => s.job_id);

          jobs = _.filter(jobs, (job) => draftIds.indexOf(job.id) !== -1);
        } else if (showUnsubmittedJobs.value) {
          const unsubmittedJobIds = unsubmittedJobs.value.map((s) => s.job_id)
          jobs = _.filter(jobs, (job) => unsubmittedJobIds.indexOf(job.id) !== -1);
        } else if (jobStatusFilters.length > 0) {
          jobs = _.filter(jobs, (job) => {
            return jobStatusFilters.indexOf(job.job_status) !== -1
          });
        }
      }

      return jobs;
    })
    const jobStatusCounts = computed(() => {
      return _.countBy(props.jobs, 'job_status');
    })

    const selectedJobIds = ref([]);
    const shouldShowSelectCheckbox = function (job) {
      return job.job_status === JOB_STATUS.PENDING;
    };
    const onJobSelected = function () {
      emit('input', selectedJobIds.value);
    };

    watch(() => props.value, (newValue, oldValue) => {
      selectedJobIds.value = newValue;
    }, {immediate: true});

    const isJobExpired = function (job) {
      if (job.job_status === JOB_STATUS.COMPLETED) return false;

      if (job.equipment) {
        if (DateTime.fromISO(job.equipment.next_load) < DateTime.fromJSDate(new Date()) || DateTime.fromISO(job.equipment.next_exam) < DateTime.fromJSDate(new Date())) {
          return true
        }
      }

      return false;
    };
    const isJobNotSubmitted = function (job) {
      return _.some(job.submissions, (s) => s.ready_to_submit)
    };
    const getJobChipColor = function (job) {
      if (isJobExpired(job)) {
        return 'red';
      }

      return {
        [JOB_STATUS.PENDING]: '#ffe660',
        [JOB_STATUS.COMPLETED]: '#91d7ff',
        [JOB_STATUS.CANCELLED]: 'red',
      }[job.job_status];
    };
    const getJobChipIcon = function (job) {
      if (isJobExpired(job)) {
        return 'fas fa-exclamation-triangle';
      }

      return {
        [JOB_STATUS.PENDING]: 'fas fa-user-clock',
        [JOB_STATUS.COMPLETED]: 'fas fa-check',
        [JOB_STATUS.CANCELLED]: 'fas fa-ban',
      }[job.job_status];
    };
    const getJobStatus = function (job) {
      return {
        [JOB_STATUS.PENDING]: root.$t('job_status.pending'),
        [JOB_STATUS.COMPLETED]: root.$t('job_status.completed'),
        [JOB_STATUS.CANCELLED]: root.$t('job_status.cancelled'),
      }[job.job_status];
    };

    const getJobExamChipColor = function (job) {
      if (job.last_exam_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: '#91d7ff',
          [SUBMISSION_RESULT.FAIL]: 'red',
          [SUBMISSION_RESULT.MISSING]: '#e5d9fe',
          [SUBMISSION_RESULT.NOT_READY]: '#e5d9fe',
          [SUBMISSION_RESULT.UNDER_REPAIR]: '#e5d9fe',
        }[job.last_exam_submission.result];
      } else {
        return '#ffe660'
      }
    }
    const getJobExamChipIcon = function (job) {
      if (job.last_exam_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: 'fas fa-thumbs-up',
          [SUBMISSION_RESULT.FAIL]: 'fas fa-ban',
          [SUBMISSION_RESULT.MISSING]: 'fas fa-question',
          [SUBMISSION_RESULT.NOT_READY]: 'fas fa-question',
          [SUBMISSION_RESULT.UNDER_REPAIR]: 'fas fa-hammer',
        }[job.last_exam_submission.result];
      } else {
        return 'fas fa-clock'
      }
    }
    const getJobExamLabel = function (job) {
      if (job.last_exam_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: root.$t('job_status.pass'),
          [SUBMISSION_RESULT.FAIL]: root.$t('job_status.fail'),
          [SUBMISSION_RESULT.MISSING]: root.$t('job_status.missing'),
          [SUBMISSION_RESULT.NOT_READY]: root.$t('job_status.not_ready'),
          [SUBMISSION_RESULT.UNDER_REPAIR]: root.$t('job_status.under_repair'),
        }[job.last_exam_submission.result];
      } else {
        // return root.$t('job_status.pending')
        return job.equipment.next_exam;
      }
    }

    const getJobLoadTestChipColor = function (job) {
      if (job.last_load_test_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: '#91d7ff',
          [SUBMISSION_RESULT.FAIL]: 'red',
          [SUBMISSION_RESULT.MISSING]: '#e5d9fe',
          [SUBMISSION_RESULT.NOT_READY]: '#e5d9fe',
          [SUBMISSION_RESULT.UNDER_REPAIR]: '#e5d9fe',
        }[job.last_load_test_submission.result];
      } else {
        return '#ffe660'
      }
    }
    const getJobLoadTestChipIcon = function (job) {
      if (job.last_load_test_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: 'fas fa-thumbs-up',
          [SUBMISSION_RESULT.FAIL]: 'fas fa-ban',
          [SUBMISSION_RESULT.MISSING]: 'fas fa-question',
          [SUBMISSION_RESULT.NOT_READY]: 'fas fa-question',
          [SUBMISSION_RESULT.UNDER_REPAIR]: 'fas fa-hammer',
        }[job.last_load_test_submission.result];
      } else {
        return 'fas fa-clock'
      }
    }
    const getJobLoadTestLabel = function (job) {
      if (job.last_load_test_submission) {
        return {
          [SUBMISSION_RESULT.PASS]: root.$t('job_status.pass'),
          [SUBMISSION_RESULT.FAIL]: root.$t('job_status.fail'),
          [SUBMISSION_RESULT.MISSING]: root.$t('job_status.missing'),
          [SUBMISSION_RESULT.NOT_READY]: root.$t('job_status.not_ready'),
          [SUBMISSION_RESULT.UNDER_REPAIR]: root.$t('job_status.under_repair'),
        }[job.last_load_test_submission.result];
      } else {
        // return root.$t('job_status.pending')
        return job.equipment.next_load;
      }
    }

    const onJobClicked = function (job) {
      emit('click', job);
    };

    // Job Status
    const currentFilter = computed(() => root.$store.getters.jobFilters);
    const currentFilterCount = computed(() => {
      return _.filter(['site', 'client', 'district', 'region'], (key) => {
        return currentFilter.value[key].length > 0;
      }).length
    })
    const isJobStatusActive = function (status) {
      return !showUnsubmittedJobs.value && !showDraft.value && !showExpiredJobs.value && currentFilter.value.jobStatus.indexOf(status) !== -1;
    }
    const toggleJobStatusFilter = function (status) {
      if (showDraft.value) {
        showDraft.value = false;
      }
      if (showExpiredJobs.value) {
        root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
          ...currentFilter.value,
          showExpired: false,
        });
      }
      if (showUnsubmittedJobs.value) {
        showUnsubmittedJobs.value = false;
      }

      root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
        ...currentFilter.value,
        jobStatus: [status],
      });
    };
    const showFilter = function () {
      root.$eventHub.$emit(EVENT_SHOW_FILTER);
    }

    //
    const showDraft = ref(false);
    const toggleShowDraft = function () {
      if (showExpiredJobs.value) {
        root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
          ...currentFilter.value,
          showExpired: false,
        });
      }
      if (showUnsubmittedJobs.value) {
        showUnsubmittedJobs.value = false;
      }
      showDraft.value = true;
    }
    const drafts = computed(() => {
      return _.uniqBy(JobSubmission.query()
          .where('job_id', (job_id) => jobIds.value.indexOf(job_id) !== -1)
          .where('result', (value) => !!value)
          .where('created_at', (value) => !value)
          .where('ready_to_submit', false).get(), 'job_id');
    })

    //
    const showExpiredJobs = computed(() => root.$store.getters.jobFilters.showExpired);
    const toggleShowExpiredJobs = function () {
      root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
        ...currentFilter.value,
        showExpired: true,
      });

      if (showDraft.value) {
        showDraft.value = false;
      }
      if (showUnsubmittedJobs.value) {
        showUnsubmittedJobs.value = false;
      }
    }
    const expiredJobs = computed(() => {
      return _.filter(props.jobs, isJobExpired);
    })

    //
    const unsubmittedJobs = computed(() => {
      return _.uniqBy(JobSubmission.query().where('ready_to_submit', true).get(), 'job_id');
    });
    const showUnsubmittedJobs = ref(false);
    const toggleShowUnsubmittedJobs = function () {
      if (showDraft.value) {
        showDraft.value = false;
      }
      if (showExpiredJobs.value) {
        root.$store.dispatch(ACTION_TYPES.SET_JOB_FILTERS, {
          ...currentFilter.value,
          showExpired: false,
        });
      }
      showUnsubmittedJobs.value = true;
    }

    const activeFilterCount = computed(() => {
      return _.filter([
        showDraft.value,
        showExpiredJobs.value,
        showUnsubmittedJobs.value,
      ], Boolean).length + currentFilter.value.jobStatus.length;
    })

    return {
      JOB_TYPE,

      filteredJobs,
      jobStatusCounts,

      selectedJobIds,
      shouldShowSelectCheckbox,
      onJobSelected,

      isJobExpired,
      isJobNotSubmitted,

      getJobChipColor,
      getJobChipIcon,
      getJobStatus,

      getJobExamChipColor,
      getJobExamChipIcon,
      getJobExamLabel,

      getJobLoadTestChipColor,
      getJobLoadTestChipIcon,
      getJobLoadTestLabel,

      onJobClicked,

      currentFilter,
      currentFilterCount,
      isJobStatusActive,
      toggleJobStatusFilter,
      showFilter,

      showDraft,
      toggleShowDraft,
      drafts,

      showExpiredJobs,
      toggleShowExpiredJobs,
      expiredJobs,

      showUnsubmittedJobs,
      toggleShowUnsubmittedJobs,
      unsubmittedJobs,

      activeFilterCount,
    }
  }
}
</script>

<style lang="less">
.job-list {
  //height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  flex: 1 1 0;

  .job-list-virtual-scroll {
    flex: 1 1 0;

    .job-list-cell {
      display: flex;
      height: 120px;
      border-bottom: solid 1px lightgray;

      &.expired {
        background-color: rgba(255, 0, 0, 0.5);
      }

      &.not-submitted {
        background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, rgba(255, 255, 255, 0.5) 10px, rgba(255, 255, 255, 0.5) 20px);

        &.expired {
          background: repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2) 10px, rgba(255, 0, 0, 0.5) 10px, rgba(255, 0, 0, 0.5) 40px);
        }
      }

      .v-chip {
        .v-icon {
          font-size: 18px;
        }
      }
    }
  }

  .job-list-filter-row {
    padding: 0 8px;
    justify-content: space-around;

    .col {
      padding: 8px;
    }

    .v-chip {
      display: flex;
      flex: 0 0 auto;

      .v-chip__content {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
</style>
