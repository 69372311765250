<template>
  <v-container class="job-details pa-0" fluid v-if="job">
    <v-app-bar color="primary"
               dark
               flat>
      <v-btn icon
             @click="back">
        <v-icon>fas fa-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ job.equipment.equipment_reg_no }}</v-toolbar-title>
    </v-app-bar>
    <OnlineStatusBanner></OnlineStatusBanner>
    <v-banner single-line
              transition="slide-y-transition"
              :color="jobStatusColor"
              :value="Boolean(job)"
              class="text-left job-details-status-banner">
      <v-icon class="mr-4">{{ jobStatusIcon }}</v-icon>
      {{ jobStatusLabel }}
    </v-banner>

    <v-tabs grow>
      <v-tab>{{ $t('job_detail.equipment') }}</v-tab>
      <v-tab-item class="job-detail-equipment-panel">
        <DataIterator :cols="6"
                      :value="job"
                      :fields="equipmentFields">
<!--          <template #exam_form>-->
<!--            <v-container fluid-->
<!--                         class="pa-0"-->
<!--                         v-if="lastExamCert">-->
<!--              <v-row no-gutters>-->
<!--                <v-col>{{ lastExamCert.type }}</v-col>-->
<!--              </v-row>-->
<!--              <v-row no-gutters>-->
<!--                <v-col>{{ $parseDate(lastExamCert.created_at, 'yyyy-MM-dd') }}</v-col>-->
<!--              </v-row>-->
<!--              <v-row no-gutters>-->
<!--                <v-col>-->
<!--                  <v-btn large color="primary"-->
<!--                         @click="$showPdfByUrl(`api/app/file/${lastExamCert.signed}`)">-->
<!--                    <v-icon class="mr-4">fas fa-file-pdf-->
<!--                    </v-icon>-->
<!--                    {{ $t('view') }}-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-container>-->
<!--            <v-row v-else>-->
<!--              <v-col>N/A</v-col>-->
<!--            </v-row>-->
<!--          </template>-->
<!--          <template #load_test_form>-->
<!--            <v-container fluid-->
<!--                         class="pa-0"-->
<!--                         v-if="lastLoadTestCert">-->
<!--              <v-row no-gutters>-->
<!--                <v-col>{{ lastLoadTestCert.type }}</v-col>-->
<!--              </v-row>-->
<!--              <v-row no-gutters>-->
<!--                <v-col>{{ $parseDate(lastLoadTestCert.created_at, 'yyyy-MM-dd') }}</v-col>-->
<!--              </v-row>-->
<!--              <v-row no-gutters>-->
<!--                <v-col>-->
<!--                  <v-btn large color="primary"-->
<!--                         @click="$showPdfByUrl(`api/app/file/${lastLoadTestCert.signed}`)">-->
<!--                    <v-icon class="mr-4">fas fa-file-pdf</v-icon>-->
<!--                    {{ $t('view') }}-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-container>-->
<!--            <v-row v-else>-->
<!--              <v-col>N/A</v-col>-->
<!--            </v-row>-->
<!--          </template>-->
        </DataIterator>
      </v-tab-item>
      <v-tab>{{ $t('job_detail.venue') }}</v-tab>
      <v-tab-item class="job-detail-site-panel">
        <DataIterator :value="job.equipment.site"
                      :fields="siteFields">
          <template #address_detail>
            <label>
              {{ job.equipment.address_detail }}
            </label>
          </template>
          <template #coordinates>
            <v-btn color="primary"
                   :disabled="!job.equipment.site.coordinates"
                   @click="coordinatePickerShown = true">
              <v-icon class="mr-4">fas fa-map-marker-alt</v-icon>
              {{ $t('job_form.change_coordinates') }}
            </v-btn>
          </template>
        </DataIterator>
      </v-tab-item>
      <CoordinatePicker :value="job.equipment.site.coordinates"
                        :shown="coordinatePickerShown"
                        @cancel="coordinatePickerShown = false"
                        @confirm="coordinatePickerShown = false"
                        view-only></CoordinatePicker>
      <v-tab>{{ $t('job_detail.past_exams') }}</v-tab>
      <v-tab-item class="job-detail-past-exam-panel text-left">
        <FormGroup :title="`Load Test (${$parseDate(lastLoadTestSubmission.created_at, 'yyyy-MM-dd')})`"
                   v-if="lastLoadTestSubmission">
          <PhotoCarousel :value="lastLoadTestPhotos"
                         hide-delete-btn></PhotoCarousel>
        </FormGroup>
        <FormGroup :title="`Examination (${$parseDate(lastExamSubmission.created_at, 'yyyy-MM-dd')})`"
                   v-if="lastExamSubmission">
          <PhotoCarousel :value="lastExamPhotos"
                         hide-delete-btn></PhotoCarousel>
        </FormGroup>
      </v-tab-item>
      <!--      <v-tab>{{ $t('job_detail.contact') }}</v-tab>-->
      <!--      <v-tab-item class="job-detail-contact-panel">-->
      <!--        <v-expansion-panels accordion>-->
      <!--          <v-expansion-panel v-for="contact in contacts"-->
      <!--                             :key="contact.id">-->
      <!--            <v-expansion-panel-header ripple>{{ contact.name }} {{ contact.post ? `(${contact.post})` : '' }}</v-expansion-panel-header>-->
      <!--            <v-expansion-panel-content>-->
      <!--              <DataIterator :value="contact"-->
      <!--                            :fields="contactFields"></DataIterator>-->
      <!--            </v-expansion-panel-content>-->
      <!--          </v-expansion-panel>-->
      <!--        </v-expansion-panels>-->
      <!--      </v-tab-item>-->
    </v-tabs>

    <v-footer app
              inset
              class="pr-6 py-3">
      <v-row>
        <!--        <v-col>-->
        <!--          <v-btn block-->
        <!--                 color="primary"-->
        <!--                 :disabled="job.job_type === JOB_TYPE.EXAMINATION"-->
        <!--                 @click="resumeOrNewForm(SUBMISSION_TYPE.LOAD_TEST)">-->
        <!--            {{ $t('job_detail.load_test') }}-->
        <!--            <v-icon class="ml-4" v-if="job.job_type !== JOB_TYPE.EXAMINATION">{{ loadTestStartButtonIcon }}</v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-col>-->
        <!--        <v-col>-->
        <!--          <v-btn block-->
        <!--                 color="primary"-->
        <!--                 @click="resumeOrNewForm(SUBMISSION_TYPE.EXAM)">-->
        <!--            {{ $t('job_detail.exam') }}-->
        <!--            <v-icon class="ml-4">{{ examStartButtonIcon }}</v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-col>-->
        <v-col>
          <v-btn block
                 color="primary"
                 @click="resumeOrNewForm()">
            {{ job.job_type === JOB_TYPE.EXAMINATION ? $t('job_detail.exam') : $t('job_detail.load_test_and_exam') }}
            <v-icon class="ml-4">{{ startButtonIcon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, watch} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";
import {Contact, Job, JobSubmission} from '@/store/models';
import {DateTime} from "luxon";
import DataIterator from "@/components/DataIterator";
import OnlineStatusBanner from "@/components/OnlineStatusBanner";
import {v4 as uuid} from "uuid";
import {JOB_STATUS, JOB_TYPE, SUBMISSION_RESULT, SUBMISSION_TYPE} from "@/constants";
import DatePickerModal from "@/components/DatePickerModal";
import CoordinatePicker from "@/components/CoordinatePicker";
import FormGroup from "@/components/FormGroup";
import PhotoCarousel from "@/components/PhotoCarousel";
import JobForm from "@/views/JobForm";

export default {
  name: 'JobDetails',
  props: {
    jobId: String,
  },
  components: {PhotoCarousel, FormGroup, CoordinatePicker, DatePickerModal, OnlineStatusBanner, DataIterator},
  setup(props, {root, emit}) {
    // onMounted(async () => {
    //   await root.$store.dispatch(ACTION_TYPES.HIDE_APPBAR);
    // });
    // onUnmounted(async () => {
    //   await root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR);
    // });

    const back = function () {
      emit('submit');
    };

    const jobId = ref(props.jobId);
    const job = computed(() => {
      return Job.query().withAllRecursive().where('id', jobId.value).first();
    });
    const rpe = computed(() => root.$store.getters.rpe)

    // Status Bar
    const jobStatusColor = computed(() => {
      return {
        0: '#ffe660',
        1: '#e5d9fe',
        2: '#91d7ff',
        '-1': 'red',
      }[job.value.job_status];
    });
    const jobStatusIcon = computed(() => {
      return {
        0: 'fas fa-clock',
        1: 'fas fa-calendar-alt',
        2: 'fas fa-thumbs-up',
        '-1': 'fas fa-ban',
      }[job.value.job_status];
    });
    const jobStatusLabel = computed(() => {
      return {
        0: root.$t('job_status.pending'),
        1: `${root.$t('job_status.scheduled')} (${DateTime.fromISO(job.value.job_date).toFormat('yyyy-MM-dd')})`,
        2: root.$t('job_status.completed'),
        '-1': root.$t('job_status.cancelled'),
      }[job.value.job_status];
    });

    // Equipment
    const equipmentFields = ref([
      {label: root.$t('equipment.client_code'), value: 'equipment.site.client.code'},
      {label: root.$t('equipment.client_name'), value: 'equipment.site.client.name'},
      {label: root.$t('equipment.equipment_reg_no'), value: 'equipment.equipment_reg_no'},
      {label: root.$t('equipment.equipment_code'), value: 'equipment.equipment_code'},
      {label: root.$t('equipment.description'), value: 'equipment.description', cols: 12},
      {label: root.$t('equipment.next_exam'), value: 'equipment.next_exam'},
      {label: root.$t('equipment.next_load'), value: 'equipment.next_load'},
      {label: root.$t('equipment.exam_form'), value: 'equipment.examination_form'},
      {label: root.$t('equipment.load_test_form'), value: 'equipment.load_test_form'},
      {label: root.$t('equipment.safe_working_load'), value: 'equipment.safe_working_load'},
      {label: root.$t('equipment.test_load'), value: 'equipment.test_load'},
      {label: root.$t('equipment.maker'), value: 'equipment.maker'},
      {label: root.$t('equipment.model'), value: 'equipment.model'},
      {label: root.$t('equipment.serial_no'), value: 'equipment.serial_no'},
      {label: root.$t('equipment.install_date'), value: 'equipment.install_date'},
      {label: root.$t('equipment.remarks'), value: 'equipment.remarks', textarea: false, cols: 12},
    ]);
    const equipment = computed(() => {
      if (job.value) {
        return job.value.equipment;
      }
    })

    // Certificates
    const lastExamCert = ref(null);
    const lastLoadTestCert = ref(null);

    // Site
    const siteFields = ref([
      {label: root.$t('site.client'), value: 'client.name'},
      {label: root.$t('site.client_code'), value: 'client.code'},
      {label: root.$t('equipment.address_detail'), value: 'address_detail'},
      {label: root.$t('site.title'), value: 'name'},
      {label: root.$t('site.address'), value: 'address', textarea: true},
      {label: root.$t('site.district'), value: 'district'},
      {label: root.$t('site.region'), value: 'region'},
      {label: root.$t('site.contact_name'), value: 'contact_name'},
      {label: root.$t('site.contact_no'), value: 'contact_no'},
      {label: root.$t('site.coordinates'), value: 'coordinates'},
    ]);
    const coordinatePickerShown = ref(false);

    // Contacts
    const contacts = computed(() => {
      return Contact.query().where('site_id', job.value.equipment.site_id).get();
    })
    const contactFields = ref([
      {label: root.$t('contact.name'), value: 'name'},
      {label: root.$t('contact.post'), value: 'post'},
      {label: root.$t('contact.phone'), value: 'phone'},
      {label: root.$t('contact.email'), value: 'email'},
      {label: root.$t('contact.fax'), value: 'fax'},
    ]);

    // Photos
    const lastExamSubmission = ref(null);
    const lastExamPhotos = ref([]);
    const lastLoadTestSubmission = ref(null);
    const lastLoadTestPhotos = ref([]);

    // Pre-load
    watch(() => equipment.value, async (newValue, oldValue) => {
      if (!oldValue && newValue) {
        // Load Certs
        lastExamCert.value = newValue.last_exam_cert;
        lastLoadTestCert.value = newValue.last_load_cert;

        // Load Photos
        if (newValue.last_exam_submission) {
          let submission = newValue.last_exam_submission;
          lastExamSubmission.value = submission;
          lastExamPhotos.value = submission.photos.map((id) => `api/app/file/${id}`);
          // try {
          //   let submission = newValue.last_exam_submission;
          //   lastExamSubmission.value = submission;
          //   let photos = submission.photos.map((id) => {
          //     return {
          //       file_id: id,
          //       job_id: submission.job_id,
          //       job_submission_id: submission.id,
          //     }
          //   });
          //   let results = [];
          //   await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, 'Downloading Last Exam Photos...');
          //   for (const [idx, p] of photos.entries()) {
          //     results.push(await root.$store.dispatch(ACTION_TYPES.GET_FILE, p));
          //     root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, `Downloading Load Exam Photos (${idx + 1}/${photos.length}) ...`);
          //   }
          //   lastExamPhotos.value = results.map((result) => {
          //     return root.$convertBase64toFile(result.base64, result.filename);
          //   })
          // } catch (e) {
          //   console.error(`Failed to retrieve last exam photos: ${e}`)
          // } finally {
          //   await root.$store.dispatch(ACTION_TYPES.SET_LOADING_MSG, null);
          // }
        }

        if (newValue.last_load_submission) {
          let submission = newValue.last_load_submission;
          lastLoadTestSubmission.value = submission;
          lastLoadTestPhotos.value = submission.photos.map((id) => `api/app/file/${id}`);
        }
      }
    }, {immediate: true})

    // Footer
    const scheduleDatePickerDialogShown = ref(false);
    const showScheduleDatePicker = function () {
      scheduleDatePickerDialogShown.value = true;
    };
    const scheduleJob = async function (scheduleDate) {
      if (await root.$confirm(root.$t('job_list.schedule_job_confirm_msg', {
        job_no: 1,
        date: DateTime.fromJSDate(scheduleDate).toFormat('yyyy-MM-dd'),
      }))) {
        scheduleDatePickerDialogShown.value = false;
        await root.$store.dispatch(ACTION_TYPES.SCHEDULE_JOBS, {
          job_ids: [jobId.value],
          job_date: DateTime.fromJSDate(scheduleDate).toISO(),
        });
      }
    };

    const submittedLoadTest = computed(() => {
      // if (job.value && job.value.load_test_submission) {
      //   return JobSubmission.find(job.value.load_test_submission);
      // }
      return JobSubmission.query()
          .where('job_id', jobId.value)
          .where('type', SUBMISSION_TYPE.LOAD_TEST)
          .where('created_at', (value) => {
            return !!value;
          })
          .orderBy('created_at', 'desc').first();
    });
    const submittedExam = computed(() => {
      // if (job.value && job.value.exam_submission) {
      //   return JobSubmission.find(job.value.exam_submission);
      // }
      return JobSubmission.query()
          .where('job_id', jobId.value)
          .where('type', SUBMISSION_TYPE.EXAM)
          .where('created_at', (value) => {
            return !!value;
          })
          .orderBy('created_at', 'desc').first();
    });
    const unsubmittedLoadTest = computed(() => {
      return JobSubmission.query()
          .where('job_id', jobId.value)
          .where('type', SUBMISSION_TYPE.LOAD_TEST)
          .where('created_at', (value) => {
            return !value;
          })
          .orderBy('created_at', 'desc').first();
    });
    const unsubmittedExam = computed(() => {
      return JobSubmission.query()
          .where('job_id', jobId.value)
          .where('type', SUBMISSION_TYPE.EXAM)
          .where('created_at', (value) => {
            return !value;
          })
          .orderBy('created_at', 'desc').first();
    });

    const loadTestStartButtonIcon = computed(() => {
      if (submittedLoadTest.value) {
        return 'far fa-check-circle';
      } else if (unsubmittedLoadTest.value) {
        if (unsubmittedLoadTest.value.ready_to_submit) {
          return 'far fa-envelope';
        } else {
          return 'far fa-file';
        }
      } else {
        return 'fas fa-exclamation';
      }
    });
    const examStartButtonIcon = computed(() => {
      if (submittedExam.value) {
        return 'far fa-check-circle';
      } else if (unsubmittedExam.value) {
        if (unsubmittedExam.value.ready_to_submit) {
          return 'far fa-envelope';
        } else {
          return 'far fa-file';
        }
      } else {
        return 'fas fa-exclamation';
      }
    });

    const submitted = computed(() => {
      return JobSubmission.query()
          .where('job_id', jobId.value)
          .where('created_at', (value) => {
            return !!value;
          })
          .orderBy('created_at', 'desc').first();
    })
    const unsubmitted = computed(() => {
      return JobSubmission.query()
          .where('job_id', jobId.value)
          .where('created_at', (value) => {
            return !value;
          })
          .orderBy('created_at', 'desc').first();
    })
    const startButtonIcon = computed(() => {
      if (job.value.job_status === 2) return 'far fa-check-circle';
      if (submitted.value) {
        return 'far fa-check-circle';
      } else if (unsubmitted.value) {
        if (unsubmitted.value.ready_to_submit) {
          return 'far fa-envelope';
        } else {
          return 'far fa-file';
        }
      } else {
        return 'fas fa-exclamation';
      }
    })

    const resumeOrNewForm = async function () {
      const submitted = JobSubmission.query()
          .where('job_id', jobId.value)
          .where('created_at', (value) => {
            return !!value;
          })
          .orderBy('created_at', 'desc').first();

      if (submitted) {
        if (
            // Check if submit within same day
            !DateTime.fromISO(submitted.created_at).hasSame(DateTime.fromJSDate(new Date()), 'day') &&
            // Check if job is extended
            (!job.value.extended_until || (DateTime.fromISO(job.value.extended_until) < new Date()))
        ) {
          await root.$dialog.error({text: root.$t('job_detail.cannot_resubmit_msg')});
        } else {
          if (await root.$confirm(root.$t('job_detail.resubmitted_confirm_msg'))) {
            console.log(submitted);
            // Duplicate Submission
            const newFormId = uuid();

            // Copy Photos
            let promises = submitted.photos.map((p) => {
              let filename = `${equipment.value.equipment_reg_no}-${newFormId}-${DateTime.fromJSDate(new Date()).toMillis()}.jpg`
              return root.$store.dispatch(ACTION_TYPES.CLONE_FILE, {
                file_id: p,
                filename: filename,
                job_submission_id: newFormId,
              });
            });
            await Promise.all(promises);

            await JobSubmission.insert({
              data: {
                id: newFormId,
                job_id: jobId.value,
                type: job.value.job_type === JOB_TYPE.EXAMINATION ? SUBMISSION_TYPE.EXAM : SUBMISSION_TYPE.LOAD_TEST,
                coordinates: submitted.coordinates,
                address_detail: submitted.address_detail || job.value.equipment.address_detail,
                result: submitted.result,
                fail_reason: submitted.fail_reason,
                rpe_name: rpe.value.name,
                rpe_no: rpe.value.rpe_no,
                rpe_qualification: rpe.value.rpe_qualification,
                rpe_discipline: rpe.value.rpe_discipline,

                jib_length: submitted.jib_length,
                radius: submitted.radius,
                test_load: submitted.test_load,
                safe_working_load: submitted.safe_working_load,
                form_3_7: submitted.form_3_7,
                form_3_9: submitted.form_3_9,
              }
            });

            root.$dialog.showAndWait(JobForm, {
              fullscreen: true,
              showClose: false,
              jobSubmissionId: newFormId,
            })
          }
        }
      } else {
        // See if there is unsubmitted form
        const unsubmitted = JobSubmission.query()
            .where('job_id', jobId.value)
            .where('ready_to_submit', true)
            .where('created_at', null).first();

        // Ask if user want to resume or create new form
        if (unsubmitted) {
          if (await root.$confirm(root.$t('job_detail.resume_msg'), {
            buttonTrueText: 'Modify',
            buttonFalseText: 'Cacnel',
            persistent: true,
          })) {
            root.$dialog.showAndWait(JobForm, {
              fullscreen: true,
              showClose: false,
              jobSubmissionId: unsubmitted.id,
            })
            return;
          } else {
            return;
            // // Delete unsubmitted Form
            // await JobSubmission.delete(unsubmitted.id);
          }
        }

        // See if there is draft
        const draft = JobSubmission.query()
            .where('job_id', jobId.value)
            .where('ready_to_submit', false)
            .where('created_at', null).first();

        let formId = null;
        if (draft) {
          formId = draft.id;
        } else {
          // Create new form
          formId = uuid();
          await JobSubmission.insert({
            data: {
              id: formId,
              job_id: jobId.value,
              type: job.value.job_type === JOB_TYPE.EXAMINATION ? SUBMISSION_TYPE.EXAM : SUBMISSION_TYPE.LOAD_TEST,
              address_detail: equipment.value.address_detail,
              result: SUBMISSION_RESULT.PASS,

              rpe_name: rpe.value.name,
              rpe_no: rpe.value.rpe_no,
              rpe_qualification: rpe.value.rpe_qualification,
              rpe_discipline: rpe.value.rpe_discipline,

              jib_length: equipment.value.jib_length,
              radius: equipment.value.radius,
              test_load: equipment.value.test_load,
              safe_working_load: equipment.value.safe_working_load,
            }
          });

          await JobSubmission.hydrate({
            id: formId,
          })
          await Job.hydrate({
            id: jobId.value,
          });
        }
        root.$dialog.showAndWait(JobForm, {
          fullscreen: true,
          showClose: false,
          jobSubmissionId: formId,
        })
      }
    };

    return {
      back,
      job,

      // Status Bar
      jobStatusColor,
      jobStatusIcon,
      jobStatusLabel,

      // Equipment
      equipment,
      equipmentFields,

      // PDF
      lastExamCert,
      lastLoadTestCert,

      // Site
      siteFields,
      coordinatePickerShown,

      // Contacts
      contacts,
      contactFields,

      // Photos
      lastExamSubmission,
      lastExamPhotos,
      lastLoadTestSubmission,
      lastLoadTestPhotos,

      //
      submitted,
      unsubmitted,
      startButtonIcon,

      loadTestStartButtonIcon,
      examStartButtonIcon,
      resumeOrNewForm,

      submittedLoadTest,
      submittedExam,
      unsubmittedLoadTest,
      unsubmittedExam,

      scheduleDatePickerDialogShown,
      showScheduleDatePicker,
      scheduleJob,

      JOB_TYPE,
      SUBMISSION_TYPE,
      JOB_STATUS,
    }
  },
}
</script>

<style lang="less">
.job-details {
  background: white;
  height: 100vh;
  padding-top: 56px !important;

  .job-details-status-banner {

  }

  .job-detail-equipment-panel, .job-detail-site-panel, .job-detail-contact-panel, .job-detail-past-exam-panel {
    .data-iterator {
      height: calc(100vh - 230px);
      overflow-y: scroll;
    }
  }

  .job-detail-past-exam-panel {
    .form-title {
      font-weight: bold;
      font-size: 110%;
    }
  }

  &.offline {
    .data-iterator {
      height: calc(100vh - 289px) !important;
    }
  }
}
</style>
