<template>
  <v-container class="search-view pa-0 fill-height" fluid>
    <v-snackbar color="rgba(0, 0, 0, 0.5)"
                timeout="-1"
                :value="true"
                absolute
                top
                content-class="text-center">
      Scan QR code on Certificate or enter Registration No.
    </v-snackbar>

    <v-container class="pa-0">
      <v-row align-self="center">
        <v-col class="pa-0" align-self="center">
          <v-btn block
                 text
                 dark
                 x-large
                 :ripple="false"
                 @click="startCodeReader"
                 style="height: 100% !important;"
                 v-if="!htmlCameraShown">
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-icon size="60px">fas fa-camera</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Tap to Scan QR Code
                </v-col>
              </v-row>
            </v-container>
          </v-btn>

          <qrcode-stream :camera="camera"
                         @decode="onQrcodeDecode"
                         v-if="htmlCameraShown"></qrcode-stream>
        </v-col>
      </v-row>
    </v-container>

    <v-footer app
              inset
              class="pr-6 py-3">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            <v-text-field outlined
                          prepend-inner-icon="fas fa-search"
                          :label="$t('search.reg_no')"
                          v-model="regNo"
                          hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block
                   color="primary"
                   :disabled="!regNo"
                   @click="searchEquipment">{{ $t('search.title') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script>
import {ref, computed, onMounted} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import {QrcodeStream} from 'vue-qrcode-reader'

import jwtDecode from 'jwt-decode';
import {Equipment, Job} from "@/store/models";
import {JOB_STATUS} from "@/constants";
import JobDetails from "@/views/JobDetails";

export default {
  name: 'Search',
  components: {
    QrcodeStream,
  },
  setup(props, {root}) {
    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.SET_APPBAR_TITLE, root.$t('search.title'));
    });

    const startCodeReader = function () {
      if (tnQrCodeReaderAvailable.value) {
        startTnQrCodeReader();
      } else {
        htmlCameraShown.value = true;
      }
    }

    const tnQrCodeReaderAvailable = computed(() => {
      return window.tnConnector && window.tnConnector.util.scanCode;
    });
    const startTnQrCodeReader = function () {
      window.tnConnector.util.scanCode({}, ({type, data}) => {
        onQrcodeDecode(data);
      }, async ({errorMessage, isCameraPermissionDenied, isCancelledByUser}) => {
        if (isCameraPermissionDenied) {
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Camera Permission Denied');
        }
      })
    };

    const camera = ref('auto');
    const htmlCameraShown = ref(false);

    const onQrcodeDecode = async function (content) {
      htmlCameraShown.value = false;

      const j = content.substr(content.indexOf('#') + 1);

      try {
        let jwt = jwtDecode(j);
        regNo.value = jwt.eq;
        await searchEquipment();
      } catch (e) {
        console.log(e);
        await root.$dialog.error({text: root.$t('search.invalid_qr_code')});
      }

      camera.value = 'auto';
    };

    const regNo = ref('');
    const searchEquipment = async function () {
      regNo.value = regNo.value.toUpperCase();
      let equipment = Equipment.query().where('equipment_reg_no', regNo.value).first();

      if (!equipment) {
        await root.$dialog.error({text: root.$t('search.equipment_not_assigned')});
        return;
      }

      let job = Job.query().where('equipment_id', equipment.id).where((j) => j.job_status !== JOB_STATUS.RESCHEDULED && j.job_status !== JOB_STATUS.CANCELLED).first();

      if (!job) {
        job = Job.query().where('equipment_id', equipment.id).where((j) => j.job_status !== JOB_STATUS.RESCHEDULED).first();
      }

      if (!job) {
        await root.$dialog.error({text: root.$t('search.equipment_not_assigned')});
        return;
      }

      root.$dialog.showAndWait(JobDetails, {
        jobId: job.id,
        fullscreen: true,
        showClose: false,
      });

      // root.$router.push({name: 'job-details', params: {jobId: job.id}});
    };

    return {
      startCodeReader,

      camera,
      htmlCameraShown,
      onQrcodeDecode,
      regNo,
      searchEquipment,
    };
  },
}
</script>

<style lang="less">
.search-view {
  background-color: black;
}
</style>
