<template>
  <v-card>
    <v-card-title>
      Submission Unsuccessful
    </v-card-title>
    <v-card-text class="text-left">
      <v-row class="font-weight-bold">
        <v-col>Submission was unsuccessful due to network issue</v-col>
      </v-row>
      <v-row>
        <v-col>
          This finished task has been saved as "Un-submitted Form". You may now close this message and try the following:
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ol>
            <li>submit again once the mobile network becomes available.</li>
            <li>leave this submission page, once the mobile network becomes available, click "Batch-submit Un-submitted Forms" on the top-right corner to submit all "Un-submitted Forms"</li>
          </ol>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="text-right">
      <v-spacer></v-spacer>
      <v-btn text
             color="primary"
             @click="$emit('submit')">
        {{ $t('ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SubmissionFailDialog'
}
</script>
