import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VSelect,_vm._g(_vm._b({attrs:{"label":_vm.label,"items":_vm.items,"menu-props":{closeOnClick: true},"value":_vm.value}},'v-select',_vm.$attrs,false),on))]}}]),model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c(VCard,[_c(VCardTitle,{staticClass:"flex-grow-0"},[_vm._v(_vm._s(_vm.label))]),_c(VCardSubtitle,{staticClass:"flex-grow-0 pb-0"},[_c(VAutocomplete,{attrs:{"readonly":"","dense":"","chips":"","small-chips":"","deletable-chips":"","multiple":"","menu-props":{closeOnClick: true},"append-icon":" ","items":_vm.items},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c(VChip,{attrs:{"close":""},on:{"dblclick":function($event){return _vm.removeItem(index)},"click:close":function($event){return _vm.removeItem(index)}}},[_vm._v(_vm._s(_vm.truncateText(item.text))+" ")]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedItems.length - 3)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c(VCardText,{staticClass:"flex-grow-1 pa-0 d-flex flex-column"},[_c(VVirtualScroll,{attrs:{"bench":20,"items":_vm.filteredItems,"item-height":60},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{key:item.value,on:{"click":function($event){return _vm.itemClicked(item)}}},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"readonly":"","input-value":_vm.selectedItems.indexOf(item.value) !== -1}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)],1)]}}])}),_c(VTextField,{staticClass:"px-2",attrs:{"outlined":"","prepend-inner-icon":"fas fa-search","hide-details":""},on:{"input":_vm.onSearchInput}})],1),_c(VCardActions,{staticClass:"flex-grow-0"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialogShown = false}}},[_vm._v(" CANCEL ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.onSelectionClear}},[_vm._v(" CLEAR ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.onSelectionConfirm}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }