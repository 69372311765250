import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Select Photo Source ")]),_c(VCardText,{staticClass:"text-left"},[_c(VRow,{staticClass:"font-weight-bold"},[_c(VCol,{on:{"click":function($event){return _vm.$emit('submit', 'camera')}}},[_c(VIcon,[_vm._v("fas fa-camera")]),_vm._v(" Camera ")],1),_c(VCol,{on:{"click":function($event){return _vm.$emit('submit', 'file')}}},[_c(VIcon,[_vm._v("fas fa-images")]),_vm._v(" Photo Album ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }