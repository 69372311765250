<template>
  <v-container class="pa-0">
    <v-row>
      <v-col :cols="$slots['title-append'] ? 8 : 12">
        <label class="form-title">
          {{ title }}
        </label>
      </v-col>
      <v-col cols="4" class="text-right" v-if="$slots['title-append']">
        <slot name="title-append"></slot>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="[{'pa-0': noPadding}]">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    title: String,
    noPadding: Boolean,
  },
}
</script>