import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","transition":"slide-x-reverse-transition"},model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c(VContainer,{staticClass:"coordinate-picker-dialog-container",attrs:{"fluid":""}},[_c(VAppBar,{attrs:{"color":"primary","dark":"","absolute":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c(VIcon,[_vm._v("fas fa-chevron-left")])],1),_c(VSpacer),(!_vm.viewOnly)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.confirm}},[_c(VIcon,[_vm._v("fas fa-check")])],1):_vm._e()],1),_c('mgl-map',{attrs:{"accessToken":_vm.mapboxAccessToken,"mapStyle":_vm.mapboxMapStyle,"minZoom":10,"boxZoom":"","center":_vm.mapCenter},on:{"update:center":function($event){_vm.mapCenter=$event}}},[_c('mgl-navigation-control'),_c('mgl-geolocate-control',{attrs:{"trackUserLocation":""}}),(!_vm.viewOnly)?_c(VImg,{staticClass:"coordinate-picker-map-pin",attrs:{"src":require("../assets/pin.png"),"width":"20","height":"32"}}):_vm._e(),(_vm.viewOnly)?_c('MglMarker',{attrs:{"coordinates":_vm.originalMapCenter}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }