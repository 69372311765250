<template>
  <v-card>
    <v-card-title>
      Select Photo Source
    </v-card-title>
    <v-card-text class="text-left">
      <v-row class="font-weight-bold">
        <v-col @click="$emit('submit', 'camera')">
          <v-icon>fas fa-camera</v-icon>
          Camera
        </v-col>
        <v-col @click="$emit('submit', 'file')">
          <v-icon>fas fa-images</v-icon>
          Photo Album
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SelectPhotoSource'
}
</script>
