<template>
  <v-container class="rpe-info pt-16" fluid>
    <v-row>
      <v-col class="text-center">
        <v-icon color="primary"
                size="56px">fas fa-user
        </v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label>{{ $t('rpe_info.label') }}</label>
      </v-col>
    </v-row>
    <v-form>
      <v-combobox outlined
                  :items="rpeOptions"
                  :label="$t('account.rpe_name')"
                  :placeholder="$t('account.rpe_name')"
                  @change="onRpeNameChange"
                  v-model="rpeName"
                  :rules="requiredRules">
      </v-combobox>
      <v-text-field outlined
                    prefix="RP"
                    :label="$t('account.rpe_no')"
                    :placeholder="$t('account.rpe_no')"
                    v-model="rpeNo"
                    :rules="rpeNoRules">
      </v-text-field>
      <v-text-field outlined
                    :label="$t('account.rpe_qualification')"
                    :placeholder="$t('account.rpe_qualification')"
                    v-model="rpeQualification"
                    :rules="requiredRules">
      </v-text-field>
      <v-text-field outlined
                    :label="$t('account.rpe_discipline')"
                    :placeholder="$t('account.rpe_discipline')"
                    v-model="rpeDiscipline"
                    :rules="requiredRules">
      </v-text-field>
    </v-form>

    <v-footer app
              inset
              class="pr-6 py-3">
      <v-row>
        <v-col>
          <v-btn block
                 color="primary"
                 @click="save">
            {{ $t('rpe_info.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from "luxon";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'RpeInfo',
  components: {DatePickerInput},
  setup(props, {root}) {
    const rpeOptions = ref([]);
    const getRpe = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'app/rpe/',
      });

      if (response.body.success) {
        rpeOptions.value = response.body.rpes.map((r) => {
          return {
            text: r.rpe_name,
            value: {
              ...r,
              rpe_no: r.rpe_no.replaceAll('RP', ''),
            },
          }
        })
      }
    }
    const onRpeNameChange = function (e) {
      if (e) {
        rpeName.value = e.value.rpe_name;
        rpeNo.value = e.value.rpe_no;
        rpeQualification.value = e.value.rpe_qualification;
        rpeDiscipline.value = e.value.rpe_discipline;
      }
    }


    const rpeName = ref(null);
    const rpeNo = ref('');
    const rpeQualification = ref('');
    const rpeDiscipline = ref('');
    const rpeValidFrom = ref('');

    const requiredRules = ref([
      value => !!value || 'Required',
    ]);
    const rpeNoRules = ref([
      value => !!value || 'Required',
      value => value.toString().length === 7 || 'RPE Registration No. must have consist of 7 digits',
    ]);
    const rpeValidFromErrorMsg = computed(() => {
      if (DateTime.fromJSDate(new Date).plus({year: 1}) > DateTime.fromFormat(rpeValidFrom.value, 'yyyy-MM-dd')) {
        return null;
      }
      return 'Your registration must be valid within the following year'
    })

    const save = async function () {
      await root.$store.dispatch(ACTION_TYPES.SET_RPE, {
        name: rpeName.value,
        rpe_no: 'RP' + rpeNo.value,
        rpe_qualification: rpeQualification.value,
        rpe_discipline: rpeDiscipline.value,
        valid_from: rpeValidFrom.value,
      });
      root.$router.replace({name: 'job-list'});
    }

    onMounted(async () => {
      await getRpe()

      await root.$store.dispatch(ACTION_TYPES.SET_APPBAR_TITLE, root.$t('rpe_info.title'));

      const rpe = root.$store.getters.rpe;
      rpeName.value = rpe.name;
      rpeNo.value = rpe.rpe_no.substring(2);
      rpeQualification.value = rpe.rpe_qualification;
      rpeDiscipline.value = rpe.rpe_discipline;
      rpeValidFrom.value = rpe.valid_from;
    });

    return {
      rpeOptions,
      onRpeNameChange,

      rpeName,
      rpeNo,
      rpeQualification,
      rpeDiscipline,
      rpeValidFrom,
      requiredRules,
      rpeNoRules,
      rpeValidFromErrorMsg,
      save,
    }
  },
}
</script>
