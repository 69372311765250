<template>
  <v-container class="change-contract pt-16" fluid>
    <v-row>
      <v-col class="text-center">
        <v-icon color="primary"
                size="56px">fas fa-exchange-alt
        </v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label>{{ $t('change_contract.label') }}</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field readonly
                      :label="$t('account.contractor')"
                      :value="contractor.name">
        </v-text-field>
      </v-col>
    </v-row>
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-text-field readonly-->
<!--                      :label="$t('account.rpe_name')"-->
<!--                      :value="rpe.name">-->
<!--        </v-text-field>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-text-field readonly-->
<!--                      :label="$t('account.rpe_no')"-->
<!--                      :value="rpe.rpe_no">-->
<!--        </v-text-field>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-text-field readonly-->
<!--                      :label="$t('account.rpe_qualification')"-->
<!--                      :value="rpe.rpe_qualification">-->
<!--        </v-text-field>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-text-field readonly-->
<!--                      :label="$t('account.rpe_discipline')"-->
<!--                      :value="rpe.rpe_discipline">-->
<!--        </v-text-field>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col>
        <v-select outlined
                  :label="$t('account.contract_id')"
                  :placeholder="$t('account.contract_id')"
                  :items="contracts"
                  item-text="id"
                  item-value="id"
                  v-model="selectedContract">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select outlined
                  :label="$t('account.schedule_plan_id')"
                  :placeholder="$t('account.schedule_plan_id')"
                  :items="schedulePlans"
                  item-text="name"
                  item-value="id"
                  v-model="selectedSchedulePlan">
        </v-select>
      </v-col>
    </v-row>
    <v-footer app
              inset
              class="pr-6 py-3">
      <v-row>
        <v-col>
          <v-btn block
                 color="primary"
                 @click="changeContract">
            {{ $t('confirm') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {Contract, SchedulePlan} from "@/store/models";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'ChangeContract',
  setup(props, {root}) {
    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.SET_APPBAR_TITLE, root.$t('change_contract.title'));
      await root.$store.dispatch(ACTION_TYPES.RETRIEVE_CONTRACTS);
    });

    const contractor = computed(() => root.$store.getters.contractor);
    const rpe = computed(() => root.$store.getters.rpe)
    const contracts = computed(() => Contract.all());
    const selectedContract = ref(root.$store.getters.currentContractNo);

    const schedulePlans = computed(() => {
      if (!selectedContract.value) {
        return [];
      }
      return SchedulePlan.query().where('contract', selectedContract.value).all();
    });
    const selectedSchedulePlan = ref(root.$store.getters.currentSchedulePlanId);

    const changeContract = async function () {
      await root.$store.dispatch(ACTION_TYPES.SET_CURRENT_CONTRACT, {contractNo: selectedContract.value, schedulePlanId: selectedSchedulePlan.value});

      await root.$store.dispatch(ACTION_TYPES.RETRIEVE_JOBS, {forceReload: true});

      root.$router.replace({name: 'job-list'});
    };

    return {
      contractor,
      rpe,
      contracts,
      selectedContract,
      schedulePlans,
      selectedSchedulePlan,
      changeContract,
    }
  },
}
</script>
