<template>
  <v-dialog persistent
            v-model="dialogShown">
    <v-card class="pa-4">
      <v-card-title></v-card-title>
      <v-card-text class="text-center">
        <vc-date-picker color="red"
                        is-inline
                        is-expanded
                        v-model="selectedDate"></vc-date-picker>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn block
                   @click="cancel">
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block
                   color="primary"
                   :disabled="!selectedDate"
                   @click="confirm">
              {{ $t('confirm') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, watch} from "@vue/composition-api";

export default {
  name: 'DatePickerModal',
  props: {
    shown: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);
    watch(() => props.shown, (newValue, oldValue) => {
      dialogShown.value = newValue;

      if (newValue) {
        selectedDate.value = null;
      }
    });

    const selectedDate = ref(null);
    const cancel = function() {
      emit('cancel');
    };
    const confirm = function() {
      emit('confirm', selectedDate.value);
    };

    return {
      dialogShown,
      selectedDate,
      cancel,
      confirm,
    }
  }
}
</script>