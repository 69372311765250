const JOB_TYPE = {
    LOAD_TEST_AND_EXAMINATION: "LOAD_TEST_AND_EXAMINATION",
    EXAMINATION: "EXAMINATION",
};

const JOB_STATUS = {
    PENDING: 0,
    SCHEDULED: 1,
    COMPLETED: 2,
    CANCELLED: -1,
    RESCHEDULED: 4,
};

const SUBMISSION_RESULT = {
    PASS: 'PASS',
    FAIL: 'FAIL',

    OTHER: 'OTHER',
    MISSING: 'MISSING',
    UNDER_REPAIR: 'UNDER_REPAIR',
    NOT_READY: 'NOT_READY',
}

const SUBMISSION_TYPE = {
    LOAD_TEST: 'LOAD_TEST',
    EXAM: 'EXAM',
};

const EVENT_SHOW_MORE = 'EVENT_SHOW_MORE'
const EVENT_SHOW_FILTER = 'EVENT_SHOW_FILTER'
const EVENT_SHOW_SCHEDULER = 'EVENT_SHOW_SCHEDULER'
const EVENT_SHOW_PDF = 'EVENT_SHOW_PDF'

export {JOB_TYPE, JOB_STATUS, SUBMISSION_RESULT, SUBMISSION_TYPE, EVENT_SHOW_MORE, EVENT_SHOW_FILTER, EVENT_SHOW_SCHEDULER, EVENT_SHOW_PDF};
