<template>
  <v-card>
    <v-card-text>
      <h2>選擇裝置種類</h2>
      <v-row>
        <v-col>
          <v-btn color="primary"
                 block
                 @click="$emit('submit', true)">
            固定電氣裝置
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary"
                 block
                 @click="$emit('submit', false)">
            可攜式電動起重裝置
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'ChecklistEquipmentTypeModal',
  props: {},
  setup(props, {root, emit}) {
  },
}
</script>
