import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","transition":"fade-transition"},model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c(VContainer,{staticClass:"camera-capture-dialog-container pa-0 fill-height",attrs:{"fluid":""}},[(!_vm.cameraStarted)?_c('semipolar-spinner',{staticClass:"spinner",attrs:{"animation-duration":2000,"size":65,"color":"#ff1d5e"}}):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"align-self":"center"}},[_c('WebCam',{ref:"webcam",attrs:{"width":"100%","height":"100%","select-first-device":"","device-id":_vm.currentCameraDeviceId},on:{"video-live":function($event){_vm.cameraStarted = true},"stopped":function($event){_vm.cameraStarted = false},"cameras":_vm.onCameraListed}})],1)],1),_c(VFooter,{attrs:{"absolute":""}},[_c(VContainer,{staticClass:"pa-0"},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"icon":"","rounded":"","x-large":"","disabled":!_vm.cameraStarted || _vm.capturing},on:{"click":_vm.capture}},[_c(VIcon,[_vm._v("fas fa-camera")])],1)],1)],1)],1)],1),_c('div',{staticClass:"camera-capture-close-icon",on:{"click":_vm.cancel}},[_c(VIcon,[_vm._v("fas fa-times")])],1),(_vm.cameras.length > 1)?_c('div',{staticClass:"camera-capture-switch-camera-icon",on:{"click":_vm.switchCamera}},[_c(VIcon,[_vm._v("fas fa-sync-alt")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }