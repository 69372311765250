<template>
  <v-container fluid
               class="site-cell pa-4"
               @click="onSiteClicked(site)">
    <v-row no-gutters>
      <v-col>{{ site.name }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>{{ `${site.street}` }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>{{ `${site.district}, ${site.region}` }}</v-col>
    </v-row>
    <v-row class="d-flex justify-space-around">
      <v-chip color="#ffe660">
        <v-icon class="mr-2">fas fa-user-clock</v-icon>
        {{ jobStatusCounts[0] || '0' }}
      </v-chip>
      <v-chip color="#91d7ff">
        <v-icon class="mr-2">fas fa-check</v-icon>
        {{ jobStatusCounts[2] || '0' }}
      </v-chip>
      <v-chip color="red">
        <v-icon class="mr-2">fas fa-exclamation-triangle</v-icon>
        {{ expiredJobs.length }}
      </v-chip>
      <v-chip color="#6CDC87">
        <v-icon class="mr-2">far fa-envelope</v-icon>
        {{ unsubmittedJobs.length }}
      </v-chip>
    </v-row>
  </v-container>
</template>

<script>
import {computed, ref} from '@vue/composition-api';
import _ from "lodash";
import {JOB_STATUS, JOB_TYPE} from "@/constants";
import {DateTime} from "luxon";

export default {
  name: 'SiteCell',
  props: {
    index: Number,
    source: Object,
  },
  setup(props, {root, emit}) {
    const site = ref(props.source);

    const jobStatusCounts = computed(() => {
      return _.countBy(props.source.jobs, 'job_status');
    })
    const isJobExpired = function (job) {
      if (job.job_status === JOB_STATUS.COMPLETED) return false;

      // Check if equipment expired
      if (job.type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION && job.equipment && job.equipment.next_load) {
        if (DateTime.fromISO(job.equipment.next_load) < DateTime.fromJSDate(new Date())) {
          return true
        }
      }

      // Check if equipment expired
      if (job.equipment && job.equipment.next_exam) {
        if (DateTime.fromISO(job.equipment.next_exam) < DateTime.fromJSDate(new Date())) {
          return true
        }
      }

      return false;
    };
    const expiredJobs = computed(()=> {
      return _.filter(props.source.jobs, isJobExpired);
    })
    const isJobNotSubmitted = function (job) {
      return _.some(job.submissions, (s) => s.ready_to_submit)
    };
    const unsubmittedJobs = computed(()=> {
      return _.filter(props.source.jobs, isJobNotSubmitted);
    })

    const onSiteClicked = function (site) {
      root.$eventHub.$emit('SiteClicked', site);
    };

    return {
      site,
      jobStatusCounts,
      expiredJobs,
      unsubmittedJobs,
      onSiteClicked,
    }
  }
}
</script>

<style lang="less">
.job-list-cell {
  height: 85px;
  border-bottom: solid 1px lightgray;

  &.expired {
    background-color: rgba(255, 0, 0, 0.5);
  }

  &.not-submitted {
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 10px, rgba(255, 255, 255, 0.5) 10px, rgba(255, 255, 255, 0.5) 40px);

    &.expired {
      background: repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2) 10px, rgba(255, 0, 0, 0.5) 10px, rgba(255, 0, 0, 0.5) 40px);
    }
  }

  .v-chip {
    .v-icon {
      font-size: 18px;
    }
  }
}
</style>
