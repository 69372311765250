import {ACTION_TYPES} from "@/store/types";

export const accountModule = {
    state: {
        isLoggedIn: false,
        account: null,
        photoLimit: 5,
        contractor: null,
        rpe: null,
        accountId: null,
    },
    mutations: {
        setAccount(state, account) {
            state.isLoggedIn = true;
            state.account = account;
            state.contractor = account.contractor;
        },
        setPhotoLimit(state, photoLimit) {
            state.photoLimit = photoLimit;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.account = null;
            state.contractor = null;
        },
        setRpe(state, {rpe, accountId}) {
            state.rpe = rpe;
            state.accountId = accountId;
        },
        clearRpe(state, rpe) {
            state.rpe = null;
            state.accountId = null;
        },
    },
    actions: {
        [ACTION_TYPES.GET_ME]: {
            root: true,
            async handler({dispatch, commit}) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {url: 'app/me/', opt: {disable_error_msg: true}});
                dispatch(ACTION_TYPES.SET_ME, response.body.account);
                dispatch(ACTION_TYPES.SET_PHOTO_LIMIT, response.body.photo_limit || 5);
                return response;
            },
        },
        [ACTION_TYPES.SET_ME]: {
            root: true,
            handler({commit, state}, account) {
                commit('setAccount', account);
                if (account.id !== state.accountId) {
                    commit('clearRpe');
                }
            },
        },
        [ACTION_TYPES.SET_PHOTO_LIMIT]: {
            root: true,
            handler({commit, state}, photo_limit) {
                commit('setPhotoLimit', photo_limit);
            },
        },
        [ACTION_TYPES.AUTHENTICATE_TEAMNOTE_JWT]: {
            root: true,
            async handler({dispatch, commit}, jwt) {
                await dispatch(ACTION_TYPES.CALL_API, {url: 'app/jwt/', params: {jwt}});
            },
        },
        [ACTION_TYPES.LOGIN]: {
            root: true,
            async handler({dispatch, commit}, params) {
                const response = await dispatch(ACTION_TYPES.CALL_API, {url: 'app/login/', params});
                dispatch(ACTION_TYPES.SET_ME, response.body.account);
                dispatch(ACTION_TYPES.SET_PHOTO_LIMIT, response.body.photo_limit || 5);
                return response;
            },
        },
        [ACTION_TYPES.LOGOUT]: {
            root: true,
            handler({commit}) {
                commit('logout');
                commit('clearRpe');
                commit('clearLastUpdateTimestamp');
            }
        },
        [ACTION_TYPES.SET_RPE]: {
            root: true,
            handler({commit, state}, rpe) {
                commit('setRpe', {rpe, accountId: state.account.id});
            }
        },
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        account(state) {
            return state.account;
        },
        photoLimit(state) {
            return state.photoLimit;
        },
        contractor(state) {
            return state.contractor;
        },
        rpe(state) {
            return state.rpe;
        },
    },
};
