import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"change-contract pt-16",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center"},[_c(VIcon,{attrs:{"color":"primary","size":"56px"}},[_vm._v("fas fa-exchange-alt ")])],1)],1),_c(VRow,[_c(VCol,[_c('label',[_vm._v(_vm._s(_vm.$t('change_contract.label')))])])],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"readonly":"","label":_vm.$t('account.contractor'),"value":_vm.contractor.name}})],1)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"outlined":"","label":_vm.$t('account.contract_id'),"placeholder":_vm.$t('account.contract_id'),"items":_vm.contracts,"item-text":"id","item-value":"id"},model:{value:(_vm.selectedContract),callback:function ($$v) {_vm.selectedContract=$$v},expression:"selectedContract"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"outlined":"","label":_vm.$t('account.schedule_plan_id'),"placeholder":_vm.$t('account.schedule_plan_id'),"items":_vm.schedulePlans,"item-text":"name","item-value":"id"},model:{value:(_vm.selectedSchedulePlan),callback:function ($$v) {_vm.selectedSchedulePlan=$$v},expression:"selectedSchedulePlan"}})],1)],1),_c(VFooter,{staticClass:"pr-6 py-3",attrs:{"app":"","inset":""}},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":_vm.changeContract}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }