import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from "@/views/Landing";
import Login from "@/views/Login";
import SelectContract from "@/views/SelectContract";
import Job from "@/views/Job";
import Site from "@/views/Site";
import JobDetails from "@/views/JobDetails";
import JobForm from "@/views/JobForm";
import Schedule from "@/views/Schedule";
import Search from "@/views/Search";
import ChangeContract from "@/views/ChangeContract";
import RpeInfo from "@/views/RpeInfo";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'landing',
        component: Landing,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/rpe',
        name: 'rpe',
        component: SelectContract,
    },
    {
        path: '/jobs',
        name: 'job-list',
        component: Job,
    },
    {
        path: '/sites',
        name: 'site-list',
        component: Site,
    },
    {
        path: '/detail/:jobId',
        name: 'job-details',
        component: JobDetails,
    },
    {
        path: '/form/:jobSubmissionId',
        name: 'job-form',
        component: JobForm,
    },
    {
        path: '/schedule',
        name: 'schedule',
        component: Schedule,
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
    },
    {
        path: '/change_contract',
        name: 'change-contract',
        component: ChangeContract,
    },
    {
        path: '/rpe_info',
        name: 'rpe-info',
        component: RpeInfo,
    },
]

const router = new VueRouter({
    routes
})

export default router
