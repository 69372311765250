<template>
  <v-container class="pa-0"
               id="job">
    <OnlineStatusBanner></OnlineStatusBanner>
    <GroupedJobList :jobs="filteredJobs"
                    @click="onJobClicked"></GroupedJobList>
  </v-container>
</template>

<script>
import _ from 'lodash'
import {computed, onMounted, onUnmounted, ref} from '@vue/composition-api'
import {ACTION_TYPES} from "@/store/types";
import {Client, Job, Site} from "@/store/models";
import OnlineStatusBanner from "@/components/OnlineStatusBanner";
import {DateTime} from 'luxon';
import {JOB_STATUS, JOB_TYPE} from "@/constants";
import GroupedJobList from "@/components/GroupedJobList";

export default {
  name: 'Site',
  components: {GroupedJobList, OnlineStatusBanner},
  setup(props, {root}) {
    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR);
      await root.$store.dispatch(ACTION_TYPES.SET_APPBAR_TITLE, root.$t('site_list.title'));
      await root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR_ELLIPSIS);
      await root.$store.dispatch(ACTION_TYPES.RETRIEVE_JOBS, {forceReload: false});
      root.$eventHub.$on('onAppBarEllipsisClicked', () => {
        moreActionDialogShown.value = true;
      })
    });

    onUnmounted(() => {
      root.$store.dispatch(ACTION_TYPES.HIDE_APPBAR_ELLIPSIS);
    })

    const clients = computed(() => Client.all());
    const clientMap = computed(() => {
      let map = {};
      clients.value.forEach((c) => {
        map[c.code] = c;
      });
      return map;
    })
    const sites = computed(() => Site.all());
    const siteMap = computed(() => {
      let map = {};
      sites.value.forEach((s) => {
        map[s.id] = s;
      });
      return map;
    })

    const filteredJobs = computed(() => {
      let filters = root.$store.getters.jobFilters;
      let filteredJobs = Job.query().withAllRecursive().get();

      // if (filters.jobStatus.length > 0) {
      //   filteredJobs = _.filter(filteredJobs, (job) => {
      //     return filters.jobStatus.indexOf(job.job_status) !== -1
      //   });
      // }

      if (filters.client.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.client.indexOf(job.equipment.site.client.code) !== -1;
        });
      }

      if (filters.site.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.site.indexOf(job.equipment.site.id) !== -1
        });
      }

      if (filters.attn.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.attn.indexOf(job.equipment.site.memo_attn) !== -1
        });
      }

      if (filters.district.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.district.indexOf(job.equipment.site.district) !== -1;
        });
      }

      if (filters.region.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.region.indexOf(job.equipment.site.region) !== -1;
        });
      }

      if (filters.jobType.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.jobType.indexOf(job.job_type) !== -1;
        });
      }

      let sortDescending = filters.sortBy.startsWith('-');
      let sortBy = filters.sortBy.substr(filters.sortBy.indexOf('-') + 1);

      let expired = _.remove(filteredJobs, (j) => {
        if (j.job_status === JOB_STATUS.COMPLETED) {
          return false
        }

        // Check if equipment expired
        if (j.type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION && j.equipment && j.equipment.next_load) {
          if (DateTime.fromISO(j.equipment.next_load) < DateTime.fromJSDate(new Date())) {
            return true
          }
        }

        // Check if equipment expired
        if (j.equipment && j.equipment.next_exam) {
          if (DateTime.fromISO(j.equipment.next_exam) < DateTime.fromJSDate(new Date())) {
            return true
          }
        }

        return false;
      });
      expired = _.sortBy(expired, [j => _.get(j, sortBy, j.id)]);
      if (sortDescending) {
        expired = _.reverse(expired);
      }

      filteredJobs = _.sortBy(filteredJobs, [j => _.get(j, sortBy, j.id)]);
      if (sortDescending) {
        filteredJobs = _.reverse(filteredJobs);
      }

      return _.concat(expired, filteredJobs)
    });

    const moreActionDialogShown = ref(false);

    // Job Details
    const onJobClicked = function (job) {
      root.$router.push({name: 'job-details', params: {jobId: job.id}});
    };

    return {
      clientMap,
      siteMap,
      filteredJobs,

      moreActionDialogShown,

      onJobClicked,
    }
  }
}
</script>

<style lang="less">
#job {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.filter-dialog-container, .schedule-dialog-container {
  background-color: white;
  height: 100vh;
  padding-top: 56px;
  overflow-y: auto;
}

.schedule-dialog-container {
  padding-bottom: 84px;
}
</style>
