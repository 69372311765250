<template>
  <v-dialog v-model="dialogShown"
            fullscreen
            transition="slide-x-reverse-transition">
    <v-container fluid class="coordinate-picker-dialog-container">
      <v-app-bar color="primary"
                 dark
                 absolute>
        <v-btn icon
               @click="cancel">
          <v-icon>fas fa-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon
               @click="confirm"
               v-if="!viewOnly">
          <v-icon>fas fa-check</v-icon>
        </v-btn>
      </v-app-bar>

      <mgl-map :accessToken="mapboxAccessToken"
               :mapStyle="mapboxMapStyle"
               :minZoom="10"
               boxZoom
               :center.sync="mapCenter">
        <mgl-navigation-control></mgl-navigation-control>
        <mgl-geolocate-control trackUserLocation></mgl-geolocate-control>
        <v-img src="../assets/pin.png"
               width="20"
               height="32"
               class="coordinate-picker-map-pin"
               v-if="!viewOnly"></v-img>
        <MglMarker :coordinates="originalMapCenter"
                   v-if="viewOnly"></MglMarker>
      </mgl-map>
    </v-container>
  </v-dialog>
</template>

<script>
import {MglMap, MglNavigationControl, MglGeolocateControl, MglMarker} from "vue-mapbox";
import {ref, computed, watch} from '@vue/composition-api';

export default {
  name: 'CoordinatePicker',
  components: {MglMap, MglNavigationControl, MglGeolocateControl, MglMarker},
  props: {
    value: String,
    shown: Boolean,
    viewOnly: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);
    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;

        if (newValue) {
          originalMapCenter.value = JSON.parse(props.value || '{"lat": 22.3193, "lng": 114.1694}');
          // mapCenter.value = JSON.parse(props.value || '{"lat": 22.3193, "lng": 114.1694}');
        }
      }
    })

    const cancel = function () {
      emit('cancel');
    };
    const confirm = function () {
      emit('confirm', mapCenter.value);
    };
    // watch(() => props.value, (newVal, oldVal) => {
    //   console.log(newVal, oldVal, newVal !== oldVal);
    //   // if (newVal !== oldVal) {
    //   //   mapCenter.value = JSON.parse(newVal || '{"lat": 22.3193, "lng": 114.1694}');
    //   // }
    // });

    // Mapbox
    const mapboxAccessToken = 'pk.eyJ1Ijoia2VuZnUiLCJhIjoiY2s5dGdtNTVrMTVqbjNtbXI1ZjV5MzF0bCJ9.Wtd75gUSZgEayezDOqJ60w';
    const mapboxMapStyle = 'mapbox://styles/mapbox/streets-v11';
    const originalMapCenter = ref(JSON.parse(props.value || '{"lat": 22.3193, "lng": 114.1694}'));
    const mapCenter = ref(JSON.parse(props.value || '{"lat": 22.3193, "lng": 114.1694}'));

    return {
      dialogShown,
      cancel,
      confirm,

      mapboxAccessToken,
      mapboxMapStyle,
      originalMapCenter,
      mapCenter,
    }
  }
}
</script>

<style lang="less">
.coordinate-picker-dialog-container {
  background-color: white;
  height: 100vh;
  padding-top: 68px;

  .coordinate-picker-map-pin {
    position: absolute;
    bottom: 50%;
    left: calc(50% - 10px);
  }
}
</style>