import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import ormPlugin from './models'
import {accountModule} from "@/store/modules/account";
import {snackBarModule} from "@/store/modules/snackBar";
import {apiModule} from "@/store/modules/api";
import {lalgModule} from "@/store/modules/lalg";
import localforage from "localforage";

Vue.use(Vuex)

const vuexTeamnote = new VuexPersistence({
    storage: localforage,
    // saveState(key, state) {
    //     if (window.tnConnector) {
    //         return new Promise((resolve, reject) => {
    //             window.tnConnector.app.setStorage({
    //                 key: key,
    //                 value: state,
    //             }, () => {
    //                 resolve();
    //             }, (error) => {
    //                 console.log(error);
    //                 reject(error);
    //             });
    //         });
    //     } else {
    //         return new Promise((resolve, reject) => {
    //             resolve(localStorage.setItem(key, JSON.stringify(state)))
    //         });
    //
    //     }
    // },
    // restoreState(key) {
    //     console.log(`restoreState: ${key}`);
    //     if (window.tnConnector) {
    //         return new Promise((resolve, reject) => {
    //             window.tnConnector.app.getStorage({
    //                 key: key,
    //             }, ({value}) => {
    //                 console.log(value);
    //                 resolve(value);
    //             }, (error) => {
    //                 console.log(error);
    //                 reject(error);
    //             });
    //         });
    //     } else {
    //         return new Promise((resolve, reject) => {
    //             resolve(JSON.parse(localStorage.getItem(key)))
    //         });
    //     }
    // },
    asyncStorage: true,
})

export default new Vuex.Store({
    modules: {
        account: accountModule,
        snackBar: snackBarModule,
        api: apiModule,
        lalg: lalgModule,
    },
    plugins: [
        ormPlugin,
        vuexTeamnote.plugin,
    ]
})
