<template>
  <v-dialog v-model="dialogShown"
            fullscreen
            transition="fade-transition">
    <v-container fluid class="camera-capture-dialog-container pa-0 fill-height">
      <semipolar-spinner class="spinner"
                         :animation-duration="2000"
                         :size="65"
                         color="#ff1d5e"
                         v-if="!cameraStarted"/>
      <v-row>
        <v-col align-self="center">
          <WebCam ref="webcam"
                  width="100%"
                  height="100%"
                  select-first-device
                  @video-live="cameraStarted = true"
                  @stopped="cameraStarted = false"
                  @cameras="onCameraListed"
                  :device-id="currentCameraDeviceId">
          </WebCam>
        </v-col>
      </v-row>

      <v-footer absolute>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn icon rounded x-large
                     @click="capture"
                     :disabled="!cameraStarted || capturing">
                <v-icon>fas fa-camera</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>

      <div class="camera-capture-close-icon"
           @click="cancel">
        <v-icon>fas fa-times</v-icon>
      </div>

      <div class="camera-capture-switch-camera-icon"
           @click="switchCamera"
           v-if="cameras.length > 1">
        <v-icon>fas fa-sync-alt</v-icon>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import {WebCam} from 'vue-web-cam';
import {ref, watch, computed} from "@vue/composition-api";
import {SemipolarSpinner} from 'epic-spinners'
import Compressor from 'compressorjs';

export default {
  name: 'CameraCapture',
  components: {WebCam, SemipolarSpinner},
  props: {
    shown: Boolean,
  },
  setup(props, {root, emit}) {
    const webcam = ref(null);
    const cameraStarted = ref(false);

    const dialogShown = ref(props.shown);
    watch(() => props.shown, (newValue, oldValue) => {
      if (root.$isTeamNote()) {
        if (newValue) {
          window.tnConnector.util.takePhoto({
            maxWidth: 640,
            maxHeight: 640,
            isEnableEdit: false,
          }, (result) => {
            // console.log("Success: " + JSON.stringify(result));

            emit('capture', result.base64);
          }, ()=> {
            emit('cancel');
          })
        }
      } else {
        dialogShown.value = newValue;
        capturing.value = false;

        if (webcam.value) {
          if (newValue) {
            webcam.value.start();
          } else {
            webcam.value.stop();
          }
        }
      }
    })

    const cameras = ref([]);
    const currentCameraIdx = ref(0);
    const onCameraListed = function (c) {
      cameras.value = c;
    };
    const switchCamera = function () {
      currentCameraIdx.value = (currentCameraIdx.value + 1) % cameras.value.length;
    };
    const currentCameraDeviceId = computed(() => {
      if (cameras.value.length > 0) {
        return cameras.value[currentCameraIdx.value].deviceId;
      } else {
        return null;
      }
    });

    const cancel = function () {
      webcam.value.stop();
      emit('cancel');
    };

    const capturing = ref(false);
    const capture = async function () {
      let capture = webcam.value.capture();
      if (capture.length <= 6) {
        return;
      }

      let r = await fetch(capture);
      let blob = await r.blob();

      let compressed = await new Promise((resolve, reject) => {
        new Compressor(blob, {
          maxHeight: 640,
          maxWidth: 640,
          success(file) {
            resolve(file);
          },
          error(err) {
            reject(err.message);
          },
        })
      });
      let base64 = await root.$convertFileToBase64(compressed);

      let data = base64.substr(5);
      // let data = capture.substr(5);

      if (data.length > 1) {
        capturing.value = true;
        emit('capture', base64);
      }
    };

    return {
      webcam,
      cameraStarted,
      dialogShown,

      cameras,
      onCameraListed,
      switchCamera,
      currentCameraDeviceId,

      cancel,
      capturing,
      capture,
    }
  },
}
</script>

<style lang="less">
.camera-capture-dialog-container {
  background-color: rgba(0, 0, 0, 0.5);

  .spinner {
    position: absolute;
    left: calc(50% - 32.5px);
    top: 50%;
  }

  .camera-capture-close-icon, .camera-capture-switch-camera-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    height: 100px;
    overflow: hidden;
    position: fixed;
    top: -50px;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
    width: 100px;
    z-index: 9999;
  }

  .camera-capture-close-icon {
    left: -50px;

    .fa-times {
      position: absolute;
      right: 20px;
      bottom: 15px;
      color: white;
      font-size: 32px;
    }
  }

  .camera-capture-switch-camera-icon {
    right: -50px;

    .fa-sync-alt {
      position: absolute;
      left: 20px;
      bottom: 15px;
      color: white;
      font-size: 32px;
    }
  }
}
</style>
