<template>
  <v-banner sticky
            single-line
            color="primary"
            icon="fas fa-handshake-alt-slash"
            icon-color="white"
            class="no-network-banner text-left"
            :value="false && !$store.getters.isOnline">
    <label><b>{{ $t('no_network') }}</b></label>
  </v-banner>
</template>

<script>
export default {
  name: 'OnlineStatusBanner',
}
</script>