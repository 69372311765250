<template>
  <v-card class="check-list-modal">
    <v-card-text class="check-list-model-content">
      <v-btn v-if="!required"
             color="primary"
             small
             class="check-list-skip-btn"
             @click="skip">SKIP
      </v-btn>
      <h2>{{ currentSectionTitle }}</h2>
      <v-stepper v-model="step" class="check-list-modal-stepper">
        <!--        <v-stepper-header>-->
        <!--          <v-stepper-step v-for="(section, idx) in display"-->
        <!--                          :key="idx"-->
        <!--                          :step="idx+1">-->
        <!--            {{ section.title }}-->
        <!--          </v-stepper-step>-->
        <!--        </v-stepper-header>-->
        <v-stepper-items>
          <v-stepper-content v-for="(section, idx) in display"
                             :key="idx"
                             :step="idx+1">
            <div class="check-list-model-item-wrapper">
              <v-input v-for="(item, idx) in section.items"
                       :key="idx"
                       hide-details
                       class="py-2 check-list-modal-input">
                <div class="d-flex flex-column flex-grow-1">
                  <div v-if="!item.textarea"
                       :class="{'font-weight-bold': item.label}">{{ item.text }}
                  </div>
                  <v-textarea outlined
                              :label="item.text"
                              v-if="item.textarea"
                              @input="onTextAreaInput(item.id, $event)"
                              hide-details
                              auto-grow
                              no-resize></v-textarea>
                </div>
                <template #append
                          v-if="(!item.label && !item.textarea) || (section.id === 'E' && (item.id === 'L1' || item.id === 'L2'))">
                  <v-menu offset-y
                          v-if="!item.label && !item.textarea">
                    <template #activator="{on, attrs}">
                      <v-btn :color="item.value.color"
                             dark
                             v-bind="attrs"
                             v-on="on">
                        <v-icon>{{ item.value.icon }}</v-icon>
                        {{ item.value.text }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(option, idx) in (item.options || options)"
                                   :key="idx"
                                   @click="onOptionSelected(item.id, option)">
                        <v-list-item-icon>
                          <v-icon>{{ option.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ option.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <div class="d-flex flex-column">
                    <v-btn color="green"
                           dark
                           @click="setIsE1(false)"
                           v-if="section.id === 'E' && item.id === 'L1'">
                      <v-icon>fas fa-sync</v-icon>
                      裝置種類
                    </v-btn>
                    <v-btn color="green"
                           dark
                           @click="setIsE1(true)"
                           v-if="section.id === 'E' && item.id === 'L2'">
                      <v-icon>fas fa-sync</v-icon>
                      裝置種類
                    </v-btn>
                  </div>
                </template>
              </v-input>
              <v-textarea outlined
                          auto-grow
                          no-resize
                          hide-details
                          v-if="sectionIdWithRemarks.indexOf(section.id) !== -1"
                          :value="section.remarks"
                          @input="onRemarkInput(section.id, $event)"
                          :label="section.id === 'H' ? '備註': '發現的毛病或異常情況'"
                          class="mt-2"></v-textarea>
              <v-row class="flex-grow-0">
                <v-col v-if="(idx + 1) !== 1">
                  <v-btn block
                         @click="back">
                    上一個項目
                  </v-btn>
                </v-col>
                <v-col v-if="(idx + 1) !== display.length">
                  <v-btn block
                         color="primary"
                         @click="next">
                    下一個項目
                  </v-btn>
                </v-col>
              </v-row>

              <v-btn color="primary"
                     v-if="(idx + 1) === display.length"
                     @click="finish">
                完成
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from '@vue/composition-api'
import ChecklistEquipmentTypeModal from "@/components/ChecklistEquipmentTypeModal";
import _ from "lodash";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'ChecklistModal',
  props: {
    required: Boolean,

    previewMode: Boolean,
  },
  setup(props, {root, emit}) {
    const skip = async function () {
      if (!props.required && await root.$dialog.confirm({text: 'Skip Checklist?'})) {
        emit('submit', false);
      }
    }

    const step = ref(1);
    const askedEquipmentType = ref(false);
    const isE1 = ref(true);
    const setIsE1 = function (newValue) {
      isE1.value = newValue;
      if (isE1.value) {
        results.value.items['E1'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        results.value.items['E2'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        results.value.items['E3'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        results.value.items['E4'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        results.value.items['E5'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        results.value.items['E6'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        results.value.items['E7'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
      } else {
        results.value.items['E1'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        results.value.items['E2'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        results.value.items['E3'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        results.value.items['E4'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        results.value.items['E5'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        results.value.items['E6'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        results.value.items['E7'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
      }
      updateTrigger.value = !updateTrigger.value;
    }

    watch(() => step.value, async (newValue, oldValue) => {
      if (!askedEquipmentType.value && oldValue === 4 && newValue === 5) {
        isE1.value = await root.$dialog.showAndWait(ChecklistEquipmentTypeModal, {
          showClose: false,
          persistent: true,
        });

        if (isE1.value) {
          results.value.items['E1'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
          results.value.items['E2'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
          results.value.items['E3'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
          results.value.items['E4'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
          results.value.items['E5'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
          results.value.items['E6'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
          results.value.items['E7'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
        } else {
          results.value.items['E1'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
          results.value.items['E2'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
          results.value.items['E3'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
          results.value.items['E4'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
          results.value.items['E5'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
          results.value.items['E6'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
          results.value.items['E7'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        }
        askedEquipmentType.value = true;
        updateTrigger.value = !updateTrigger.value;
      }
    })

    const currentSectionTitle = computed(() => {
      let section = sections.value[step.value - 1];
      if (section) return section.title;
      return ''
    })
    const sections = computed(() => {
      if (updateTrigger.value) {
        ''
      }

      return [
        {
          id: 'A',
          title: '肉眼檢驗',
          items: [
            {id: 'L1', text: 'A. 檢查及檢驗起重機械或起重裝置個別構件的狀況，需要時輔以錘擊測試', label: true},
            {id: 'A1', text: '1.   吊重機械裝置的構件 (鋼絲纜索、起重配件、控制裝置及按鈕等)'},
            {id: 'A2', text: '2.   任何制動系統的部分 (制動襯片、離合系統的棘爪和棘輪等)'},
            {id: 'A3', text: '3.   連接硬件和接頭 (液壓及氣動系統的螺栓、緊固件、接頭等)'},
            {id: 'A4', text: '4.   桅桿或吊臂結構 (連接輪栓、結構桅桿及基座的焊縫、移動系統的制動器及鉗等)'},
            {id: 'A5', text: '5.   影響起重機械穩定性的固定、錨定及壓重設備'},
          ],
        },
        {
          id: 'B',
          title: '尺寸檢驗',
          items: [
            {id: 'L1', text: 'B. 對重要構件及結構進行尺寸公差及變形程度的檢查，確保符合製造商的規格及相關安全標準', label: true},
            {id: 'B1', text: '1.   測量線性尺寸 (直徑、厚度、構件的間距、鋼絲纜索的直徑、制動襯片的磨損限度、齒輪傳動的耗損等)'},
            {id: 'B2', text: '2.   測量跨距限度、撓度、準直，確定承重裝配結構物的結構穩定性 (吊臂的撓度、塔式起重機的桅桿角架的準直等)'},
            {id: 'B3', text: '3.   安全限位開關掣的尺寸間距'},
          ],
        },
        {
          id: 'C',
          title: '功能測試及操作測試(由符合資格的操作員協助進行)',
          items: [
            {id: 'L1', text: 'C1. 進行無負荷測試，檢查起重機械或裝置各個操作系統和安全系統的功能', label: true},
            {id: 'C1', text: '1.   升降系統的機械裝置'},
            {id: 'C2', text: '2.   懸臂升降系統的機械裝置'},
            {id: 'C3', text: '3.   移動系統的機械裝置'},
            {id: 'C4', text: '4.   安全警號、安全裝置及指示燈的功能'},
            {id: 'C5', text: '5.   制動操作 (破損安全模式或自由操作)'},
            {id: 'L2', text: 'C2. 進行安全操作負荷性能測試，確定機械的性能', label: true},
            {id: 'C6', text: '1.   升降系統的機械裝置'},
            {id: 'C7', text: '2.   懸臂升降系統的機械裝置'},
            {id: 'C8', text: '3.   移動系統的機械裝置'},
            {id: 'C9', text: '4.   所有安全警號、安全裝置及指示燈的功能'},
            {id: 'C10', text: '5.   制動操作 (破損安全模式或自由操作)'},
            {id: 'C11', text: '6.   尺寸檢驗 (偏移測量等)'},
          ],
        },
        {
          id: 'D',
          title: '開啟式檢驗',
          items: [
            {id: 'D1', text: 'D. 按製造商的建議或如功能測試不正常/不及規格時，應進行開啟式檢驗', default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'}},
            {id: 'D2', text: '檢驗部件', textarea: true, hide: (!results.value.items['D1'] || results.value.items['D1'].value === 'not_applicable')},
          ],
        },
        {
          id: 'E',
          title: '電氣測試及檢驗',
          items: [
            {id: 'L1', text: 'E1. 固定電氣裝置由《電力條例》所規定的註冊電工進行徹底檢驗及測試', label: true, hide: !isE1.value},
            {id: 'E1', text: '1. 裝置已作適當的保護，避免出現接地故障、短路或負荷過重', hide: !isE1.value},
            {id: 'E2', text: '2. 在電力供應來源上有適當及安全的接駁', hide: !isE1.value},
            {id: 'E3', text: '3. 電氣零件及電纜/電線處於良好狀況', hide: !isE1.value},
            {id: 'E4', text: '4. 電纜/電線已適當地鋪設及作出保護，避免損壞', hide: !isE1.value},
            {id: 'L2', text: 'E2. 可攜式電動起重裝置由《電力條例》所規定的註冊電工進行徹底檢驗及測試', label: true, hide: isE1.value},
            {id: 'E5', text: '1. 絕緣測試', hide: isE1.value, default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'}},
            {id: 'E6', text: '2. 接地測試', hide: isE1.value, default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'}},
            {id: 'E7', text: '3. 功能測試', hide: isE1.value, default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'}},
          ],
        },
        {
          id: 'F',
          title: '無損測試(由合資格人員進行)',
          items: [
            {
              id: 'F1', text: 'F. 當肉眼檢驗或開啟式檢驗未足以就所檢驗的零件或構件作出可靠的結論，需進行無損測試，評估零件及構件是否整無缺及可靠',
              default: {text: '不需要', color: 'red', icon: 'fas fa-times', value: 'not_applicable'},
              options: [
                {text: '需要', color: 'green', icon: 'fas fa-check', value: 'yes'},
                {text: '不需要', color: 'red', icon: 'fas fa-times', value: 'not_applicable'},
              ],
            },
            {id: 'F2', text: '測試方法及被測試部件', textarea: true, hide: (!results.value.items['F1'] || results.value.items['F1'].value === 'not_applicable')},
          ],
        },
        {
          id: 'G',
          title: '試驗負荷測試',
          items: [
            {id: 'L1', text: 'G. 起重機、絞車和起重滑車在使用前4年內 或 按需要進行試驗負荷測試', label: true},
            {
              id: 'G1', text: '1. 試驗負荷超逾安全操作負荷的', default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'},
              options: [
                {text: '25%', color: 'green', icon: 'fas fa-check', value: '25%'},
                {text: '10%', color: 'green', icon: 'fas fa-check', value: '10%'},
                {text: '5公噸', color: 'green', icon: 'fas fa-check', value: '5公噸'},
                {text: '其他', color: 'green', icon: 'fas fa-check', value: false},
                {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'},
              ],
            },
            {id: 'G1A', text: '試驗負荷超逾安全操作負荷的', textarea: true, hide: (!results.value.items['G1'] || results.value.items['G1'].value !== false)},
            {id: 'G2', text: '2. 檢驗起重機械的結構，例如機械的桅桿或伸臂的撓度或傾斜度', default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'}},
            {id: 'G3', text: '3. 動態測試 (特別注意制動系統在每個運行過程中的性能和效能，識別任何過度運轉或蠕動)', default: {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'}},
          ],
        },
        {
          id: 'H',
          title: '總結',
          items: [
            {
              id: 'H1', text: 'H. 起重機械或起重裝置處於「安全操作狀態」',
              default: {text: '是', color: 'green', icon: 'fas fa-check', value: 'yes'},
              options: [
                {text: '是', color: 'green', icon: 'fas fa-check', value: 'yes'},
                {text: '否', color: 'red', icon: 'fas fa-times', value: 'no'},
              ],
            },
          ],
        },
      ]
    });
    const setSectionGAllNormal = function () {
      results.value.items['G1'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
      results.value.items['G2'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
      results.value.items['G3'] = {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
      updateTrigger.value = !updateTrigger.value;
    }
    const setSectionGAllNotApplicable = function () {
      results.value.items['G1'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
      results.value.items['G2'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
      results.value.items['G3'] = {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'};
      updateTrigger.value = !updateTrigger.value;
    }
    const results = ref({
      remarks: {},
      items: {},
    });
    const sectionIdWithRemarks = computed(() => {
      let result = [];

      let sectionIdMap = {};
      _.forEach(sections.value, (section) => {
        _.forEach(section.items, (i) => {
          sectionIdMap[i.id] = section.id;
        })
      });

      _.forEach(results.value.items, (v, k) => {
        if (!v.textarea && v.value !== 'not_applicable') {
          result.push(sectionIdMap[k])
        }
      })

      return _.uniq(result);
    })

    const updateTrigger = ref(false);

    const display = computed(() => {
      if (updateTrigger.value) {
        ''
      }

      return sections.value.map((s) => {
        return {
          id: s.id,
          title: s.title,
          remarks: results.value.remarks[s.id] || '',
          items: _.filter(s.items, (i) => !i.hide).map((i) => {
            return {
              ...i,
              value: results.value.items[i.id] || {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'},
            }
          })
        }
      })
    })
    const options = ref([
      {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'},
      {text: '不正常', color: 'red', icon: 'fas fa-times', value: 'abnormal'},
      {text: '不適用', color: 'grey', icon: 'fas fa-ban', value: 'not_applicable'},
    ])

    onBeforeMount(() => {
      // Init results
      sections.value.forEach((section, idx) => {
        results.value.remarks[idx] = "";

        section.items.forEach((item) => {
          if (!item.textarea) results.value.items[item.id] = item.default || {text: '正常', color: 'green', icon: 'fas fa-check', value: 'normal'};
        })
      })
    })

    const onOptionSelected = function (itemId, option) {
      results.value.items[itemId] = option;
      updateTrigger.value = !updateTrigger.value;
    }
    const onTextAreaInput = function (itemId, value) {
      results.value.items[itemId] = value;
      updateTrigger.value = !updateTrigger.value;
    }
    const onRemarkInput = function (sectionId, evt) {
      results.value.remarks[sectionId] = evt;
      updateTrigger.value = !updateTrigger.value;
    };

    const back = function () {
      step.value = step.value - 1;

      setTimeout(() => {
        document.getElementsByClassName('check-list-model-item-wrapper').forEach((el) => {
          el.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        })
      }, 50);
    }
    const next = function () {
      step.value = step.value + 1;

      setTimeout(() => {
        document.getElementsByClassName('check-list-model-item-wrapper').forEach((el) => {
          el.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        })
      }, 50);
    }
    const finish = function () {
      // debug()
      if (props.previewMode) {
        generatePdf();
      } else {
        emit('submit', JSON.stringify(results.value));
      }
    }
    const debug = function () {
      console.log(sections.value);
      console.log(display.value);
      console.log(results.value);
    }
    const generatePdf = async function () {
      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'test_checklist/',
        params: {
          results: JSON.stringify(results.value),
        },
        opt: {extra: {responseType: 'arraybuffer'}},
      });

      let blob = new Blob([response.data], {type: response.headers.get('content-type')});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'checklist.pdf';
      link.click();
    }

    return {
      skip,
      step,
      setIsE1,
      currentSectionTitle,
      options,
      setSectionGAllNormal,
      setSectionGAllNotApplicable,
      sectionIdWithRemarks,
      display,
      onOptionSelected,
      onTextAreaInput,
      onRemarkInput,
      back,
      next,
      finish,

      debug,
    }
  },
}
</script>

<style lang="less">
.check-list-modal {
  height: 100vh;

  display: flex;
  flex-direction: column;

  .check-list-model-content {
    flex: 1 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .check-list-skip-btn {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .check-list-modal-stepper {
      height: 100%;
      flex: 1 1 0;

      display: flex;
      flex-direction: column;
      overflow: hidden;

      .v-stepper__step {
        padding: 8px;
      }

      .v-stepper__items {
        flex: 1 1 0;

        display: flex;
        flex-direction: column;

        .v-stepper__content {
          display: flex;

          .v-stepper__wrapper {
            flex: 1 1 0;

            display: flex;
            flex-direction: column;

            .check-list-model-item-wrapper {
              flex: 1 1 0;

              display: flex;
              flex-direction: column;
              overflow-y: auto;
              overflow-x: hidden;

              .v-input {
                align-items: center;
                flex-grow: 0;
              }

              .check-list-modal-input {
                border-bottom: solid 1px #ddd;
              }
            }
          }
        }
      }
    }
  }
}
</style>
