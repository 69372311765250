<template>
  <v-container class="data-iterator" fluid>
    <v-row>
      <v-col v-for="field in fields"
             :cols="field.cols || defaultCols"
             :key="field.value">
        <v-row class="text-left">
          <v-col>
            <label class="data-iterator-title">
              {{ field.label }}
            </label>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col v-if="$slots[field.value]" class="pt-0">
            <slot :name="field.value"></slot>
          </v-col>
          <v-col v-if="!$slots[field.value]" class="pt-0">
            <label v-if="!field.textarea">
              {{ getAttr(field.value) || '' }}
            </label>
            <v-textarea v-if="field.textarea"
                        :value="getAttr(field.value)"
                        readonly
                        auto-grow
                        no-resize
                        solo
                        :rows="5"></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import {ref} from '@vue/composition-api';

export default {
  name: 'DataIterator',
  props: {
    value: Object,
    fields: Array,
    cols: Number,
  },
  setup(props, {root}) {
    const defaultCols = ref(props.cols || 12);
    const object = ref(props.value);
    const getAttr = function (path) {
      return _.get(object.value, path, '');
    };

    return {
      defaultCols,
      object,
      getAttr,
    }
  },
}
</script>

<style lang="less">
.data-iterator {
  .data-iterator-title {
    font-weight: bold;
    font-size: 110%;
  }

  .v-textarea {
    .v-input__slot {
      box-shadow: none !important;
      padding: 0 !important;
    }
  }
}
</style>