<template>
  <v-container class="schedule pa-0">
    <v-row class="flex-grow-0">
      <v-col>
        <v-dialog
            v-model="datePickerDialogShown"
            max-width="320px">
          <template #activator="{on, attrs}">
            <v-btn text
                   block
                   v-bind="attrs"
                   v-on="on">
              <b>{{ $parseDate(selectedDate.toISOString(), 'DDDD') }}</b>
            </v-btn>
          </template>
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <vc-date-picker color="red"
                              is-required
                              is-inline
                              is-expanded
                              :show-week="false"
                              :attributes="datePickerAttribute"
                              v-model="selectedDate">
              </vc-date-picker>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                     text
                     @click="datePickerDialogShown = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <JobList :jobs="jobs"
             @click="onJobClicked"></JobList>


  </v-container>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";
import JobList from "@/components/JobList";
import {Job} from "@/store/models";
import {DateTime} from 'luxon';
import _ from "lodash";
import {JOB_STATUS, JOB_TYPE} from "@/constants";

export default {
  name: 'Schedule',
  components: {JobList},
  setup(props, {root}) {
    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.SHOW_APPBAR_ELLIPSIS);
      await root.$store.dispatch(ACTION_TYPES.SET_APPBAR_TITLE, root.$t('schedule.title'));
    });

    onUnmounted(() => {
      root.$store.dispatch(ACTION_TYPES.HIDE_APPBAR_ELLIPSIS);
    })

    const selectedDate = ref(new Date());
    const jobs = computed(() => {
      let filters = root.$store.getters.jobFilters;
      let filteredJobs = Job.query().where('job_date', DateTime.fromJSDate(selectedDate.value).toISODate()).withAllRecursive().get();

      if (filters.client.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.client.indexOf(job.equipment.site.client.code) !== -1;
        });
      }

      if (filters.site.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.site.indexOf(job.equipment.site.id) !== -1
        });
      }

      if (filters.attn.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.attn.indexOf(job.equipment.site.memo_attn) !== -1
        });
      }

      if (filters.district.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.district.indexOf(job.equipment.site.district) !== -1;
        });
      }

      if (filters.region.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.region.indexOf(job.equipment.site.region) !== -1;
        });
      }

      if (filters.jobType.length > 0) {
        filteredJobs = _.filter(filteredJobs, (job) => {
          return filters.jobType.indexOf(job.job_type) !== -1;
        });
      }

      let sortDescending = filters.sortBy.startsWith('-');
      let sortBy = filters.sortBy.substr(filters.sortBy.indexOf('-') + 1);

      let expired = _.remove(filteredJobs, (j) => {
        if (j.job_status === JOB_STATUS.COMPLETED) {
          return false
        }

        // Check if equipment expired
        if (j.type === JOB_TYPE.LOAD_TEST_AND_EXAMINATION && j.equipment.next_load) {
          if (DateTime.fromISO(j.equipment.next_load) < DateTime.fromJSDate(new Date())) {
            return true
          }
        }

        // Check if equipment expired
        if (j.equipment.next_exam) {
          if (DateTime.fromISO(j.equipment.next_exam) < DateTime.fromJSDate(new Date())) {
            return true
          }
        }

        return false;
      });
      expired = _.sortBy(expired, [j => _.get(j, sortBy, j.id)]);
      if (sortDescending) {
        expired = _.reverse(expired);
      }

      filteredJobs = _.sortBy(filteredJobs, [j => _.get(j, sortBy, j.id)]);
      if (sortDescending) {
        filteredJobs = _.reverse(filteredJobs);
      }

      return _.concat(expired, filteredJobs)
    });

    const datePickerAttribute = computed(() => {
      return [{
        key: 'selected',
        highlight: true,
        dates: selectedDate.value,
        contentClass: 'highlighted'
      }, {
        key: 'scheduled',
        dot: {
          color: 'red'
        },
        dates: root.$store.getters.scheduledJobDates.map((d) => {
          return DateTime.fromISO(d).toJSDate();
        }),
      }];
    });

    const datePickerDialogShown = ref(false);

    // Job Details
    const onJobClicked = function (job) {
      root.$router.push({name: 'job-details', params: {jobId: job.id}});
    };

    return {
      selectedDate,
      datePickerAttribute,
      datePickerDialogShown,
      jobs,
      // filteredJobs,

      onJobClicked,
    };
  },
}
</script>

<style lang="less">
.schedule {
  display: flex;
  flex-direction: column;
  height: 100% !important;

  .schedule-job-list-wrapper {
    height: calc(100vh - 355.125px);
  }
}

.vc-container {
  border: none !important;

  .vc-title {
    //color: red;
  }
}
</style>
