<template>
  <v-dialog v-model="dialogShown"
            fullscreen
            scrollable>
    <template #activator="{on}">
      <v-select :label="label"
                :items="items"
                :menu-props="{closeOnClick: true}"
                v-bind="$attrs"
                v-on="on"
                :value="value"></v-select>
    </template>

    <v-card>
      <v-card-title class="flex-grow-0">{{ label }}</v-card-title>
      <v-card-subtitle class="flex-grow-0 pb-0">
        <v-autocomplete readonly
                        dense
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        :menu-props="{closeOnClick: true}"
                        append-icon=" "
                        :items="items"
                        v-model="selectedItems">
          <template #selection="{item, index}">
            <v-chip close
                    @dblclick="removeItem(index)"
                    @click:close="removeItem(index)"
                    v-if="index < 3">{{ truncateText(item.text) }}
            </v-chip>
            <span v-if="index === 3"
                  class="grey--text text-caption">
          (+{{ selectedItems.length - 3 }} others)
            </span>
          </template>
        </v-autocomplete>
      </v-card-subtitle>
      <v-card-text class="flex-grow-1 pa-0 d-flex flex-column">
        <v-virtual-scroll :bench="20"
                          :items="filteredItems"
                          :item-height="60">
          <template #default="{item}">
            <v-list-item :key="item.value"
                         @click="itemClicked(item)">
              <v-list-item-action>
                <v-checkbox readonly
                            :input-value="selectedItems.indexOf(item.value) !== -1"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
        <v-text-field outlined
                      class="px-2"
                      prepend-inner-icon="fas fa-search"
                      @input="onSearchInput"
                      hide-details></v-text-field>
      </v-card-text>
      <v-card-actions class="flex-grow-0">
        <v-btn text
               @click="dialogShown = false">
          CANCEL
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text
               @click="onSelectionClear">
          CLEAR
        </v-btn>
        <v-btn color="primary"
               text
               @click="onSelectionConfirm">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, watch, computed} from '@vue/composition-api'
import _ from 'lodash'

export default {
  name: 'ModalSelector',
  props: {
    label: String,
    items: Array,
    value: Array,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);
    const selectedItems = ref([]);
    watch(() => dialogShown.value, (newValue) => {
      if (newValue) {
        selectedItems.value = props.value;
      }
    }, {immediate: true})
    const truncateText = function (text) {
      return _.truncate(text, {length: 30})
    }
    const removeItem = function (idx) {
      selectedItems.value.splice(idx, 1);
    };
    const search = ref(null);
    const onSearchInput = _.debounce((evt)=> {
      search.value = evt;
    }, 1000)
    const filteredItems = computed(() => {
      if (search.value) {
        return _.filter(props.items, (i)=> {
          return i.text.toLowerCase().indexOf(search.value.toLowerCase()) !== -1;
        })
      }

      return props.items;
    })
    const itemClicked = function (item) {
      const idx = _.indexOf(selectedItems.value, item.value);

      if (idx === -1) {
        selectedItems.value.splice(selectedItems.value.length - 1, 0, item.value)
      } else {
        selectedItems.value.splice(idx, 1);
      }
    };
    const onSelectionClear = function () {
      selectedItems.value = [];
    }
    const onSelectionConfirm = function () {
      emit('input', selectedItems.value);
      dialogShown.value = false;
    }

    return {
      dialogShown,
      selectedItems,
      truncateText,
      removeItem,
      onSearchInput,
      filteredItems,
      itemClicked,
      onSelectionClear,
      onSelectionConfirm,
    };
  }
}
</script>

