<template>
  <v-dialog v-model="dialogShown"
            persistent
            @click:outside="onClose">
    <v-container fluid class="pdf-viewer-container">
      <div class="pdf-viewer-pdf-wrapper">
        <pdf :src="src" v-if="showPdf" :page="currentPage" class="pdf-viewer-pdf"></pdf>
      </div>
      <span class="pdf-viewer-page-count">
        {{ currentPage }} / {{ totalPage }}
      </span>
      <v-btn icon
             dark
             class="pdf-viewer-close-btn"
             key="pdf-viewer-close-btn"
             @click="onClose">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
      <!--      <span class="pdf-viewer-download-btn"-->
      <!--            v-if="fileId">-->
      <!--        <v-btn icon key="pdf-viewer-download-btn"-->
      <!--               @click="$downloadFile(fileId)">-->
      <!--        <v-icon>fas fa-download</v-icon>-->
      <!--      </v-btn>-->
      <!--      </span>-->
      <v-btn class="pdf-viewer-prev-btn" icon key="pdf-viewer-prev-btn"
             v-if="currentPage !== 1 && totalPage !== 1"
             @click="currentPage -= 1">
        <v-icon>fas fa-chevron-circle-left</v-icon>
      </v-btn>

      <v-btn class="pdf-viewer-next-btn" icon key="pdf-viewer-next-btn"
             v-if="currentPage !== totalPage && totalPage !== 1"
             @click="currentPage += 1">
        <v-icon>fas fa-chevron-circle-right</v-icon>
      </v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
import {ref, watch, computed} from '@vue/composition-api'
import pdf from 'vue-pdf'

export default {
  name: 'PdfViewer',
  components: {pdf},
  props: {
    shown: Boolean,
    src: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);

    const currentPage = ref(0);
    const totalPage = ref(0);
    const showPdf = ref(false);
    watch(() => props.shown, async (newValue, oldValue) => {
      dialogShown.value = newValue;

      if (!oldValue && newValue) {
        const p = await pdf.createLoadingTask(props.src).promise;
        currentPage.value = 1;
        totalPage.value = p.numPages;
        showPdf.value = true;
      } else {
        showPdf.value = false;
      }
    });

    // const fileId = computed(() => {
    //   if (!props.src) {
    //     return null;
    //   }
    //
    //   if (props.src.startsWith(DOWNLOAD_FILE_PREFIX)) {
    //     return props.src.substr(DOWNLOAD_FILE_PREFIX.length);
    //   } else {
    //     return null;
    //   }
    // })

    const onClose = function () {
      emit('close');
    };


    return {
      dialogShown,
      showPdf,
      currentPage,
      totalPage,
      // fileId,

      onClose,
    }
  }
}
</script>

<style lang="less">
.pdf-viewer-container {
  background-color: white;
  position: relative;
  height: 100%;
  overflow: hidden;

  .pdf-viewer-pdf-wrapper {
    height: 90vh;
    width: 100%;
    overflow: auto;

    .pdf-viewer-pdf {
      min-width: 720px;
    }
  }

  .pdf-viewer-page-count, .pdf-viewer-download-btn, .pdf-viewer-close-btn {
    position: absolute;
    top: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 50px;
    color: white;
  }

  .pdf-viewer-page-count {
    left: 50%;
    transform: translateX(-50%);
  }

  .pdf-viewer-download-btn {
    .v-btn {
      color: white;
    }
  }

  .pdf-viewer-close-btn {
    right: 20px;
  }

  .pdf-viewer-prev-btn, .pdf-viewer-next-btn {
    position: absolute;
    top: 50%;
    font-size: 200%;
  }

  .pdf-viewer-prev-btn {
    left: 20px;
  }

  .pdf-viewer-next-btn {
    right: 20px;
  }
}
</style>
