<template>
  <v-dialog ref="menu"
            v-model="showDatePicker"
            :close-on-content-click="false"
            :return-value="date"
            transition="scale-transition"
            offset-y
            min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field :outlined="outlined"
                    append-icon="fas fa-calendar-day"
                    @click:append="showDatePicker = true"
                    :label="label"
                    :placeholder="label"
                    :error-messages="errorMessage"
                    v-model="dateString"
                    readonly
                    v-on="on"></v-text-field>
    </template>
    <v-date-picker v-model="date"
                   :range="range"
                   no-title
                   scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="clearDate">Clear</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";

export default {
  name: 'DatePickerInput',
  props: {
    value: [String, Array],
    label: String,
    outlined: Boolean,
    range: Boolean,
    errorMessage: String,
  },
  setup(props, {root, emit}) {
    const date = ref(props.value || (props.range ? [] : null));
    const dateString = computed(() => {
      return props.range ? (date.value.length === 2 ? `${date.value[0]} - ${date.value[1]}` : '') : date.value;
    })
    const clearDate = function () {
      date.value = (props.range ? [] : null);
      showDatePicker.value = false;
    };
    watch(
        date,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            emit('input', newVal)
          }
        }
    );

    const showDatePicker = ref(false);

    return {
      date,
      dateString,
      showDatePicker,
      clearDate,
    }
  }
}
</script>