import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"login",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c(VImg,{attrs:{"src":require("../assets/emsd_logo.png"),"height":"10vh","max-height":"200px","contain":""}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('login.username')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('login.password'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"red","dark":""},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")])],1)],1),_c(VFooter,{staticClass:"login-footer",attrs:{"absolute":"","color":"transparent"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v("Contact EMSD (SVSD/S/CS Team) at")])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v("XXXX-XXXX")])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v("Or")])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v("XXX@XXXX.com")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }