import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"rpe-info pt-16",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center"},[_c(VIcon,{attrs:{"color":"primary","size":"56px"}},[_vm._v("fas fa-user ")])],1)],1),_c(VRow,[_c(VCol,[_c('label',[_vm._v(_vm._s(_vm.$t('rpe_info.label')))])])],1),_c(VForm,[_c(VCombobox,{attrs:{"outlined":"","items":_vm.rpeOptions,"label":_vm.$t('account.rpe_name'),"placeholder":_vm.$t('account.rpe_name'),"rules":_vm.requiredRules},on:{"change":_vm.onRpeNameChange},model:{value:(_vm.rpeName),callback:function ($$v) {_vm.rpeName=$$v},expression:"rpeName"}}),_c(VTextField,{attrs:{"outlined":"","prefix":"RP","label":_vm.$t('account.rpe_no'),"placeholder":_vm.$t('account.rpe_no'),"rules":_vm.rpeNoRules},model:{value:(_vm.rpeNo),callback:function ($$v) {_vm.rpeNo=$$v},expression:"rpeNo"}}),_c(VTextField,{attrs:{"outlined":"","label":_vm.$t('account.rpe_qualification'),"placeholder":_vm.$t('account.rpe_qualification'),"rules":_vm.requiredRules},model:{value:(_vm.rpeQualification),callback:function ($$v) {_vm.rpeQualification=$$v},expression:"rpeQualification"}}),_c(VTextField,{attrs:{"outlined":"","label":_vm.$t('account.rpe_discipline'),"placeholder":_vm.$t('account.rpe_discipline'),"rules":_vm.requiredRules},model:{value:(_vm.rpeDiscipline),callback:function ($$v) {_vm.rpeDiscipline=$$v},expression:"rpeDiscipline"}})],1),_c(VFooter,{staticClass:"pr-6 py-3",attrs:{"app":"","inset":""}},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('rpe_info.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }