import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle),_c(VCardText,{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('job_form.submit_now_msg'))+" ")]),_c(VCardActions,[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":""},on:{"click":function($event){return _vm.$emit('submit', undefined)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1),_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_vm._v(" "+_vm._s(_vm.$t('job_form.submit_later'))+" ")]),_c(VBtn,{staticClass:"mt-4",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit', true)}}},[_vm._v(" "+_vm._s(_vm.$t('job_form.submit_now'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }