export const ACTION_TYPES = {
    // loadingSpinner
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    CLEAR_LOADING: 'CLEAR_LOADING',
    SET_LOADING_MSG: 'SET_LOADING_MSG',

    // snackBar
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    HIDE_SNACKBAR: 'HIDE_SNACKBAR',

    SET_ONLINE_STATUS: 'SET_ONLINE_STATUS',

    // App Bar
    SHOW_APPBAR: 'SHOW_APPBAR',
    HIDE_APPBAR: 'HIDE_APPBAR',
    SET_APPBAR_TITLE: 'SET_APPBAR_TITLE',
    SHOW_APPBAR_ELLIPSIS: 'SHOW_APPBAR_ELLIPSIS',
    HIDE_APPBAR_ELLIPSIS: 'HIDE_APPBAR_ELLIPSIS',

    // Files
    SAVE_FILE: 'SAVE_FILE',
    GET_FILE: 'GET_FILE',
    CLONE_FILE: 'CLONE_FILE',
    DELETE_FILE: 'DELETE_FILE',

    // API
    CALL_API: 'CALL_API',
    UPLOAD_FILE: 'UPLOAD_FILE',

    // Account
    AUTHENTICATE_TEAMNOTE_JWT: 'AUTHENTICATE_TEAMNOTE_JWT',
    SET_ME: 'SET_ME',
    SET_PHOTO_LIMIT: 'SET_PHOTO_LIMIT',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_RPE: 'SET_RPE',
    GET_ME: 'GET_ME',

    //// LALG

    // Contract
    RETRIEVE_CONTRACTS: 'RETRIEVE_CONTRACTS',
    SET_CURRENT_CONTRACT: 'SET_CURRENT_CONTRACT',

    // Job
    RETRIEVE_JOBS: 'RETRIEVE_JOBS',
    SCHEDULE_JOBS: 'SCHEDULE_JOBS',
    UPDATE_JOB_STATUS: 'UPDATE_JOB_STATUS',
    SET_JOB_FILTERS: 'SET_JOB_FILTERS',
    RESET_JOB_FILTERS: 'RESET_JOB_FILTERS',

    // Form
    SUBMIT_FORM: 'SUBMIT_FORM',
}
